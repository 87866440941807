import React from 'react';

export default class Records extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        let name = JSON.parse(localStorage.getItem('loggedIn')).data.name;
        console.log('name is =>', name);
        return (
            <section className='border_area records_area my-3'>
                <h5 className='title records text-center'>紀錄</h5>
                {this.props.records && this.props.records.map((record, index) => {
                    console.log('record is =>', record);
                    return (
                        <p key={index} className='each_record px-3 py-2'>{record}</p>
                    )
                })}
            </section>
        )
    }
}