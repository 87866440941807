const axios = require('axios');

const areaControlApi = {
    getAreaStatus : async(authUser) => {
        return await axios.get(`${window.cruisys_config.API_HOST}/WelcomeAboard/recep_spaces`, {
            headers: { 'X-api-token': authUser.token },
        });
    },
    patchAreaStatus : async(authUser, data) => {
        return await axios.request({
            url: `${window.cruisys_config.API_HOST}/WelcomeAboard/recep_spaces`,
            method: 'PATCH',
            headers: { 'X-api-token': authUser.token },
            data: {data: data},
        });
    }
}

export default areaControlApi;