import React, { Component } from 'react'

import moment from 'moment';
import 'moment/locale/zh-tw';
import avatarUrl from '../avatarUrl';

import ticketHelper from '../ticketHelper';

import _ from 'lodash'
import TicketCardImage from './TicketCardImage';
import ImageWithDefault from './ImageWithDefault';

moment.locale('zh-tw')

const topStyle = {
  display: 'grid',
  gridTemplateColumns: '4fr 5fr'
  // gridColumnGap: '0.5rem'
}

const bottomStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: '0.5rem'
}

class TicketCard extends Component {
  render() {
    let st = ticketHelper.showProgress(this.props.data.progress)
    let statusColor = st.color
    let statusLabel = st.label
    let statusIconName = st.iconName
    let si = ticketHelper.showStayIn(this.props.data)
    let stayInColor = si.color
    let stayInLabel = si.label;
    let line_status = false;
    // For testing
    /*this.props.data.car_orig.contacts.map(person => {
      person.line_id = '12345';
    });*/
    this.props.data.car_orig.contacts.forEach(person => {
      if(person.hasOwnProperty('line_id')){
        line_status = true;
      }
    });
    // car.plate_no 可能直接是 '' 空字串，此時 _.get 的預設值無效
    let plateNO = _.get(this.props.data, 'car.plate_no', '　')
    if (plateNO === '') { plateNO = '　' }
    return (
      <div className='relative rounded overflow-hidden shadow my-2 border border-grey-light' style={{ backgroundColor: '#fafafa' }}>
        <div className='pl-4 mt-6 font-black text-3xl mb-1 text-black font-sans'>{plateNO}</div>
        <div className='absolute pin-t pin-r pt-5 px-3'>
          {
            this.props.data.warranty_recall && (
              <div className='inline-block md:block lg:inline-block px-2 py-1 text-white text-base bg-label-green rounded'>
                保固召回
              </div>
            )
          }
          {
            this.props.data.warranty_check &&(
              <div className={`inline-block md:block lg:inline-block px-2 py-1 text-white text-base bg-label-blue rounded ${this.props.data.warranty_recall && 'ml-2 md:ml-0 md:mt-3 lg:ml-2 lg:mt-0'}`}>
                保固終檢
              </div>
            )
          }
        </div>
        <div className='py-4' style={topStyle}>
          <div className='flex flex-col pl-4'>
            <div className='flex flex-row mb-2 items-center mt-1'>
              {this.props.data.assignee
                ? <ImageWithDefault
                  alt=''
                  src={avatarUrl(_.get(this.props.data, 'assignee.avatar', ''))}
                  default={ticketHelper.userImageDefault()}
                  className='rounded-full'
                  style={{ width: '35px', height: '35px' }} />
                : <img alt='' src='/empty_user.png' style={{ width: '35px', height: '35px' }} />}
              <div className='pl-2'>
                <div className='text-lg font-black text-black'>{_.get(this.props.data, 'assignee.name', '尚未指定')}</div>
                <div className='text-grey-darker text-sm'>{_.get(this.props.data, 'assignee.title', '－－－－')}</div>
              </div>
            </div>
            <div>
              <div className='text-lg text-black font-black flex flex-row items-center mt-4'>
                <span className={`icon-ic_reservation ${this.props.data.booked ? 'text-cruisys-blue' : 'text-grey-darker'} block mr-3`} />
                <span>{this.props.data.booked ? '預約' : '自來'}</span>
              </div>
              <div className='text-lg text-black font-black flex flex-row items-center mt-2 mb-2'>
                <span className={'block mr-3 ' + statusIconName} style={{ color: statusColor }} />
                <span>{statusLabel}</span>
              </div>
              <div className='text-lg text-black font-black flex flex-row items-center mb-4'>
                <span className={`icon-line block mr-3 ${line_status ? 'text-green': 'text-grey'}`}/>
                <span>{line_status ? '已綁定': '未綁定'}</span>
              </div>
            </div>
            <div>
              <div className='flex flex-row items-center'>
                <span className='text-grey-darker text-base block w-12'>TAG</span>
                <span className='text-base font-black text-black'>{this.props.data.tag_no ? this.props.data.tag_no : '-'}</span>
              </div>

              <div className='flex flex-row items-center mt-1'>
                <span className='text-grey-darker text-base block w-12'>系列</span>
                <span className='text-base font-black text-black'>{_.get(this.props.data, 'car.series', '') ? _.get(this.props.data, 'car.series', '') : '-'}</span>
              </div>
            </div>
          </div>
          <div className='flex flex-col items-center'>
            <TicketCardImage
              className='ticket-card-image'
              src={ticketHelper.carImageHead(_.get(this.props.data, 'car.plate_no', ''))}
              default={ticketHelper.carImageDefault()}
            />
          </div>
        </div>
        <div className='p-4 border-t' style={bottomStyle}>
          <div className=''>
            <div className='text-grey-darker text-lg'>預計進廠</div>
            <div className='text-base font-black text-black'>
              {_.get(this.props.data, 'estimated_arrival_time', null)
                ? moment(_.get(this.props.data, 'estimated_arrival_time', '')).format('MM/DD HH:mm')
                : '--/-- --:--'}
            </div>
          </div>
          <div className='ml-4'>
            <div className='text-grey-darker text-lg'>預計交車</div>
            <div className='text-base font-black text-black'>
              {
                _.get(this.props.data, 'estimated_delivery_time', null)
                  ? moment(_.get(this.props.data, 'estimated_delivery_time', '')).format('MM/DD HH:mm')
                  : '--/-- --:--'
              }</div>
          </div>
        </div>
      </div>
    )
  }
}

export default TicketCard
