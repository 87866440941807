import React, { Component } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'

import LoginPage from './pages/login';
import LoginFormPage from './pages/loginForm';
import MePage from './pages/me';
import TicketPage from './pages/ticket';
import StatPage from './pages/stats';
import Control from './pages/control';
import NotificationsPage from './pages/notifications';
import NewPage from './pages/new';
import ScrollToTop from './components/ScrollToTop';


class App extends Component {
  render () {
    return (
      <div>
        <Router>
          <ScrollToTop>
            <div>
              <Route exact path='/' component={LoginPage} />
              <Route exact path='/login-form' component={LoginFormPage} />
              <Route path='/me' component={MePage} />
              <Switch>
                <Route exact path='/tickets/new' component={NewPage} />
                <Route exact path='/tickets/:id' component={TicketPage} />
              </Switch>
              <Route path='/tickets/:id/edit' render={(props) => <TicketPage edit />} />
              <Route path='/stats' component={StatPage} />
              <Route path='/notifications' component={NotificationsPage} />  
              <Route path='/control-parking' component={Control} />
            </div>          
          </ScrollToTop>        
        </Router>
        <div className="flex flex-row justify-center text-grey-darker my-2">{`© Cruisys ${new Date().getFullYear()}. All rights reserved.`}</div>
      </div>
    )
  }
}

export default App
