import React, { Component } from 'react'
import ImageWithDefault from './ImageWithDefault'
import ticketHelper from '../ticketHelper'
import { get } from 'lodash'

class LineContact extends Component {
  render () {
    const data = this.props.data

    // souce
    const source_table = {
      service_ticket:'預約表',
      car_contact:'車籍資料',
      service_go:'Service Go',
      '-':'-'
    }
    const source = source_table[data.data_source]

    // user type with order
    const userTypeTable = {
      client:'售後',
      car_owner:'車主',
      contact:'銷售',
    }
    const roles = Object.keys(userTypeTable).filter(type => data.user_type[type])
    const rolesWithString = roles.map(role => userTypeTable[role])

    let userType = "-"
    if (rolesWithString.length > 0){
      userType = rolesWithString.join("/ ")
    }

    // display name
    let title = data.title
    if (title === "先生/小姐" || title === "未指定"){
      title = "貴賓"
    }
    const displayName = data.name + " " + title

    if (!data) {
      return (
        <div></div>
      )
    }
    return (
      <div className='flex overflow-hidden px-4 py-2' style={{backgroundColor:"#F4F4F4"}}>
        <div className='flex-none self-center'>
          <ImageWithDefault
            alt=''
            src={data.profile_url}
            default={ticketHelper.lineImageDefault()}
            className='rounded-full self-center border border-solid border-black'
            style={{ width: '66px', height: '66px' }} />
        </div>
        <div className='flex flex-col flex-auto ml-4'>
          <div className='flex-none text-base'>
            {source} - {userType}
          </div>
          <div className='flex justify-between pt-3'>
            <div className='flex flex-col flex-none self-center'>
              <div className='text-xl'>
                {displayName}
              </div>
              <div className='text-xl'>
                {data.nickname}
              </div>
            </div>
            <div className='flex-none self-center'>
              <a href={`tel:${data.phone}`}>
                <button className='bg-cruisys-blue-dark text-white p-2 w-16 rounded-lg' disabled={!get(data, 'phone', false)}>
                  <span className={'text-2xl icon-phone'} style={{weight:"28px", height:"28px"}}/>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LineContact
