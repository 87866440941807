import React, { Component } from 'react'

import { get } from 'lodash'
import moment from 'moment'
import 'moment/locale/zh-tw'

import avatarUrl from '../avatarUrl'
import ticketHelper from '../ticketHelper'
import ImageWithDefault from './ImageWithDefault'
import ImageWithDefaultHidden from './ImageWithDefaultHidden'
import TicketDetailLocation from './TicketDetailLocation'
import LineContact from './LineContact'

import { Icon } from 'semantic-ui-react'

import Slick from './Slick'
import NumberFormat from 'react-number-format';

moment.locale('zh-tw')

function secondsToLabel(seconds) {
  return (seconds || seconds === 0)
    ? `${Math.floor(seconds / 3600)} 時 ${Math.floor(seconds / 60) % 60} 分`
    : '- 時 - 分'
}

function timeToLabel(time) {
  return time ? moment(time).format('MM/DD HH:mm') : '- -:- -'
}

const contactLabels = ['客戶', '車主', '聯絡人']

export default class TicketDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showComment: false,
      showContact: false
    }
  }

  toggleShowComment() {
    if (this.state.showComment) {
      this.setState({ showComment: false })
    } else {
      this.setState({ showComment: true })
    }
  }

  toggleShowContact() {
    if (this.state.showContact) {
      this.setState({ showContact: false })
    } else {
      this.setState({ showContact: true })
    }
  }

  render() {
    let data = this.props.data;
    let line_status = false;
    data.car_orig.contacts.forEach(person => {
      if(person.hasOwnProperty('line_id')){
        line_status = true;
      }
    });
    console.log('line_status is =>', line_status);
    let st = ticketHelper.showProgress(data.progress)
    let statusColor = st.color
    let statusLabel = st.label
    let statusIconName = st.iconName
    return (
      <div className='bg-white min-h-screen pb-20'>
        <div className='overflow-hidden mb-2 border-b sm:pl-4 sm:my-2 px-8 pb-4'>
          <div className='py-4' style={{ paddingTop: '1.75rem' }}>
            {data.status === 'closed'
              ? ''
              : <button className='bg-grey-light text-black p-3 w-32 rounded-lg text-xl' onClick={this.props.editHandler}>編輯</button>}
          </div>
          <div className='pb-4 ticket-detail-top'>
            <div className='relative pt-2'>
              <h1 className='font-black text-3xl mb-4 text-black font-sans'>{get(data, 'car.plate_no')}</h1>
              <div className='sm:hidden absolute pin-t pin-r pt-3 px-2'>
                {
                  this.props.data.warranty_recall && (
                    <div className='inline-block px-2 py-1 text-white text-base bg-label-green rounded'>
                      保固召回
                    </div>
                  )
                }
                {
                  this.props.data.warranty_check &&(
                    <div className={`inline-block px-2 py-1 text-white text-base bg-label-blue rounded ${this.props.data.warranty_recall && 'ml-2'}`}>
                      保固終檢
                    </div>
                  )
                }
              </div>
              <div className='flex flex-row mb-2 items-center'>
                {data.assignee
                  ? <ImageWithDefault
                    alt='user_avatar'
                    src={avatarUrl(get(data, 'assignee.avatar', null))}
                    default={ticketHelper.userImageDefault()}
                    className='rounded-full'
                    style={{ width: '40px', height: '40px' }} />
                  : <img className='rounded-full' alt='user_avatar' src='/empty_user.png' style={{ width: '40px', height: '40px' }} />
                }
                <div className='pl-2'>
                  <div className='text-lg font-black text-black'>{get(data, 'assignee.name', '尚未指定')}</div>
                  <div className='text-grey-darker'>{get(data, 'assignee.title', '－－－－')}</div>
                </div>
              </div>
              <div className='flex sm:block flex-row flex-wrap items-center sm:justify-between md:justify-start mt-4 sm:mt-0 px-1'>
                <div className='text-lg text-black sm:mt-4 flex flex-row items-center'>
                  <span className={`icon-ic_reservation block mr-3 ${data.booked ? 'text-cruisys-blue' : 'text-grey-darker'}`} />
                  <span>{data.booked ? '預約' : '自來'}</span>
                </div>
                <div className='text-lg text-black flex flex-row items-center sm:mt-2 ml-3 sm:ml-0'>
                  <span className={'block mr-3 ' + statusIconName} style={{ color: statusColor }} />
                  <span>{statusLabel}</span>
                </div>
                <div className='text-lg text-black sm:mt-2 flex flex-row items-center ml-3 sm:ml-0'>
                  <span className={`icon-line block mr-3 ${line_status ? 'text-green': 'text-grey'}`}/>
                  <span>{line_status ? '已綁定': '未綁定'}</span>
                </div>
              </div>
              <div className='flex sm:block flex-row flex-wrap items-center sm:justify-between md:justify-start mt-2 px-1'>
                <div className='text-lg text-black flex flex-row items-center ml-0'>
                  <span className='icon-ic_star-full block mr-3' />
                  <span>{data.service_type === null || data.service_type === undefined ? '未指定服務類型' : data.service_type}</span>
                </div>
                <div className='text-lg text-black sm:mt-2 flex flex-row items-center ml-3 sm:ml-0'>
                  <span className='icon-ic_customer_here block mr-3 text-cruisys-red' />
                  <span>
                    {data.customer_status}
                  </span>
                </div>
              </div>
            </div>
            <div className='sm:flex justify-center items-center relative hidden'>
              <div className='absolute pin-t pin-r pt-3 px-2'>
                {
                  this.props.data.warranty_recall && (
                    <div className='inline-block px-2 py-1 text-white text-base bg-label-green rounded'>
                      保固召回
                    </div>
                  )
                }
                {
                  this.props.data.warranty_check &&(
                    <div className={`inline-block px-2 py-1 text-white text-base bg-label-blue rounded ${this.props.data.warranty_recall && 'ml-2'}`}>
                      保固終檢
                    </div>
                  )
                }
              </div>
              <Slick plateNo={get(data, 'car.plate_no', '').toUpperCase()} />
            </div>
          </div>
          <div className='sm:hidden pb-8 flex flex-row justify-center border-t' style={{width: '100%'}}>
            <ImageWithDefault
              alt='car head'
              src={ticketHelper.carImageHead(get(data, 'car.plate_no', '').toUpperCase())}
              default={ticketHelper.carImageDefault()}
              style={{ width:'63%', height:'100%', maxWidth:'300px' }} />
            <ImageWithDefaultHidden
              alt='car rear'
              src={ticketHelper.carImageRear(get(data, 'car.plate_no', '').toUpperCase())}
              default={ticketHelper.carImageDefault()}
              style={{ width:'63%', height:'100%', maxWidth:'300px' }} />
          </div>
          <div className='flex flex-row justify-between text-xl mt-4'>
            <div className="w-1/2">
              <div>
                <span className='text-grey-darker inline-block w-16'>WIP</span>
                <span className='font-black text-black'>{data.WIP_no ? data.WIP_no : '-'}</span>
              </div>
              <div className='mt-3'>
                <span className='text-grey-darker inline-block w-16'>TAG</span>
                <span className='font-black text-black'>{data.tag_no ? data.tag_no : '-'}</span>
              </div>
            </div>
            <div className="w-1/2">
              <div>
                <span className='text-grey-darker inline-block w-16'>系列</span>
                <span className='font-black text-black'>{get(data, 'car.series', '') ? get(data, 'car.series', '') : '-'}</span>
              </div>
              <div className='mt-3'>
                <span className='text-grey-darker inline-block w-16'>里程</span>
                <NumberFormat type='text' className='font-black text-black sm:w-32' thousandSeparator={true} value={get(data, 'car.mileage') ? get(data, 'car.mileage') : '-'} displayType={'text'} />
              </div>
            </div>
            <div/>
          </div>
          <div className='flex flex-row justify-between text-xl'>
            <div className='mt-3'>
              <span className='text-grey-darker inline-block w-16'>VIN</span>
              <span className='font-black text-black w-full'>{get(data, 'car.vin') ? get(data, 'car.vin') : '-'}</span>
            </div>
          </div>
        </div>
        <div className='pl-8 mt-6 pb-8'>
          <div className='flex flex-row'>
            <span className='text-4xl block font-black text-cruisys-blue w-8' >
              <span className='icon-ic_reception text-xl block mt-1' style={{ color: '#2a4b8d' }} />
            </span>
            <div className='ml-2 text-lg'>
              <div>
                <span className='pb-1 inline-block' style={{ width: '9rem' }}>預計進廠</span>
                <span>{timeToLabel(data.estimated_arrival_time)}</span>
              </div>
              <div>
                <span className='pb-1 inline-block' style={{ width: '9rem' }}>已接待</span>
                <span>{secondsToLabel(data.processed_reception_seconds)}</span>
              </div>
            </div>
          </div>

          <div className='flex flex-row mt-2'>
            <span className='text-4xl block font-black text-cruisys-blue w-8' >
              <span className='icon-ic_working text-xl block mt-1' style={{ color: '#f58c16' }} />
            </span>
            <div className='ml-2 text-lg'>
              <div>
                <span className='pb-1 inline-block' style={{ width: '9rem' }}>維修時間</span>
                <span>{secondsToLabel(data.estimated_repair_seconds)}</span>
              </div>
              <div>
                <span className='pb-1 inline-block' style={{ width: '9rem' }}>已維修</span>
                <span>{secondsToLabel(data.processed_repair_seconds)}</span>
              </div>
            </div>
          </div>

          <div className='flex flex-row mt-2'>
            <span className='text-4xl block font-black text-cruisys-blue w-8' >
              <span className='icon-ic_detailing text-xl block mt-1' style={{ color: '#32b16c' }} />
            </span>
            <div className='ml-2 text-lg'>
              <div>
                <span className='pb-1 inline-block' style={{ width: '9rem' }}>美容時間</span>
                <span>{secondsToLabel(data.estimated_detailing_seconds)}</span>
              </div>
              <div>
                <span className='pb-1 inline-block' style={{ width: '9rem' }}>已美容</span>
                <span>{secondsToLabel(data.processed_detailing_seconds)}</span>
              </div>
            </div>
          </div>

          <div className='flex flex-row mt-2'>
          <span className='text-4xl block font-black text-cruisys-blue w-8' >
            <span className='icon-ic_offline_pin text-xl block mt-1' style={{ color: '#7e7e7e' }} />
          </span>
          <div className='ml-2 text-lg'>
            <div>
              <span className='pb-1 inline-block' style={{ width: '9rem' }}>預計交車</span>
              <span>{timeToLabel(data.estimated_delivery_time)}</span>
            </div>
          </div>
        </div>
        </div>
        <TicketDetailLocation src={ticketHelper.locationImage(data)} loc={ticketHelper.location2name[ticketHelper.ticketLastLocation(data)]} />
        <div className='pl-8 mt-6 border-t hidden sm:block'>
          <ul className='list-reset text-lg mt-4 sm:mt-8'>
            <li className='mb-2 text-xl text-grey-darker'>確認事項</li>
          </ul>

          <div className='w-5/6 h-48 py-1 text-lg'>{get(data, 'checklists[0].comment', '')}</div>
        </div>

        <div className='border-t sm:hidden'>
          <div className='py-4 pl-8 pr-8 bg-grey-light flex flex-row justify-between items-center text-xl mb-1' onClick={this.toggleShowComment.bind(this)}>
            <span>確認事項</span>
            <Icon name={this.state.showComment ? 'angle up' : 'angle down'} />
          </div>
          {this.state.showComment
            ? <div className={`pl-8`} >
              <div className='w-5/6 h-48 py-1 text-lg'>{get(data, 'checklists[0].comment', '')}</div>
            </div>
            : ''}
        </div>

        <div className='pl-8 mt-6 border-t hidden sm:block'>
          <ul className='list-reset text-lg mb-4 mt-4 sm:mt-8'>
            <li className='mb-2 text-xl text-grey-darker'>聯絡資訊</li>
          </ul>
          {get(data, 'contact', '')
            ? <div>
                <div className='text-lg mb-4' style={{ display: 'grid', gridTemplateColumns: '6rem 8rem', gridColumnGap: '1rem', gridRowGap: '0.5rem' }}>
                  <div>客戶姓名</div>
                  <div>{get(data, 'contact.name', '')}</div>
                  <div>客戶稱謂</div>
                  <div>
                    {!get(data, 'contact', null)
                      ? ''
                      : get(data, 'contact.title', '先生/小姐') === "先生/小姐" || get(data, 'contact.title', '未指定') === "未指定" || !get(data, 'contact.title', '')
                        ? "貴賓"
                        : get(data, 'contact.title', '貴賓')
                    }
                  </div>
                </div>
                <div>
                  <a href={`tel:${get(data, 'contact.phone', '')}`}>
                    <button className='bg-cruisys-blue-dark text-white p-3 w-64 rounded-lg' disabled={!get(data, 'contact.phone', false)}>
                      <span className={'mr-2 text-xl icon-phone'} />
                      <span className='h-8 text-xl font-black'>
                        撥打電話
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            : ""
          }
        </div>
        { data.car_orig.contacts.length>0
        ? <div className={'mx-8 mt-6 hidden sm:block ' + ( get(data, 'contact', '') ? 'border-t': '')}>
            <ul className='list-reset text-lg mt-4 sm:mt-8'>
              <li className='mb-2 text-xl text-cruisys-green-dark'>Service Go 已綁定</li>
            </ul>
            <div className='lineContacts'>
              {data.car_orig.contacts.map(contact =>
                <LineContact data={contact} />
              )}
            </div>
          </div>
          : ""
        }

        <div className='border-t sm:hidden'>
          <div className='py-4 pl-8 pr-8 bg-grey-light flex flex-row justify-between items-center text-xl mb-1' onClick={this.toggleShowContact.bind(this)}>
            <span>聯絡資訊</span>
            <Icon name={this.state.showContact ? 'angle up' : 'angle down'} />
          </div>
          {this.state.showContact
          ? <div>
            { get(data, 'contact', '')
              ? <div>
                  <div className='pl-8 text-lg my-4' style={{ display: 'grid', gridTemplateColumns: '6rem 8rem', gridColumnGap: '1rem', gridRowGap: '0.5rem' }}>
                    <div>客戶姓名</div>
                    <div>{get(data, 'contact.name', '')}</div>
                    <div>客戶稱謂</div>
                    <div>
                      {!get(data, 'contact', null)
                        ? ''
                        : get(data, 'contact.title', '先生/小姐') === "先生/小姐" || get(data, 'contact.title', '未指定') === "未指定" || !get(data, 'contact.title', '')
                          ? "貴賓"
                          : get(data, 'contact.title', '貴賓')
                      }
                    </div>
                  </div>
                  <div className={'border-solid border-grey-dark mx-4 pb-6 px-4 '+ (data.car_orig.contacts.length>0 ? 'border-b' : '')}>
                    <a href={`tel:${get(data, 'contact.phone', '')}`}>
                      <button className='bg-cruisys-blue-dark text-white p-3 w-full rounded-lg' disabled={!get(data, 'contact.phone', false)}>
                        <span className={'mr-2 text-xl icon-phone'} />
                        <span className='h-8 text-xl font-black'>
                          撥打電話
                        </span>
                      </button>
                    </a>
                  </div>
                </div>
              : "" }
              { data.car_orig.contacts.length>0
                ? <div>
                    <ul className='list-reset text-lg pl-8 pt-8'>
                      <li className='mb-2 text-xl text-cruisys-green-dark'>Service Go 已綁定</li>
                    </ul>
                    <div className='lineContacts'>
                      {data.car_orig.contacts.map(contact =>
                        <LineContact data={contact} />
                      )}
                    </div>
                  </div>
                : ""
              }
            </div>
          : ''}
        </div>
      </div>
    )
  }
}
