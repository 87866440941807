import moment from 'moment'
import { get } from 'lodash'
import 'moment/locale/zh-tw'

import API from './api'

import { isString } from 'lodash'

moment.locale('zh-tw')

function newFormValue (assigneeID) {
  return {
    booked: true,
    // stay_in: 'null',
    customer_status: '當天決定',
    service_type :'null',
    car: {
      plate_no: '',
      series: '',
    },
    assignee: assigneeID,

    contact: {
      name: '',
      phone: '',
      title: '',
    },

    timeFormValue: {
      estimated_delivery_time: moment().hour(17).minute(30).format('YYYY-MM-DDTHH:mm'),
      estimated_arrival_time: moment().format('YYYY-MM-DDTHH:mm'),
      estimated_detailing_time: { hr: null, m: 30 },
      estimated_repair_time: { hr: 1, m: 0 }
    }
  }
}

function ticket2formValue (ticket, defaultAssignee) {
  let formValue = deepClone(ticket)
  // convert booleans to string for form
  // if (formValue.stay_in === null || formValue.stay_in === undefined) {
  //   formValue.stay_in = 'null'
  // } else {
  //   formValue.stay_in = formValue.stay_in.toString()
  // }
  formValue.booked = formValue.booked.toString()
  if (formValue.customer_status === null || formValue.customer_status === undefined) {
    formValue.customer_status = 'null'
  } else {
    formValue.customer_status = formValue.customer_status.toString()
  }
  if (formValue.service_type === null || formValue.service_type === undefined) {
    formValue.service_type = 'null'
  } else {
    formValue.service_type = formValue.service_type.toString()
  }

  // 如果 ticket 沒有 assignee，就預設成自己
  if (!formValue.assignee) {
    formValue.assignee = defaultAssignee
  }

  // 如果 formValue 已經有由 server 來的 assignee（一個 object）
  // 就只留下 assignee._id
  if (!isString(formValue.assignee)) {
    formValue.assignee = formValue.assignee._id
  }

  // 替空的 contact 填上空值
  if (!formValue.contact) {
    formValue.contact = { name: '', title: '', phone: ''}
  } else{
    // 若有 contact，檢查是否 title 為空值或 legacy
    if(!formValue.contact.title || formValue.contact.title === '先生/小姐'){
      formValue.contact.title = "未指定"
    }
  }

  // 將時間資料轉換成字串
  formValue.timeFormValue = extractTimeFormValue(formValue)

  return formValue
}

function formValue2ticket (formValue) {
  let ticket = deepClone(formValue)
  // convert string "true"/"false" to boolen
  // switch (ticket.stay_in) {
  //   case 'true':
  //     ticket.stay_in = true
  //     break
  //   case 'false':
  //     ticket.stay_in = false
  //     break
  //   case 'null':
  //     ticket.stay_in = null
  //     break
  //   default:
  //     break
  // }

  if(ticket.service_type === 'null'){
    ticket.service_type = null;
  }

  switch (ticket.assignee) {
    case 'null':
      ticket.assignee = null
      break
    default:
      break
  }

  switch (ticket.booked) {
    case 'true':
      ticket.booked = true
      break
    case 'false':
      ticket.booked = false
      break
    default:
      break
  }

  // 無 contact, 不帶 contact 給後端
  if (!ticket.contact.name && !ticket.contact.phone){
    ticket.contact = null
  }else{
    // 有contact, 將空字串 title 改為預設值「先生」
    if(ticket.contact.title === '') {
      ticket.contact.title = '先生'
    }
  }

  // 將時間字串資料轉回時間
  ticket.estimated_arrival_time = (ticket.timeFormValue.estimated_arrival_time && ticket.timeFormValue.estimated_arrival_time !== '') ? moment(ticket.timeFormValue.estimated_arrival_time, 'YYYY-MM-DDTHH:mm').format() : null
  ticket.estimated_delivery_time = (ticket.timeFormValue.estimated_delivery_time && ticket.timeFormValue.estimated_delivery_time !== '') ? moment(ticket.timeFormValue.estimated_delivery_time, 'YYYY-MM-DDTHH:mm').format() : null
  ticket.estimated_detailing_seconds = ticket.timeFormValue.estimated_detailing_time.hr * 60 * 60 + ticket.timeFormValue.estimated_detailing_time.m * 60
  ticket.estimated_repair_seconds = ticket.timeFormValue.estimated_repair_time.hr * 60 * 60 + ticket.timeFormValue.estimated_repair_time.m * 60
  delete ticket['timeFormValue']

  return ticket
}

function trimFormField(ticket){
  ticket.car.plate_no = ticket.car.plate_no.trim()
  ticket.WIP_no && (ticket.WIP_no = ticket.WIP_no.trim())
  ticket.tag_no && (ticket.tag_no = ticket.tag_no.trim())
  ticket.car.series && (ticket.car.series = ticket.car.series.trim())
  ticket.car.vin && (ticket.car.vin = ticket.car.vin.trim())
  ticket.checklists && ticket.checklists[0] && ticket.checklists[0].comment && (ticket.checklists[0].comment = ticket.checklists[0].comment.trim())
  ticket.contact.name && (ticket.contact.name = ticket.contact.name.trim())
  ticket.contact.phone && (ticket.contact.phone = ticket.contact.phone.trim())

  return ticket;
}

function deepClone (obj) {
  return JSON.parse(JSON.stringify(obj))
}

function updateContact (ticketFormValue, patch) {
  ticketFormValue.car.series = patch.series
  ticketFormValue.car.vin = patch.vin
  // assign found car contacts to original ticket value
  let arrangedcontact = null;
  if(patch.contact){
    arrangedcontact = patch.contact
    // adjust legacy value
    const title = get(arrangedcontact, 'title', '')
    if (!title || title === '先生/小姐'){
      arrangedcontact.title = '未指定'
    }
  }
  if(!arrangedcontact){
    arrangedcontact = {name: '', title: '', phone: ''};
  }
  ticketFormValue.contact = arrangedcontact;
}

function resetContact (ticketFormValue) {
  ticketFormValue.car.series = ''
  ticketFormValue.car.vin = ''
  ticketFormValue.contact = {
    name: '',
    title: '',
    phone: ''
  }
}

function extractTimeFormValue (ticket) {
  return {
    estimated_delivery_time: ticket.estimated_delivery_time ? moment(ticket.estimated_delivery_time).format('YYYY-MM-DDTHH:mm') : '',
    estimated_arrival_time: ticket.estimated_arrival_time ? moment(ticket.estimated_arrival_time).format('YYYY-MM-DDTHH:mm') : '',
    estimated_detailing_time: { hr: Math.floor(ticket.estimated_detailing_seconds / 3600), m: Math.floor(ticket.estimated_detailing_seconds / 60) % 60 },
    estimated_repair_time: { hr: Math.floor(ticket.estimated_repair_seconds / 3600), m: Math.floor(ticket.estimated_repair_seconds / 60) % 60 }
  }
}

async function validateTag(value, setStatus) {
  let error;
  var tag_nolimit = /^[0-9]{1,5}$/;
  this.lastCheckedTag = value

  // 每次 validate 都先 reset tag state, 此 state 用來表示 warning message
  // warning 僅在前端顯示文字，不擋 submit
  setStatus({tag:null});

  if(value && tag_nolimit.test(value) === false){
    error = "5碼內限數字";
  }else if(value){
    try{
      let tag = await API.getTag(this.state.user, value)

      // 此 tag 已有綁車牌，且非此車
      if(tag.plate_no && tag.plate_no !== this.lastCheckedPlateNO){
        error = `已使用在${tag.plate_no}上`
      } 
      else if(tag.lost){
        error = "請檢查TAG電量";
      }
      // else if(tag.low_power){
      //   setStatus({tag:`低電量${tag.power}%`});
      // }
    }catch(err){
      console.error(err)
      //handle tag 不存在
      if(err.response.status == 404){
        error = "請輸入有效TAG"
      }
    }
  }

  return error;
}

function validateWIP(value) {
  let error;
  var wip_nolimit = /^[0-9]{5}$/;
  if(value && wip_nolimit.test(value) === false){
   error = "5碼限數字";
  }
  return error;
}

async function validatePlateNo(value) {
    var plate_nolimit = /^[A-Za-z0-9]{6,7}$/;
    let currentPlateNO = value.toUpperCase();
    this.setState({ newCarID: '' });

    if(value === undefined || value === null || value === ''){
      return "車牌為必填";
    }
    else if(value && plate_nolimit.test(value) === false){    
      return "6-7碼英數";
    }
    if(this.state.plate_no && this.state.plate_no === value){
      return false;
    }
    let cars = await API.getCar(this.state.user, currentPlateNO);
    cars = cars.filter(c => c.plate_no === currentPlateNO)

    let newCar = cars[0]
    if (newCar) {
      let car = newCar
      this.setState({ newCarID: car._id });
      this.setState({ carHadTickitId: car.started_ticket_id});
      if(car.started_ticket_id === undefined || car.started_ticket_id === null || car.started_ticket_id === 0){
        return false;
      }else{
        // ${currentPlateNO} 已被建立
        return `已存在有效服務單`;
      }
    } else {
      this.baseCar = null;
      this.setState({ newCarID: '' });
      this.setState({ carHadTickitId: ''});
    }
    return false;
}

function validateVin(value) {
  let error;
  var vinlimit = /^[A-Za-z0-9]{7}$|^[A-Za-z0-9]{17}$/;
  if(value && vinlimit.test(value) === false){
    error = "7或17碼英數";
  }
  return error;
}

async function validateForm (values) {
  try {
    this.changed = true;
    let currentPlateNO = values.ticket.car.plate_no.toUpperCase();
    forceNumberOnly(values.ticket);
    const errors = {};
    if (currentPlateNO !== this.lastCheckedPlateNO && currentPlateNO.length >= 5 && currentPlateNO.length <= 7) {
      this.lastCheckedPlateNO = currentPlateNO
      let cars = await API.getCar(this.state.user, currentPlateNO);
      // 確保目前執行所使用的資料是最後一筆 API response 的結果
      if (currentPlateNO === this.lastCheckedPlateNO){
        cars = cars.filter(c => c.plate_no === currentPlateNO)
        let newCar = cars[0]
        if (newCar) {
          let car = newCar
          updateContact(values.ticket, {
            series: car.series,
            vin: car.vin,
            contact: car.contact
          });
          this.baseCar = car;
          this.setState({ newCarID: car._id });
        } else {
          this.baseCar = null;
          resetContact(values.ticket);
          this.setState({ newCarID: '' });
        }
      }
    } 
  } catch (e) {
    console.error(e)
  }
}

function forceNumberOnly (ticket) {
  if (typeof ticket.timeFormValue.estimated_repair_time.hr === 'string') {
    ticket.timeFormValue.estimated_repair_time.hr = ticket.timeFormValue.estimated_repair_time.hr.replace(/\D/, '')
  }
  if (typeof ticket.timeFormValue.estimated_repair_time.m === 'string') {
    ticket.timeFormValue.estimated_repair_time.m = ticket.timeFormValue.estimated_repair_time.m.replace(/\D/, '')
  }
  if (typeof ticket.timeFormValue.estimated_detailing_time.hr === 'string') {
    ticket.timeFormValue.estimated_detailing_time.hr = ticket.timeFormValue.estimated_detailing_time.hr.replace(/\D/, '')
  }
  if (typeof ticket.timeFormValue.estimated_detailing_time.m === 'string') {
    ticket.timeFormValue.estimated_detailing_time.m = ticket.timeFormValue.estimated_detailing_time.m.replace(/\D/, '')
  }
}

export default {
  deepClone,

  newFormValue,
  ticket2formValue,
  formValue2ticket,
  trimFormField,

  updateContact,
  resetContact,

  validateTag,
  validateWIP,
  validatePlateNo,
  validateVin,
  validateForm
}
