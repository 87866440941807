import React, { Component } from 'react'
import { Container } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'

import LoginForm from '../components/LoginForm'

import bg from '../img_login_bg.png';

class LoginFormPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    // is logged in?
    let u = window.localStorage.getItem('loggedIn')
    if (u) {
      return (
        <Redirect to={{ pathname: '/stats'}} />
      )
    }

    return (
      <div className='flex flex-col align-center better-h-screen justify-between bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <div className='w-100 bg-white' />
        <Container className='text-center'>
          <img src='/BMW_type.svg' alt='BMW Service' />
          <h2 className='text-white mt-2 mb-16' style={{ fontSize: '24px' }}>Smart Workshop&trade; 智慧車廠</h2>
            <div className='flex justify-center' >
              <div style={{ width: '300px' }}>
                <LoginForm />
              </div>
            </div>
        </Container>
        <div className='text-white pb-10 text-2xl text-center'>Powered by Cruisys </div>
      </div>
    )
  }
}

export default LoginFormPage
