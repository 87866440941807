import React, { Component } from 'react'
import { Container } from 'semantic-ui-react'
import { Redirect, Link } from 'react-router-dom'

import LoginErrorModal from '../components/LoginErrorModal'
import LoadingOverlay from '../components/LoadingOverlay'
import API from '../api'

import bg from '../img_login_bg.png';
import _ from 'lodash';

const originURL = window.location.protocol + "//" + window.location.host;
const urlString = window.location.href;

class LoginPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showLoginError: false,
      redirecting: false
    }
  }

  async componentWillMount () {

    if(_.has(this.props.location.state,"refreshLogin")){
        window.location.reload();
    }

    // after logged in Line account, user will be redirected to this page with Line auth-code
    let url = new URL(urlString);
    // handling login failed
    this.setState({redirecting: false})
    // with Line auth-code?
    if (url.searchParams.has("code")){
      this.setState({redirecting: true})
      try{
        await window.liff.init({ liffId: window.cruisys_config.LIFF_ID })
        const accessToken = await window.liff.getAccessToken()
        await this.authenticateWithLine(accessToken)
      } catch(e){
        console.error(e)
      }
      // authenticated successfully
      if (!this.state.showLoginError){
        window.location.href = originURL;
      }
    }
  }

  clearLineCache(){
    const localStorage = window.localStorage
    const regex = /LIFF_STORE/
    let i = 0;
    while(i < localStorage.length){
      const key = localStorage.key(i)
      if (key.match(regex)){
        localStorage.removeItem(key)
      }
      else{
        i++
      }
    }
  }

  async authenticateWithLine(accessToken){
      try{
        console.log("access token:", accessToken)
        let user = await API.loginWithLine(accessToken)
        window.localStorage.setItem('loggedIn', JSON.stringify(user))
      } catch(e){
        console.error(e)
        // SWS backend doesn't have the information with this line account
        this.setState({
          showLoginError: true,
        })
      }
  }

  async redirectToLineLoginPage(){
    const liff = window.liff
    const LIFF_ID = window.cruisys_config.LIFF_ID;
    if(!LIFF_ID){
      console.error("LIFF_ID undefined")
      return
    }

    try{
      // LIFF SDK cache access token in local storage, but we always want a new one
      this.clearLineCache()

      // liff.login() will redirect to LINE login page
      await liff.init({ liffId: LIFF_ID })
      await liff.login({ redirectUri: originURL });
    } catch(e){
      console.error('LIFF set up failed')
      console.log(e)
    }
  }

  redirectToLoginPage(){
    this.setState({showLoginError: false})
    window.location.href = originURL;
  }

  render () {
    // is logged in?
    let u = window.localStorage.getItem('loggedIn')
    if (u) {
      return (
        <Redirect to={{ pathname: '/stats'}} />
      )
    }

    return (
      <div className='flex flex-col align-center better-h-screen justify-between bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${bg})` }}>
        <LoadingOverlay show={this.state.redirecting}/>
        <LoginErrorModal
          open={this.state.showLoginError}
          handleOk={this.redirectToLoginPage.bind(this)}/>
        <div className='w-100 bg-white' />
        <Container className='text-center'>
          <img src='/BMW_type.svg' alt='BMW Service' />
          <h2 className='text-white mt-2 mb-16' style={{ fontSize: '24px' }}>Smart Workshop&trade; 智慧車廠</h2>
            <div>
              <button
                className='block mx-auto px-10 py-4 rounded-full bg-line-green text-white font-bold text-3xl'
                onClick={this.redirectToLineLoginPage.bind(this)}>
                  使用 LINE 帳號登入
              </button>
              <Link to={`/login-form`}>
                <button
                  className='block mx-auto mt-8 text-white text-2xl underline' >
                  使用帳密登入
                </button>
              </Link>
            </div>
        </Container>
        <div className='text-white pb-10 text-2xl text-center'>Powered by Cruisys </div>
      </div>
    )
  }
}

export default LoginPage
