import React, { Component } from 'react'

import Slick from '../components/Slick'
import API from '../api'
import { get, isString } from 'lodash'
import moment from 'moment'
import 'moment/locale/zh-tw'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Prompt } from 'react-router'
import { Icon } from 'semantic-ui-react'

import formHelper from '../formHelper'
import avatarUrl from '../avatarUrl'
import Loading from './Loading'
import ImageWithDefault from './ImageWithDefault'
import ImageWithDefaultHidden from './ImageWithDefaultHidden'
import ServiceTypeSelect from './TicketField/ServiceTypeSelect'

import ticketHelper from '../ticketHelper'

import { diff } from 'deep-object-diff'
import NumberFormat from 'react-number-format';

moment.locale('zh-tw')

const contactFormStyle = {
  display: 'grid',
  gridTemplateColumns: '8rem 1fr',
  gridColumnGap: '1rem',
  gridRowGap: '0.5rem'
}

export default class TicketDetailForm extends Component {
  constructor(props) {
    super(props)

    let u = window.localStorage.getItem('loggedIn')
    if (u) u = JSON.parse(u)
    this.state = {
      user: u,
      users: [],
      loading: true,
      newCarID: undefined,
      showComment: false,
      showContact: false,
      plate_no: '',
    }
    this.changed = false
    this.baseCar = null
    this.lastCheckedPlateNO = ''
  }

  toggleShowComment() {
    if (this.state.showComment) {
      this.setState({ showComment: false })
    } else {
      this.setState({ showComment: true })
    }
  }

  toggleShowContact() {
    if (this.state.showContact) {
      this.setState({ showContact: false })
    } else {
      this.setState({ showContact: true })
    }
  }

  async loadUsers() {
    let users = await API.getUsers(this.state.user)

    this.setState({ users: users, loading: false })
  }

  componentDidMount() {
    this.loadUsers()
    this.baseCar = this.props.data.car
    this.lastCheckedPlateNO = this.props.data.car.plate_no.toUpperCase()
    this.setState({plate_no: this.props.data.car.plate_no.toUpperCase() });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className='bg-white min-h-screen'>
          <Loading type='bubbles' color='#3E47F9' />
        </div>
      )
    }
    let data = this.props.data

    let oldTicket = formHelper.deepClone(data)

    // 將 ticket 轉成適合表單使用的 FormValue
    let ticket = formHelper.ticket2formValue(data, this.state.user.data._id)
    console.log('ticket is =>', ticket);
    // 如果 ticket 已經有由 server 來的 assignee（一個 object）
    // 就只留下 assignee._id
    if (!isString(ticket.assignee)) {
      ticket.assignee = ticket.assignee._id
    }

    return (
      <div className='bg-white min-h-screen'>
        <Formik
          initialValues={{ ticket }}
          onSubmit={async (values, actions) => {
            this.changed = false
            try {
              // if baseCarID === null
              // create car, get car ID
              let carID = values.ticket.car._id
              if (!this.baseCar) {
                // 這是一台全新的車輛
                let newCar = values.ticket.car
                // 這些值前端不會知道
                delete newCar['_id']
                delete newCar['created_time']
                delete newCar['make']
                delete newCar['model']
                delete newCar['service_ticket_id']
                let ret = await API.createCar(this.state.user, newCar)
                carID = ret._id
              }
              if (this.baseCar && this.baseCar._id !== ticket.car._id) {
                // 這是一台已知的車輛，但是不是此服務單原本的車
                carID = this.baseCar._id
              }

              //處理字串前後空白
              values.ticket = formHelper.trimFormField(values.ticket);              
              
              // 找出需要 patch 的部分
              let newTicket = formHelper.formValue2ticket(values.ticket);
              console.log('newTicket is =>', newTicket);
              let updates = diff(oldTicket, newTicket);
              if (updates.checklists) { // 如果 checklists 有修改的話，就直接把新的 checklists 整個傳過去
                updates.checklists = newTicket.checklists
              }
              // 如果車牌改了，就在 updates 填上 car: id
              if (Object.keys(updates).includes('car')) {
                updates.car = carID
              }

              let ret = await API.updateTicket(this.state.user, newTicket._id, updates)
              console.log('ret', ret)

              // create snapshot for given ticket ID with car data
              let carSnapshot = newTicket.car
              // 這些值前端不會知道
              delete carSnapshot['created_time']
              delete carSnapshot['make']
              delete carSnapshot['model']

              let r = await API.createCarSnapshot(this.state.user, ret._id, carID, carSnapshot)
              console.log(r)
              this.props.onUpdated()
            } catch (e) {
              console.error(e)
              actions.setErrors({ form: '更新失敗，請檢查更新內容' })
              actions.setSubmitting(false)
            }
          }}
          validate={formHelper.validateForm.bind(this)}
          render={({ errors, status, touched, isSubmitting, dirty, isValid, values, setFieldValue, setStatus }) => {
            let ticket = values.ticket
            let avatar
            if (values.ticket.assignee) {
              let u = this.state.users.filter(u => u.data._id === values.ticket.assignee)[0]
              avatar = avatarUrl(u.data.avatar)
            }
            //console.log(ticket.service_type);

            return (
              <Form className='pb-20'>
                <Prompt
                  when={this.changed}
                  message={location => '表單有未儲存的修改，是否要捨棄變更？'}
                />
                <div className='overflow-hidden sm:my-2 pb-4 border-b px-4 sm:pl-8'>
                  <ErrorMessage name='form' component='div' className='p-4 mt-4 w-5/6 text-white bg-cruisys-red rounded text-2xl font-bold text-center' />
                  <div className='py-4' style={{ paddingTop: '1.75rem' }}>
                    <button type='submit' className='bg-cruisys-blue text-white p-3 w-32 rounded-lg mr-4 text-xl font-black' style={{ float: 'right' }} onClick={this.props.completeHandler} disabled={isSubmitting || !isValid}>{(touched && dirty && !isValid ? '資料有誤' : '儲存更新')}</button>                    
                    <button className='bg-grey-light text-black p-3 w-32 rounded-lg text-xl' onClick={this.props.cancelHandler}>取消</button>
                  </div>
                  <div className='sm:pb-4 ticket-detail-form'>
                  <div className='pt-2'>
                  <div className='flex flex-row mb-2 items-start'>
                    <div className='flex-row mb-4 pl-2'>
                      {this.props.istraced 
                        ? <div className={'uppercase font-black text-3xl text-black font-sans'}>{ticket.car.plate_no}</div>
                        : (errors.ticket && errors.ticket.car && errors.ticket.car.plate_no
                          ? <React.Fragment>
                              <Field name='ticket.car.plate_no' className={'uppercase font-black text-3xl text-black font-sans ' + (errors.ticket && errors.ticket.car && errors.ticket.car.plate_no ? 'is-invalid-field' : 'border')} placeholder='AS1234' style={{textTransform: 'uppercase',width:'225px' }} validate={formHelper.validatePlateNo.bind(this)} maxLength={7} />
                              <div className='is-invalid-text text-xl'>{errors.ticket.car.plate_no}</div>
                            </React.Fragment>
                          : <Field name='ticket.car.plate_no' className={'uppercase font-black text-3xl text-black font-sans ' + (errors.ticket && errors.ticket.car && errors.ticket.car.plate_no ? 'is-invalid-field' : 'border')} placeholder='AS1234' style={{textTransform: 'uppercase',width:'225px' }} validate={formHelper.validatePlateNo.bind(this)} maxLength={7} />
                        )
                      }
                    </div>
                  </div>
                      <div className='block sm:hidden flex items-center' id='detail-ticket-assignee-input-label'>
                        <h3 className='text-xl text-grey-darker'>負責人</h3>
                      </div>
                      <div className='flex flex-row mb-4 mt-2 items-center'>
                        {values.ticket.assignee
                          ? <ImageWithDefault
                            alt='user_avatar'
                            src={avatar}
                            default={ticketHelper.userImageDefault()}
                            className='rounded-full hidden sm:inline-block'
                            style={{ width: '40px', height: '40px' }} />
                          : <img alt='' src='/empty_user.png' style={{ width: '40px', height: '40px' }} />}
                        <div className='sm:pl-2 w-full sm:w-auto'>
                          <div className='text-lg font-black text-black'>
                            <Field component='select' className='sm:mx-3 w-full sm:w-auto select-css' name='ticket.assignee'>
                              {this.state.users.map(u => <option value={u.data._id} key={u.data._id}>{u.data.name}</option>)}
                            </Field>
                          </div>
                        </div>
                      </div>
                      <div className='block sm:hidden flex items-center' id='detail-ticket-booked-input-label'>
                        <h3 className='text-xl text-grey-darker'>預約狀況</h3>
                      </div>
                      <div className='text-lg text-black mt-2 mb-4 flex flex-row items-center'>
                        <span className='icon-ic_reservation block mr-3 text-cruisys-blue hidden sm:inline-block' />
                        <Field component='select' className='sm:mx-3 w-full sm:w-auto select-css' name='ticket.booked'>
                          <option value='true'>預約</option>
                          <option value='false'>自來</option>
                        </Field>
                      </div>
                      <div className='block sm:hidden flex items-center' id='detail-ticket-service-input-label'>
                        <h3 className='text-xl text-grey-darker'>服務類型</h3>
                      </div>
                      <div className='text-lg text-black mt-2 mb-4 flex flex-row items-center' id='detail-ticket-service-input'>
                        <span className='icon-ic_star-full block mr-3 hidden sm:inline-block' />
                        <ServiceTypeSelect className='sm:mx-3 w-full sm:w-auto select-css' name='ticket.service_type' />
                      </div>
                      <div className='block sm:hidden flex items-center' id='detail-ticket-stayin-input-label'>
                        <h3 className='text-xl text-grey-darker'>來賓狀態</h3>
                      </div>
                      <div className='text-lg text-black mt-2 mb-4 flex flex-row items-center' id='detail-ticket-stayin-input'>
                        <span className='icon-ic_customer_here block mr-3 text-cruisys-red hidden sm:inline-block' />
                        <Field component='select' className='sm:mx-3 w-full sm:w-auto select-css' name='ticket.customer_status'>
                          <option value='內候'>內候</option>
                          <option value='留車'>留車</option>
                          <option value='取 / 送'>取 / 送</option>
                          <option value='取車回廠'>取車回廠</option>
                          <option value='接駁'>接駁</option>
                          <option value='當天決定'>當天決定</option>
                        </Field>
                      </div>
                    </div>
                    <div className='sm:flex justify-center items-center relative hidden'>
                      {values.ticket.car.series === '' ? <Slick plateNo='' /> : <Slick plateNo={get(ticket, 'car.plate_no', '').toUpperCase()} />}
                    </div>
                  </div>

                  <div className='sm:flex flex-row justify-between sm:mt-4 text-xl'>
                    <div className="w-1/2">
                      <div className="flex flex-col">                        
                        <div className='flex-row'>
                          <span className='text-grey-darker block sm:inline-block w-12 font-bold sm:font-normal mt-8 mb-4 sm:my-0'>WIP</span>
                          <Field name='ticket.WIP_no' type='tel' className={'w-full sm:w-32 p-2 inline-block rounded leading-tight focus:outline-none focus:shadow-outline border-box ' + (errors.ticket && errors.ticket.WIP_no ? 'is-invalid-field' : 'border')} placeholder='12345' maxLength={5} validate={formHelper.validateWIP} />
                        </div>
                        {errors.ticket && errors.ticket.WIP_no
                          ? <div className='sm:ml-12 is-invalid-text'>{errors.ticket.WIP_no}</div>
                          : ''
                        }
                      </div>
                      <div className='sm:mt-3 flex flex-col'>                        
                        <div className='flex-row'>
                          <span className='text-grey-darker block sm:inline-block w-12 font-bold sm:font-normal my-4 sm:my-0'>TAG</span>
                          <Field
                            name='ticket.tag_no'
                            type='tel'
                            className={'w-full sm:w-32 p-2 inline-block rounded leading-tight focus:outline-none focus:shadow-outline border-box ' + (errors.ticket && errors.ticket.tag_no ? 'is-invalid-field' : 'border') }
                            placeholder='12345'
                            maxLength={5}
                            validate={ async (value) => {
                              return await formHelper.validateTag.call(this, value, setStatus);
                            }} />
                        </div>
                        {errors.ticket && errors.ticket.tag_no
                          ? <div className='sm:ml-12 is-invalid-text'>{errors.ticket.tag_no}</div>
                          : status && status.tag
                            ? <div className='sm:ml-12 is-warning-text'>{status.tag}</div>
                            : ''
                        }
                      </div>
                    </div>
                    <div className="w-1/2">
                      <div className=''>
                        <span className='text-grey-darker block sm:inline-block w-12 font-bold sm:font-normal my-4 sm:my-0'>系列</span>
                        <Field name='ticket.car.series' type='text' className='w-full sm:w-32 p-2 inline-block border rounded leading-tight focus:outline-none focus:shadow-outline border-box' />
                      </div>
                      <div className='sm:mt-3'>
                        <span className='text-grey-darker block sm:inline-block w-12 font-bold sm:font-normal my-4 sm:my-0'>里程</span>
                        <NumberFormat
                          className='w-full sm:w-32 p-2 inline-block border rounded leading-tight focus:outline-none focus:shadow-outline border-box'
                          type='tel'
                          placeholder='999,999'
                          maxLength={7}
                          allowNegative={false}
                          decimalScale={0}
                          thousandSeparator={true}
                          value={values.ticket.car.mileage}
                          onValueChange={val => {setFieldValue('ticket.car.mileage', val.floatValue)}}
                          isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            return  formattedValue === "" || floatValue <= 999999;
                          }} />
                      </div>
                    </div>
                  </div>

                  <div className='flex flex-row flex-wrap sm:flex-no-wrap justify-between text-xl inline-block sm:mt-3 sm:mr-4 w-1/2 sm:w-full'>
                      <div className='text-grey-darker block sm:inline-block w-12 font-bold sm:font-normal my-4 sm:my-auto sm:mr-1'>VIN</div>
                      <Field
                        name='ticket.car.vin'
                        className={'w-full p-2 inline-block rounded leading-tight focus:outline-none focus:shadow-outline border-box '
                          + (errors.ticket && errors.ticket.car && errors.ticket.car.vin
                            ? 'is-invalid-field'
                            : 'border')}
                        maxLength={17}
                        placeholder='V12345678901234567'
                        validate={formHelper.validateVin} />
                  </div>
                  {errors.ticket && errors.ticket.car && errors.ticket.car.vin
                    ? <div className='flex flex-row sm:ml-12 text-xl is-invalid-text'>{errors.ticket.car.vin}</div>
                    : ''
                  }

                  <div className='sm:hidden pb-8 flex flex-row justify-center border-t mt-3' style={{ width: '130%', marginLeft: '-15%' }}>
                    <ImageWithDefault
                      alt='car head'
                      src={ticketHelper.carImageHead(get(ticket, 'car.plate_no', '').toUpperCase())}
                      default={ticketHelper.carImageDefault()}
                      style={{ width:'45%', height:'100%', maxWidth:'300px' }} />
                    <ImageWithDefaultHidden
                      alt='car rear'
                      src={ticketHelper.carImageRear(get(ticket, 'car.plate_no', '').toUpperCase())}
                      default={ticketHelper.carImageDefault()}
                      style={{ width:'45%', height:'100%', maxWidth:'300px' }} />
                  </div>
                </div>

                <div className='pl-8 mt-6'>
                  <div className='flex flex-row'>
                    <span className='text-4xl block font-black text-cruisys-blue w-8' >
                      <span className='icon-ic_reception text-xl block mt-1' style={{ color: '#1F4287' }} />
                    </span>
                    <div className='ml-2 text-lg'>
                      <div>
                        <span className='pb-1 inline-block' style={{ width: '8.5rem' }}>預計進廠</span>
                        <Field name='ticket.timeFormValue.estimated_arrival_time' type='datetime-local' className='border border-grey mt-1' />
                      </div>
                    </div>
                  </div>

                  <div className='flex flex-row mt-3 items-center'>
                    <span className='text-4xl block font-black text-cruisys-blue w-8' >
                      <span className='icon-ic_working text-xl block mt-1' style={{ color: '#FF8373' }} />
                    </span>
                    <div className='ml-2 text-lg'>
                      <div>
                        <span className='pb-1 inline-block' style={{ width: '8.5rem' }}>維修時間</span>
                        <span>
                          <Field type='tel' name='ticket.timeFormValue.estimated_repair_time.hr' className='mr-2 w-16 border border-grey p-2' maxLength={2} />
                          時
                          <Field type='tel' name='ticket.timeFormValue.estimated_repair_time.m' className='ml-2 mr-2 w-16 border border-grey p-2' maxLength={2} />
                          分
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className='flex flex-row mt-3 items-center'>
                    <span className='text-4xl block font-black text-cruisys-blue w-8' >
                      <span className='icon-ic_detailing text-xl block mt-1' style={{ color: '#00D4FF' }} />
                    </span>
                    <div className='ml-2 text-lg'>
                      <div>
                        <span className='pb-1 inline-block' style={{ width: '8.5rem' }}>美容時間</span>
                        <span>
                          <Field type='tel' name='ticket.timeFormValue.estimated_detailing_time.hr' className='mr-2 w-16 border border-grey p-2' maxLength={2} />
                          時
                          <Field type='tel' name='ticket.timeFormValue.estimated_detailing_time.m' className='ml-2 mr-2 w-16 border border-grey p-2' maxLength={2} />
                          分
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className='flex flex-row mt-3'>
                    <span className='text-4xl block font-black text-cruisys-blue w-8' >
                      <span className='icon-ic_offline_pin text-xl block mt-1' style={{ color: '#6369F8' }} />
                    </span>
                    <div className='ml-2 text-lg'>
                      <div>
                        <span className='pb-1 inline-block' style={{ width: '8.5rem' }}>預計交車</span>
                        <Field name='ticket.timeFormValue.estimated_delivery_time' type='datetime-local' className='border border-grey mt-1' />
                      </div>
                    </div>
                  </div>

                </div>
                <div className='pl-8 mt-8 hidden sm:block'>
                  <ul className='list-reset text-lg mt-4'>
                    <li className='mb-2 text-xl text-grey-darker'>確認事項</li>
                  </ul>

                  <Field component='textarea' className='w-5/6 h-48 border border-grey-dark mb-20 p-2 text-xl' name='ticket.checklists[0].comment' placeholder='需要注意事項備註' />
                </div>

                <div className='mt-8 sm:hidden'>
                  <div className='py-4 pl-8 pr-8 mb-4 bg-grey-light flex flex-row justify-between items-center text-xl mb-1' onClick={this.toggleShowComment.bind(this)}>
                    <span>確認事項</span>
                    <Icon name={this.state.showComment ? 'angle up' : 'angle down'} />
                  </div>

                  {this.state.showComment ? <Field component='textarea' className='ml-8 w-5/6 h-48 border border-grey-dark mb-8 sm:mb-20 p-2 text-xl' name='ticket.checklists[0].comment' placeholder='需要注意事項備註'/> : ''}
                </div>

                <div className='mt-2 sm:hidden'>
                  <div className='py-4 pl-8 pr-8 bg-grey-light flex flex-row justify-between items-center text-xl mb-1' onClick={this.toggleShowContact.bind(this)}>
                    <span>聯絡資訊</span>
                    <Icon name={this.state.showContact ? 'angle up' : 'angle down'} />
                  </div>
                  {this.state.showContact
                    ? <div className='pl-8 mt-2'>
                        <div className='text-lg mb-4 mt-4' style={contactFormStyle}>
                          <div className='flex flex-row items-center'>客戶姓名</div>
                          <Field name='ticket.contact.name' className='border border-grey mx-4 p-2' />
                          <div className='flex flex-row items-center'>客戶稱謂</div>
                          <Field name='ticket.contact.title' className='mx-4 p-1 border border-grey' component='select'>
                            <option value='先生'>先生</option>
                            <option value='小姐'>小姐</option>
                            <option value='女士'>女士</option>
                            <option value='未指定'>未指定</option>
                          </Field>
                          <div className='flex flex-row items-center'>客戶電話</div>
                          <Field name='ticket.contact.phone' type='tel' className='border border-grey mx-4 p-2' />
                        </div>
                      </div>
                    : ''}
                </div>

                <div className='pl-8 mt-8 hidden sm:block'>
                  <ul className='list-reset text-lg mt-4'>
                    <li className='mb-2 text-xl text-grey-darker'>聯絡資訊</li>
                  </ul>
                  <div className='text-lg mb-4 mt-4' style={contactFormStyle}>
                    <div className='flex flex-row items-center'>客戶姓名</div>
                    <Field name='ticket.contact.name' className='border border-grey mx-4 p-2' />
                    <div className='flex flex-row items-center'>客戶稱謂</div>
                    <Field name='ticket.contact.title' className='mx-4 p-1 border border-grey' component='select'>
                      <option value='先生'>先生</option>
                      <option value='小姐'>小姐</option>
                      <option value='女士'>女士</option>
                      <option value='未指定'>未指定</option>
                    </Field>
                    <div className='flex flex-row items-center'>客戶電話</div>
                    <Field name='ticket.contact.phone' type='tel' className='border border-grey mx-4 p-2' />
                  </div>
                </div>
              </Form>
            )
          }}
        />
      </div>
    )
  }
}
