import React, { Component } from 'react'

export default class C extends Component {
  
  render () {
    return (
      <div className={`flex justify-center items-center border-r-2 border-grey-darker${this.props.sep === true ? ' timeline-sep' : ''}`}>
        <div className='text-sm text-grey-dark'>
          <div>{this.props.text}</div>
          <div className='text-right'>{this.props.subText}</div>
        </div>
      </div>
    )
  }
}
