import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import Toolbar from '../components/Toolbar'
import TicketDetail from '../components/TicketDetail'
import TicketDetailForm from '../components/TicketDetailForm'
import TicketTimelineColumn from '../components/TicketTimelineColumn'
import TicketTimelineEmptyColumn from '../components/TicketTimelineEmptyColumn'
import CompleteTicketModal from '../components/CompleteTicketModal'
import NotifyOwnerModal from '../components/NotifyOwnerModal'
import Loading from '../components/Loading'

import moment from 'moment'
import 'moment/locale/zh-tw'

import ticketHelper from '../ticketHelper'

import { get } from 'lodash'

import API from '../api'
moment.locale('zh-tw')

class TicketDetailPage extends Component {
  constructor (props) {
    super(props)

    let u = window.localStorage.getItem('loggedIn')
    if (u) u = JSON.parse(u)
    this.state = {
      events: [],
      showClosePopup: false,
      showNotifyPopup: false,
      editing: !!this.props.edit,
      canCloseTicket: false,
      ticketStarted: false,
      user: u,
      loading: true,
      ticket: null,
      activeTab: 0,
      notificationShown: false,
      istraced:false,
    }
  }

  async loadTicket (ticketID) {
    this.handleCancelEdit()
    console.log('loading ticket id', ticketID)

    try {
      let ticket = await API.getTicket(this.state.user, ticketID)
      console.log('1. ticket is =>', ticket);
      if(ticket.status === 'started'){
        this.setState({istraced: true});
      }
      this.setState({ ticket: ticket, loading: false })

    } catch(e){
      if (e.response.status === 401) {
        window.localStorage.clear();
        this.setState({user: null})
      }
    }
  }

  // 第一次進入頁面時，觸發 component did mount 讀取 ticket
  componentDidMount () {
    if (this.state.user) this.loadTicket(this.props.match.params.id)
  }

  // 在頁面內重讀時觸發 component will receive props
  componentWillReceiveProps (nextProps) {
    if (!this.state.loading) {
      this.setState({ loading: true })
      this.loadTicket(nextProps.match.params.id)
    }
  }

  handleStart () {
    this.setState({ events: [123] })
  }

  handleClose () {
    this.setState({ showClosePopup: true })
  }

  handleClosePopup () {
    this.setState({ showClosePopup: false })
  }

  handleNotifyPickUp () {
    this.setState({ showNotifyPopup: true })
  }

  handleNotifyPopupClose () {
    this.setState({ showNotifyPopup: false })
  }

  handleStartEdit () {
    this.setState({ editing: true })
  }

  handleCancelEdit () {
    this.setState({ editing: false })
  }

  handleUpdated () {
    this.setState({ editing: false, loading: true })
    this.loadTicket(this.props.match.params.id)
  }

  async closeTicket (ticketID) {
    let updateTicket = { 
      status: 'closed' 
    }

    if(!this.state.ticket.paid){
      updateTicket.paid = moment().format()
    }

    let ret = await API.updateTicket(this.state.user, this.state.ticket._id, updateTicket)
    console.log(ret)

    await this.loadTicket(ticketID)
    this.setState({ editing: false, loading: false })
  }

  handleSetStatusClosed () {
    this.handleClosePopup()
    this.setState({ loading: true })

    this.closeTicket(this.props.match.params.id)
  }

  async notifyPickUp (ticketID) {
    let updateTicket = {
      notify_car_owner: true
    }
    let ret = await API.updateTicket(this.state.user, this.state.ticket._id, updateTicket)

    await this.loadTicket(ticketID)
    this.setState({ editing: false, loading: false })
  }

  async updateLineNotify(linenotification){
    console.log('linenotification is =>', linenotification);
    let ret = await API.updateTicket(this.state.user, this.state.ticket._id, linenotification);
    console.log('ret is =>', ret);
  }

  handleNotifyPopupOk () {
    this.handleNotifyPopupClose()
    // this.setState({ loading: true })

    this.notifyPickUp(this.props.match.params.id)
  }

  handleReloadTicket () {
    this.loadTicket(this.props.match.params.id)
  }

  setActiveTab (page) {
    return () => {
      this.setState({ activeTab: page })
    }
  }

  onNotificationToggle (show) {
    this.setState({ notificationShown: show })
  }

  render () {
    if (!this.state.user) {
      return (<Redirect to={{ pathname: '/' }} />)
    }

    let screenPadding = '49px'

    if (this.state.loading) {
      return (
        <div>
          <Toolbar />
          <div className='h-screen' style={{ marginTop: screenPadding }}>
            <div className='min-h-screen flex items-center justify-center'>
              <Loading type='bubbles' color='#3E47F9' />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div>
        <CompleteTicketModal
          open={this.state.showClosePopup}
          handleCancel={this.handleClosePopup.bind(this)}
          handleOk={this.handleSetStatusClosed.bind(this)}
          comment={get(this.state.ticket, 'checklists[0].comment', '')}
          paid={get(this.state.ticket, 'paid', null)} />
        <NotifyOwnerModal
          open={this.state.showNotifyPopup}
          handleCancel={this.handleNotifyPopupClose.bind(this)}
          handleOk={this.handleNotifyPopupOk.bind(this)} />
        <Toolbar onToggle={this.onNotificationToggle.bind(this)} />
        <div className='' style={{ marginTop: screenPadding }}>
          <div className='borderbox flex flex-row w-full block sm:hidden h-16 text-xl' >
            <div className={`w-1/2 flex items-center justify-center h-full ${this.state.activeTab === 0 ? 'bg-white' : 'inactive-tab-left bg-cruisys-grey'}`} onClick={this.setActiveTab(0).bind(this)}>
              <span>服務單資訊</span>
            </div>
            <div className={`w-1/2 flex items-center justify-center h-full ${this.state.activeTab === 1 ? '' : 'inactive-tab-right bg-cruisys-grey'}`} onClick={this.setActiveTab(1).bind(this)}>
              <span>車輛動態</span>
            </div>
          </div>
          <div className={`w-full sm:w-1/2 shadow-right min-h-screen ${this.state.activeTab === 0 ? '' : 'hidden'}`}>
            {this.state.editing
              ? <TicketDetailForm cancelHandler={this.handleCancelEdit.bind(this)} data={this.state.ticket} onUpdated={this.handleUpdated.bind(this)} istraced={this.state.istraced}/>
              : <TicketDetail completeHandler={this.handleClose.bind(this)} editHandler={this.handleStartEdit.bind(this)} data={this.state.ticket} />}
          </div>
          <div className={`min-h-screen sidebar w-full sm:w-1/2 px-6 mt-3 ${this.state.activeTab === 1 ? '' : 'hidden sm:block'}`} id='timelineContainer'>
            {this.state.ticket.status !== 'created' && this.state.ticket.status !== 'init'
              ? <TicketTimelineColumn
                data={ticketHelper.ticketEventsToTimelineData(this.state.ticket.events)}
                ticket={this.state.ticket}
                showStatusButton={!this.state.editing && this.state.ticket.status !== 'closed'}
                disableNotifyButton={this.state.ticket.notify_car_owner}
                ticketID={this.state.ticket._id}
                onCloseTicket={this.handleClose.bind(this)}
                onNotifyTicket={this.handleNotifyPickUp.bind(this)}
                onReloadRequired={this.handleReloadTicket.bind(this)} 
                updateLineNotify={this.updateLineNotify.bind(this)}/>
              : <TicketTimelineEmptyColumn
                  data={ticketHelper.ticketEventsToTimelineData(this.state.ticket.events)}
                  handleStart={this.handleStart.bind(this)}
                  ticket={this.state.ticket}
                  showStatusButton={!this.state.editing}
                  ticketID={this.state.ticket._id}
                  onReloadRequired={this.handleReloadTicket.bind(this)}
                  updateLineNotify={this.updateLineNotify.bind(this)}/>}
          </div>
        </div>
      </div>
    )
  }
}

export default TicketDetailPage
