import React, { Component } from 'react'

import axios from 'axios'

export default class TicketCardImage extends Component {
  constructor () {
    super()
    if (!window.TICKET_CARD_IMAGE_CACHE) {
      window.TICKET_CARD_IMAGE_CACHE = {}
    }

    this.state = {
      loading: true
    }
  }

  async checkImage () {
    try {
      if (window.TICKET_CARD_IMAGE_CACHE[this.props.src]) {
        return this.setState({ loading: false, src: this.props.src })
      }

      await axios.get(this.props.src)
      window.TICKET_CARD_IMAGE_CACHE[this.props.src] = true
      this.setState({ loading: false, src: this.props.src, defualt: false })
    } catch (e) {
      this.setState({ loading: false, src: this.props.default, defualt: true })
    }
  }

  componentDidMount () {
    this.checkImage()
  }

  render () {
    if (this.state.loading) {
      return <div
        style={this.props.style}
        className={this.props.className}
      />
    }

    if(this.state.defualt){
      return (
        <div
          style={{ ...this.props.style, backgroundImage: `url(${this.state.src})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}
          className={this.props.className}
        />
      )
    }
    else{
      return (
        <div
          style={{ ...this.props.style, backgroundImage: `url(${this.state.src})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}
          className={this.props.className}
        />
      )        
    }    
  }
}
