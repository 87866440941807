import React, { Component } from 'react'

import moment from 'moment'
import 'moment/locale/zh-tw'

import { Link } from 'react-router-dom'

import API from '../api'

moment.locale('zh-tw')

export default class NotificationPopupBox extends Component {
  constructor (props) {
    super(props)
    let u = window.localStorage.getItem('loggedIn')
    if (u) u = JSON.parse(u)
    this.state = {
      user: u
    }
  }

  async setRead () {
    let ret = await API.markNotificationRead(this.state.user, this.props.data._id)
    console.log(ret)
  }

  handleClickNotification () {
    this.setRead()
  }

  render () {
    let t = this.props.data.type
      if (this.props.data.event_code === 'import_file') {
          return (
                  <div className='flex border border-grey p-2'>
                      <div className='float-left ml-2 mt-1' style={{ height: '38px', width: '28px' }}>
                          {t === 'notification'
                              ? <span className='text-black icon-ic_ticket text-xl' />
                              : <span className='text-red icon-ic_error text-xl' />}
                      </div>
                      <div className='float-left w-3/4'>
                          <h4 className={`m-0 break-words ${t === 'notification' ? 'text-black' : 'text-red'}`}>{this.props.data.description}</h4>
                          <p> </p>
                          <span className='text-sm text-grey-darker'>{moment(this.props.data.created_time).format('YYYY-MM-DD HH:mm')}</span>
                      </div>
                  </div>
          )
      } else{
          return (
              <Link to={`/tickets/${this.props.data.service_ticket_id}`} onClick={this.handleClickNotification.bind(this)}>
                  <div className='flex border border-grey p-2'>
                      <div className='float-left ml-2 mt-1' style={{ height: '38px', width: '28px' }}>
                          {t === 'notification'
                              ? <span className='text-black icon-ic_ticket text-xl' />
                              : <span className='text-red icon-ic_error text-xl' />}
                      </div>
                      <div className='float-left w-3/4'>
                          <h4 className={`m-0 break-words ${t === 'notification' ? 'text-black' : 'text-red'}`}>{this.props.data.description}</h4>
                          <span className='text-sm text-grey-darker'>{moment(this.props.data.created_time).format('YYYY-MM-DD HH:mm')}</span>
                      </div>
                      <div className='float-right mt-3 ml-2'>
                          {this.props.data.read_time ? '' : <span className='text-cruisys-red text-3xl'>●</span>}
                      </div>
                  </div>
              </Link>
          )
      }
  }
}
