import React, { Component } from 'react'
import Slider from 'react-slick'
import axios from 'axios'

import ticketHelper from '../ticketHelper'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default class Slick extends Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      srcs: []
    }
  }

  async checkImage (plateNo) {
    try {
      let url = ticketHelper.carImageHead(plateNo)
      await axios.get(url)
      // assume rear image exists if head image exists
      this.setState({ loading: false, srcs: [url, ticketHelper.carImageRear(this.props.plateNo)] })
    } catch (e) {
      this.setState({ loading: false, srcs: [ticketHelper.carImageDefault()] })
    }
  }

  componentDidMount () {
    this.checkImage(this.props.plateNo)
  }

  componentWillReceiveProps (nextProps) {
    this.checkImage(nextProps.plateNo)
  }

  render () {
    var settings = {
      dots: true,
      arrows: false
    }
    if (this.state.loading) {
      return <div className='container absolute' style={{ right: '-50px', width: '180px' }} />
    }

    let images = this.state.srcs
    return (
      <div className='container absolute' style={{ width: '180px' }}>
        <Slider {...settings}>
          {images.map((img, i) => {
            return <div key={`${img}-${i}`}><img src={img} alt='' /></div>
          })}
        </Slider>
      </div>
    )
  }
}
