import React, { Component } from 'react'

function checkState(currentValue, maxValue){
  if(!currentValue || currentValue == 0){
    return 'none';    
  }

  if(currentValue >= maxValue){
    return 'danger';
  }

  if(currentValue < maxValue){
    return 'safe';
  }
}

export default class C extends Component {  
  render () {    
    let state = {
      recep: checkState(this.props.recep, this.props.recepMax),
      dispatch: checkState(this.props.dispatch, this.props.dispatchMax),
      repair: checkState(this.props.repair, this.props.repairMax),
      detail: checkState(this.props.detail, this.props.detailMax),    
      deliver: checkState(this.props.deliver, this.props.deliverMax),
      reserve: checkState(this.props.reserve, this.props.reserveMax),
    }
    console.log(state);

    return (
        <div className={"relative h-64"}>
          <img className="absolute top-0 left-0" src="progress/progress-bg.png" />
          <img className="absolute top-0 left-0" src={'progress/1F-recep-' + state.recep + '.png'} />
          <img className="absolute top-0 left-0" src={'progress/1F-dispatch-' + state.dispatch + '.png'} />
          <img className="absolute top-0 left-0" src={'progress/1F-repair-' + state.repair + '.png'} />
          <img className="absolute top-0 left-0" src={'progress/1F-detail-' + state.detail + '.png'} />
          <img className="absolute top-0 left-0" src={'progress/1F-deliver-' + state.deliver + '.png'} />
          <img className="absolute top-0 left-0" src={'progress/1F-reserve-' + state.reserve + '.png'} />
        </div>        
    )
  }
}
