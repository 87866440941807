import React, { Component } from 'react'

import Chart from 'react-c3js'
import 'c3/c3.css'

export default class C extends Component {
  render () {
    let donePercentage = Math.round(this.props.loading_occupied * 100 / this.props.loading_capacity)
    return (
      <div className={`bg-white overflow-hidden p-4 shadow border border-grey xl:relative ${this.props.className}`}>
        <h4 className='text-grey-darker font-normal text-xl mb-0'>今日產能</h4>

        {this.props.showChart
          ? <div className='xl:absolute xl:pin'>
              <div className='w-full xl:w-4/5 xl:mx-auto'>
                <Chart
                  color={{ pattern: ['#3E47F9'], threshold: { values: [100] } }}
                  data={{ columns: [[`快速維修區負載`, donePercentage]], type: 'gauge', labels: false }}
                  gauge={{ label: { format: (v, r) => `${this.props.loading_occupied}/${this.props.loading_capacity}` } }} />
              </div>
              <div className='flex flex-row justify-around items-center mt-4 text-base'>
                <div className='text-center'>
                  已完成車輛 <span className='ml-1 text-4xl font-medium'>{this.props.deliver}</span><span className='text-xl'> / {this.props.deliverGoal}</span>
                </div>
                <div className='text-center'>
                  離廠車輛 <span className='ml-1 text-4xl font-medium'>{this.props.left}</span>
                </div>
              </div>
            </div>
          : ''
        }
      </div>

    )
  }
}
