import React, { Component } from 'react'
import { Button, Modal, Form } from 'semantic-ui-react'

export default class NotifyOwnerModal extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  handleCancel () {
    this.props.handleCancel()
  }

  render () {
    return (<Modal open={this.props.open} closeOnDimmerClick size='mini'>
      <Modal.Header>提醒</Modal.Header>
      <Modal.Content>
        <Modal.Description>
        是否確認發送 LINE 訊息通知顧客準備取車和更新客休區螢幕取車資訊？
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='grey' onClick={this.handleCancel.bind(this)}>取消</Button>
        <Button color='blue' onClick={this.props.handleOk.bind(this)}>確認</Button>
      </Modal.Actions>
    </Modal>
    )
  }
}
