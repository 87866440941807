import React, { Component } from 'react'

export default class ImageWithDefaultHidden extends Component {
  constructor (props) {
    super(props)
    this.state = { render: true }
  }

  render () {
    if (!this.state.render) {
      return ''
    }

    return (
      <img
        alt={this.props.alt}
        src={this.props.src}
        className={this.props.className}
        onError={(e) => {
          e.target.onerror = null
          // e.target.src = this.props.default
          this.setState({ render: false })
        }}
        style={this.props.style}
      />
    )
  }
}
