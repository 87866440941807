import React, { Component } from 'react'

export default class LoadingOverlay extends Component {
  render () {
    return (
      <div className={`${this.props.show? "flex ": "hidden "} items-center fixed w-full h-full z-10 bg-real-black opacity-85`} style={this.props.style}>
        <img className="block mx-auto" alt='loading_car' src="/loading.gif" />
      </div>
    )
  }
}