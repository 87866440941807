import React, { Component } from 'react'

import Timeline from '../components/Timeline'
import '../assets/scss/page/ticket.scss';

export default class TicketTimelineColumn extends Component {
  constructor (props) {
    super(props)
    let u = window.localStorage.getItem('loggedIn')
    if (u) u = JSON.parse(u)

    this.state = {
      updating: false,
      user: u,
      line_status: false,
      islineOpen : true,
      ticket_status : null
    }
  }
  componentDidMount(){
    // Define if ticket is init,created, or closed. and define if line notification is open.
    this.setState({
      ticket_status: this.props.ticket.status,
      islineOpen : this.props.ticket.line_notification
    });
    this.props.ticket.car_orig.contacts.forEach(person => {
      if(person.hasOwnProperty('line_id')){
        this.setState({line_status: true});
      }
    });
  }
  switchNotify(){
    // If ticket status is "closed", user can't set notification.
    if(this.state.ticket_status === 'closed' || !this.state.line_status){
      return;
    }
    this.setState({islineOpen : !this.state.islineOpen}, () => {
      this.props.updateLineNotify({line_notification: this.state.islineOpen});
    });
  }
  render () {
    return (
      <div>
        <div className='flex flex-row justify-center items-center'>
          {this.props.showStatusButton
            // close case button
            ? <button
              className='w-full bg-cruisys-blue text-white rounded-lg py-4 px-2 my-2 mx-2 flex flex-row items-center justify-center'
              onClick={this.props.onCloseTicket}
              disabled={this.state.updating}>
                <span className='icon-ic_offline_pin block mr-2 text-2l' style={{ lineHeight: '28px' }} />
                <span className='block h-8 text-xl font-black' style={{ lineHeight: '28px' }}>完成服務單</span>
              </button>
            : ''}
          {this.props.showStatusButton
            // notify button
            ? <button
              className={`w-full bg-cruisys-green text-white rounded-lg py-4 px-2 my-2 mx-2 flex flex-row items-center justify-center ${this.props.disableNotifyButton ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={this.props.disableNotifyButton? false : this.props.onNotifyTicket}>
                <span className='icon-bullhorn block mr-2 text-xl smallIcon' style={{ lineHeight: '28px' }} />
                <span className='block h-8 text-xl font-black ' style={{ lineHeight: '28px' }}>{this.props.disableNotifyButton? '已提醒取車': '通知取車'}</span>
              </button>
            : ''
          }
        </div>
        <div className={`w-full flex bg-white items-center justify-center line-notify-setting 
          ${this.state.ticket_status === 'closed' || !this.state.line_status ? 'close': ''}`}>
          <span className='icon-info'></span>
          <p className='text'>{
            this.state.line_status 
              ? '客戶已綁定，發送Service Go通知？'
              : '客戶未綁定，Service Go通知已關閉'
            }
          </p>
          <div className="onoffswitch"> 
            <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" checked/> 
            <label onClick={this.switchNotify.bind(this)} className={`onoffswitch-label ${this.state.ticket_status === 'closed' || !this.state.line_status ? 'close': ''}`} for="myonoffswitch"> 
              <span className={`onoffswitch-inner 
                ${this.state.line_status 
                  ? this.state.islineOpen
                    ? 'on' 
                    : 'off'
                  : 'off'}`}>
              </span> 
              <span className={`onoffswitch-switch 
                ${this.state.line_status 
                  ? this.state.islineOpen
                    ? 'on'
                    : 'off'
                  : 'off'}`}>
              </span> 
            </label> 
          </div>
        </div>
        <h3 className='my-2 mt-8'>工作軌跡</h3>
        <Timeline data={this.props.data} />
      </div>
    )
  }
}
