import React, { Component } from 'react'
import { Button, Icon } from 'semantic-ui-react'

export default class FilterButton extends Component {
  constructor(props){
    super(props)
    this.state = {
      isSmallScreen : false
    }
  }
  componentDidMount(){
    if(window.screen.width <= 375){
      this.setState({isSmallScreen : true});
    }
    window.onresize = () => {
      if(window.screen.width <= 375){
        this.setState({isSmallScreen : true});
      } else {
        this.setState({isSmallScreen : false});
      }
    };
  }
  render () {
    return (
      <Button
        className='filter-button'
        basic={!this.props.active}
        icon={!!this.props.icon}
        labelPosition={this.props.icon ? 'right' : undefined}
        style={{
          fontSize: '16px',
          margin: this.state.isSmallScreen ? '4px': '6px',
          marginBottom: '10px',
          backgroundColor: this.props.active ? '#3E47F9' : 'white',
          paddingLeft: this.state.isSmallScreen ? '5px': '16px',
          paddingRight: this.state.isSmallScreen ? '5px': '16px',
          color: this.props.active ? 'white' : 'grey',
          ...this.props.style }}
        onClick={this.props.onClick}>
        {this.props.icon ? <Icon name={this.props.icon} /> : ''}
        {this.props.name}
        {this.props.active ? <span className="inline-block bg-white text-black text-xs px-2 rounded-full ml-2">{this.props.resultCount}</span> : ''}
      </Button>
    )
  }
}
