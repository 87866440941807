import React from 'react'
import ReactDOM from 'react-dom'
import './tailwind.css'
import './icon.css'
import 'semantic-ui-css/semantic.min.css'
import './index.css'
import axios from 'axios'

import App from './App'
// import * as serviceWorker from './serviceWorker'

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()

let vh = window.innerHeight * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`)

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})

async function init () {
  try {
    let config = await axios.get('/config_1.json')

    window.cruisys_config = config.data
  } catch (e) {
    window.alert('啟動失敗：無法載入設定檔')
  }

  ReactDOM.render(<App />, document.getElementById('root'))
}

init();
