import React, { Component } from 'react'
import { Container, Button, Icon } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'

import Toolbar from '../components/Toolbar'
import NotificationBox from '../components/notificationBox'

import API from '../api'

export default class Notification extends Component {
  constructor (props) {
    super(props)

    let u = window.localStorage.getItem('loggedIn')
    if (u) u = JSON.parse(u)
    this.state = {
      user: u,
      notifications: []
    }
  }

  async getNotifications () {
    try{
      let ret = await API.getNotificationsNoLimit(this.state.user)
      this.setState({ notifications: ret })
    }catch(e){
      if (e.response.status === 401) {
        window.localStorage.clear();
        this.setState({user: null})
      }
    }
  }

  componentDidMount () {
    if (this.state.user) this.getNotifications()
  }

  async markAllAsRead () {
    await API.markAllNotificationsRead(this.state.user)

    this.getNotifications()
  }

  handleMarkAllAsRead () {
    this.markAllAsRead()
  }

  refreshPage () {
    this.getNotifications()
  }

  render () {
    if (!this.state.user) {
      return (<Redirect to={{ pathname: '/',state:{refreshLogin:true} }} />)
    }

    return (
      <div>
        <Toolbar onAttemptToRefresh={this.refreshPage.bind(this)} />
        <Container className='mt-20' >
          <div className='max-w-md mx-auto'>
            <Button floated='right' color='black' icon labelPosition='right' onClick={this.handleMarkAllAsRead.bind(this)} style={{ backgroundColor: '#182045' }} ><Icon name='close' />全部設為已讀</Button>
            <h3>通知</h3>
            <div className='mt-12'>
              {this.state.notifications.map(n => <NotificationBox data={n} key={n._id} />)}
            </div>
          </div>
        </Container>
      </div>
    )
  }
}
