import React from 'react'
import { Redirect } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik'

import API from '../api'

const LoginForm = () => {
  return (
    <div>
      <Formik
        initialValues={{ username: '', password: '' }}
        onSubmit={async (values, actions) => {
          // console.log(values, actions)

          try {
            let user = await API.login(values.username, values.password)
            // console.log(JSON.stringify(user))
            window.localStorage.setItem('loggedIn', JSON.stringify(user))
            actions.setStatus({ loggedIn: true })
          } catch (e) {
            console.error(e)
            actions.setErrors({ username: '登入失敗，請檢查帳號密碼' })
            actions.setSubmitting(false)
          }
        }}
        render={({ errors, status, touched, isSubmitting }) => {
          // console.log('render', errors, status, isSubmitting)
          if (status && status.loggedIn) {
            return (<Redirect to={{ pathname: '/stats' }} />)
          }
          return (<Form className='flex flex-col items-center'>
            <ErrorMessage name='username' component='div' className='p-4 mb-4 w-full text-white bg-cruisys-red rounded text-2xl font-bold border border-grey-dark' />
            <Field type='text' autoCapitalize='none' name='username' className='p-4 pl-8 mb-4 w-full' placeholder='帳號' style={{ fontSize: '20px' }} />
            <ErrorMessage name='password' component='div' />
            <Field type='password' name='password' className='p-4 pl-8 mb-4 w-full' placeholder='密碼' style={{ fontSize: '20px' }} />
            <button type='submit' className='w-full rounded bg-cruisys-blue text-white font-black' style={{ fontSize: '20px', height: '51px' }} disabled={isSubmitting}>{isSubmitting ? '登入中...' : '登入'}</button>
          </Form>)
        }}
      />
    </div>
  )
}

export default LoginForm
