import React, { Component } from 'react'
import ticketHelper from '../ticketHelper'
import { get } from 'lodash'
import { Link } from 'react-router-dom'

import avatarUrl from '../avatarUrl'

const footerStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr 1fr'
}

export default class C extends Component {
  constructor(props){
    super(props)
    this.createNewTicket = this.createNewTicket.bind(this);
  }
  createNewTicket(isTicketCreated, comingEvent, plate_no){
    if(isTicketCreated){
      this.props.history.push(`tickets/${comingEvent.service_ticket._id}`);
    } else {
      this.props.history.push({
        pathname: 'tickets/new', 
        state:{plate_no: plate_no , nonbooked_id: comingEvent._id, created_time: comingEvent.time}
      });
    }
  }
  render () {
    let comingEvent = this.props.comingEvent;
    // Is this car have service ticket ?
    let isTicketCreated = comingEvent.service_ticket !== undefined;
    let plate_no = isTicketCreated 
      ? comingEvent.service_ticket.car.plate_no 
      : comingEvent.plate_no;  
    let recep = comingEvent.recep_space;
    let isIn = comingEvent.direction === "in";
    // if there is "plate_no", then it represent this car is comming self 
    let iscomingSelf = comingEvent.plate_no !== undefined; 
    console.log('comingEvent is =>', comingEvent);
    return (
      <div onClick={this.createNewTicket.bind(this, isTicketCreated, comingEvent, plate_no)} 
        className={`rounded-lg border-2 border-grey-lighter items-center overflow-hidden p-1 mb-2 sm:p-2 sm:mb-3 lg:mb-2
          ${(!isIn || isTicketCreated)
          ? "bg-grey-lighter"
          : "bg-white"}`}>
        <div className={'flex items-center flex-row justify-between'}>
          <div className='flex justify-start w-1/2 sm:w-2/5 pt-2 pb-2'>
            <div className='flex flex-row items-center'>
              <div className='text-lg text-black flex flex-row items-center'>
                <span className={`text-lg font-bold ${ !isIn ? "text-grey-darkest icon-exit" : "text-cruisys-blue-dark icon-enter"}`} />
                <div className={`pl-1 text-xl font-bold ${ !isIn ? "text-grey-darkest" : "text-cruisys-blue-dark"}`}>{plate_no}</div>
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-between w-1/2 sm:w-3/5'>
              <div className={`flex text-base font-bold sm:pl-2 ${ !isIn ? "text-grey-darkest" : "text-cruisys-blue-dark"}`}>
                {!isIn
                  ? "車輛偵測離廠"
                  : iscomingSelf
                    ? `自來車指定${recep}`
                    : comingEvent.service_ticket.booked 
                      ? `預約車指定${recep}`
                      : `自來車指定${recep}`}
              </div>
              <button className={`flex text-base font-bold rounded-lg py-2 px-1 sm:px-2 whitespace-no-wrap self-center
                ${!isIn
                  ? "bg-white text-grey-darkest"
                  : isTicketCreated
                    ? "bg-cruisys-blue-dark text-white"
                    : "bg-grey-lighter text-cruisys-blue-dark"}`}>
                {isTicketCreated ? '查看' : '建立'}
              </button>
          </div>            
        </div> 
      </div>
    )
  }
}
