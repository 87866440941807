import React, { Component } from 'react'

export default class ImageWithDefault extends Component {
  render () {
    return (
      <img
        alt={this.props.alt}
        src={this.props.src}
        className={this.props.className}
        style={this.props.style}
        onError={(e) => { 
          e.target.onerror = null;
          e.target.src = this.props.default;
        }}
      />
    )
  }
}
