import React, { Component } from 'react'

import axios from 'axios'

export default class TicketDetailLocation extends Component {
  constructor () {
    super()
    this.state = {
      loading: true,
      hide: false
    }
  }

  async checkImage (src) {
    try {
      await axios.get(src)
      this.setState({ loading: false, src, hide: false })
    } catch (e) {
      this.setState({ loading: false, hide: true })
    }
  }

  componentDidMount () {
    this.checkImage(this.props.src)
  }

  componentWillReceiveProps (nextProps) {
    if(nextProps.src.includes("null.png")){
      this.setState({ loading: false, hide: true })
      return
    }
    this.checkImage(nextProps.src)
  }

  render () {
    if (this.state.loading) {
      return ''
    }

    if (this.state.hide) {
      return ''
    }

    return (
      <div className='px-8 mt-6 pb-6'>
        <div className='flex flex-row mt-2'> 位於 {this.props.loc}
        </div>
        <div className='border border-grey mt-4'>
          <img src={this.state.src} alt='' className='w-full h-full' />
        </div>
      </div>
    )
  }
}
