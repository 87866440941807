import React, { Component } from 'react'
import { Icon, Pagination } from 'semantic-ui-react'

import API from '../api'

import { Link, Redirect } from 'react-router-dom'

import Toolbar from '../components/Toolbar'
import TicketCards from '../components/TicketCards'
import TicketTable from '../components/TicketTable'
import AdvancedSearch from '../components/AdvancedSearch'
import MiniTicketCard from '../components/MiniTicketCard'

import SubToolbarButton from '../components/SubToolbarButton'
import SubToolbarButtonWithText from '../components/SubToolbarButtonWithText'
import FilterButton from '../components/FilterButton'

import moment from 'moment'
import 'moment/locale/zh-tw'

import Loading from '../components/Loading'
import '../assets/css/components/loadingBar.css';
import NameSearch from '../components/me/NameSearch'
import { ImportModal, UploadNotification} from '../components/me/ImportModal'
const queryString = require('query-string');

moment.locale('zh-tw')

var subtoolbarStyle = {
  fontSize: '1.25rem'
}

class MePage extends Component {
  constructor (props) {
    super(props)
    let u = window.localStorage.getItem('loggedIn')
    if (u) u = JSON.parse(u)
    this.state = {
      view: 'card',
      showAdvancedSearch: false,
      loading: true,
      loadingNewComing: true,
      user: u,
      tickets: [],
      searchRevision: 0,
      newComing: [],
      pagination: {
        totalPage: null,
        currentPage: null
      },
      query: u ? defaultQuery(u) : null,
      queryType: defaultQueryType(u),
      queryResultCount: 0,
      activeTab: 0,
      notificationShown: false,
      csvDownloading: false,

      //import related field
      showImportModal: false,
      importFailed: null,
      modalDescription: null,
      showUploadNotification : false,
      uploadNotification: null,
    }    
  }

  async loadNewComing () {
    // TODO: 因為測試站重設（透過 api/fixture/index.js）會砍掉資料，有時候 newComing 回傳的服務單其實已經被砍掉了
    // 這邊先暫時忽略
    let newComing = []
    try {
      newComing = await API.getNewComing(this.state.user)
      console.log('newComing', newComing)
    } catch (e) {
      console.error(e)
      if (e.response.status === 401) {
        window.localStorage.clear();
        this.setState({user: null})
      }
    } finally {
      this.setState({
        newComing,
        loadingNewComing: false
      })
    }
  }

  async loadTickets () {
    try {
      let ret = await API.searchTicket(this.state.user, this.state.query)
      console.log('ret is =>', ret);
      this.setState({
        loading: false,
        tickets: ret.data,
        queryResultCount: ret.count > 999 ? '999+' : ret.count,
        pagination: {
          totalPage: ret.total_page,
          currentPage: this.state.query.page || 1
        } })
    } catch (e) {
      console.error(e)
      if (e.response.status === 401) {
        window.localStorage.clear();
        this.setState({user: null})
      }
    }
  }

  componentDidMount () {
    if (this.state.user) {
      const qs = queryString.parse(this.props.location.search)
      if(qs && qs.qtype){        
        this.state.query = {
          progress : qs.qtype,
        }

        // qtype == deliver 做特別處理
        if(qs.qtype === 'deliver'){          
          this.state.query.deliver = true
          delete this.state.query.progress
        }

        this.state.loading = true
        this.state.queryType = 'custom'
        this.state.queryResultCount = 0
      }      

      this.loadTickets()
      this.loadNewComing()
      this.timer = setInterval(() => {
        this.loadNewComing()
      }, 3 * 60 * 1000)
    }
  }

  componentWillUnmount () {
    clearInterval(this.timer)
  }

  componentWillReceiveProps () {
    if (this.state.user) {
      this.loadTickets()
      this.loadNewComing()
    }
  }

  removeCustomSearch () {
    this.setState({
      loading: true,
      query: defaultQuery(this.state.user),
      queryType: defaultQueryType(this.state.user),
      searchRevision: this.state.searchRevision + 1,
      showAdvancedSearch: false
    }, () => {
      this.loadTickets()
    })
  }

  toggleView () {
    if (this.state.view === 'card') {
      this.setState({ view: 'list' })
    } else {
      this.setState({ view: 'card' })
    }
  }

  handleClickCancelAdvancedSearch () {
    this.setState({ showAdvancedSearch: false })
  }

  handleClickShowAdvancedSearch () {
    if (this.state.showAdvancedSearch) {
      this.setState({ showAdvancedSearch: false })
    } else {
      this.setState({ showAdvancedSearch: true })
    }
  }

  handlePaginationChange (e, { activePage }) {
    this.setState({
      loading: true,
      query: { ...this.state.query, page: activePage },
      pagination: {
        currentPage: activePage
      }
    }, () => {
      this.loadTickets()
    })
  }

  handleQuery (q) {
    this.setState({ query: q, loading: true, queryType: 'custom', queryResultCount: 0 }, () => {
      this.loadTickets()
    })
  }

  handleSetQueryType (queryType) {
    return () => {
      let query = queryToday(this.state.user)
      switch (queryType) {
        case 'today':
          query = queryToday(this.state.user)
          break
        case 'staying':
          query = queryStaying(this.state.user)
          break
        case 'personal':
          query = queryPersonal(this.state.user)
          break
        default:
          throw new Error(`unknown query type: ${queryType}`)
      }
      this.setState({ query, loading: true, queryType: queryType, queryResultCount: 0 }, () => {
        this.loadTickets()
      })
    }
  }

  // 應該要把 notification 的邏輯抽出來實作在 middle ware，
  // 但因這修改要花太多時間，故先苟且的做
  // [2020.09.18] => 修改判斷邏輯與 notification 提出
  findWhichDescription(message, res, from, params) {
    let list = {
      notification: null,
      reminder: null,
      importFailed: false
    }
    let fileName = this.state.importedFile ? this.state.importedFile.name : '';
    let modalDescription = {
      'ok': function(res, from, params) {
        if(from === 'Leadtime') {
            list.notification = `成功更新動態管制表 ${res.data.update_count} 筆`
        } else if(from === 'Reservation') {
          if (params.dateDiff === 0){
            list.notification =  `成功匯入今日 ${fileName} 預約表 ${res.data.imported_count} 筆，取代先前匯入 ${res.data.delete_count} 筆。`
            list.reminder = `提醒！匯入今日預約表將不會發送LINE通知。`
          } else if(params.dateDiff === 1){
            list.notification = `成功匯入明日 ${fileName} 預約表 ${res.data.imported_count} 筆，取代先前匯入 ${res.data.delete_count} 筆。`
            list.reminder = `提醒！晚間八點前匯入的預約表會在晚間八點發送LINE的「預約提醒」給車主，若在晚間八點後才匯入預約表，將不會發通知打擾車主作息。`
          } else if(params.dateDiff > 1){
            list.notification = `成功匯入 ${fileName} 預約表 ${res.data.imported_count} 筆，取代先前匯入 ${res.data.delete_count} 筆。`
            list.reminder = `提醒！預約日的前一天將會在晚間八點發送LINE的「預約提醒」給車主。`
          } else {
            list.notification = `請檢查檔名是否為有效日期`
            list.importFailed = true
          }
        } else {
          list.notification = `未知錯誤`
          list.importFailed = true
        }
      },
      '914': function(res, from, params) {
        const updatedDate = new moment(res.data)
        list.notification = `已於 ${updatedDate.format('YYYY-MM-DD HH:mm:ss')} 匯入 ${res.filename} ，請確認本次更新的動態管制表時間是否有效`
        list.importFailed = true
      },
      '915': function(res, from, params) {
        list.notification = `請檢查檔名與檔案內日期是否一致`
        list.importFailed = true
      },
      '916': function(res, from, params) {
        const now = new moment().startOf('day')
        list.notification = (
            <div>
              <p style={{marginBottom: '15px'}}>請檢查檔案名稱是否正確</p>
              <ul>
              <li style={{marginLeft: '-20px', marginBottom: '15px'}}>預約表請確認檔名格式 (ex: {now.format('YYYY-MM-DD')}.csv)</li>
              <li style={{marginLeft: '-20px'}}>動態管制表請確認檔名格式<br></br>(ex: 即時動態管制表{now.format('YYYYMMDD')}1230.csv)</li>
              </ul>
            </div>
          );
        list.importFailed = true
      },
      '917': function(res, from, params) {
        list.notification = '請確認檔案類型為.CSV'
        list.importFailed = true
      },
      '918': function(res, from, params) {
        const now = new moment().startOf('day')
        list.notification = `請檢查檔案內日期格式 (ex: ${now.format('YYYY/MM/DD')}) `
        list.importFailed = true
      },
      '919': function(res, from, params) {
        list.notification = `檔案內的時間與檔案名稱時間需相差30分鐘以內`
        list.importFailed = true
      },
      '920': function(res, from, params) {
        list.notification = `請檢查檔案內容格式是否正確`
        list.importFailed = true
      },
      '921': function(res, from, params) {
        list.notification = `非預期性錯誤`
        list.importFailed = true
      },
      '922': function(res, from, params) {
        list.notification = `請檢查檔名是否為有效日期`
        list.importFailed = true
      },
      'default': function(res, from, params) {
        list.notification = `未知的錯誤`
        list.importFailed = true
      }
    }
    modalDescription[message](res, from, params)
    return list
  }

  handleSelectFile (e) {
    let file = e.target.files[0]
    if (file){
      let importFailed = false
      let modalDescription = null
      // console.log("upload file, file type:", file.type)
      let fileName = file.name.split('.').slice(0, -1).join('.');

      // 前端暫且無法在window系統判斷檔案格式，故拔除
      if(this._isLeadtimeFile(fileName)){ // 是否符合格式 ‘即時動態管制表’ 規範
        let digitFromFilename = null
        let normalizedDate = null
        let dateFromFile = null

        try{
          digitFromFilename = fileName.split('.')[0].match(/\d+$/)[0]
          normalizedDate = digitFromFilename.slice(0, 8) + "T" + digitFromFilename.slice(8)
          dateFromFile = moment(normalizedDate)
        } catch(e){
          console.log(e)
        }

        const now = new moment().startOf('day')
        const dateDiff = dateFromFile.startOf('day').diff(now, 'days')

        if (!dateFromFile || !dateFromFile.isValid()){ // 日期格式是否有問題
          modalDescription = this.findWhichDescription('916').notification
          importFailed = this.findWhichDescription('916').importFailed
        } else {
          if (dateDiff !== 0){ // 日期格式是否符合今日
            modalDescription = this.findWhichDescription('916').notification
            importFailed = this.findWhichDescription('916').importFailed
          } else {
            modalDescription = `確定匯入 ${fileName} 嗎？`
          }
        }
      } else if(this._isCSVDateFile(fileName)) { // 使否符合預約表規範
        const dateFromFile = moment(fileName.split('.')[0])

        const now = new moment().startOf('day')
        const dateDiff = dateFromFile.diff(now,'days')
        if (!dateFromFile.isValid()){
          modalDescription = this.findWhichDescription('916').notification
          importFailed = this.findWhichDescription('916').importFailed
        } else {
          if (dateDiff < 0){ // 過去
            modalDescription = this.findWhichDescription('922').notification
            importFailed = this.findWhichDescription('922').importFailed
          } else if (dateDiff === 0){
            modalDescription = '確定匯入今日預約表嗎？' // 今天
          } else if (dateDiff === 1){
            modalDescription = `確定匯入明日 ${fileName} 預約表嗎？` // 明天
          } else if (dateDiff > 1){ // 未來
            modalDescription = (
              <div>
                <div className='mb-2 text-cruisys-blue-light'>
                  {`注意！匯入的預約表日期為 ${fileName.split('.')[0]}`}
                </div>
                {`確定匯入 ${fileName} 預約表嗎？`}
              </div>
            )
          }
        }
      } else { // 都不是
        modalDescription = this.findWhichDescription('916').notification
        importFailed = this.findWhichDescription('916').importFailed
      }
      
      this.setState({
        showImportModal: true,
        importedFile: file,
        importFailed,
        modalDescription
      })
    }
  }

  handleImportModalCancel (){
    this.setState({showImportModal: false})
  }

  async uploadFile () {
    this.setState({showImportModal: false})
    try {
      if(this._isLeadtimeFile(this.state.importedFile.name)){
        let res = await API.uploadCSV(this.state.user, this.state.importedFile, 'leadtime')
        this.handleLeadtimeResponse(res)
      }else{
        // reservation csv
        let res = await API.uploadCSV(this.state.user, this.state.importedFile, 'csvfile')
        this.handleReservationResponse(res)
      }
      this.loadTickets()
    }catch(e){
      console.error(e)
      if (e.response.status === 401) {
        window.localStorage.clear();
        this.setState({user: null})
      }else{
        this.setState({
          showUploadNotification: true,
          uploadNotification: '非預期的錯誤',
          importFailed: true
        })
      }
    }
  }

  handleLeadtimeResponse(res){
    this.setState({
      showUploadNotification: true,
      uploadNotification: this.findWhichDescription(res.message, res, 'Leadtime').notification,
      uploadReminder: this.findWhichDescription(res.message, res, 'Leadtime').reminder,
      importFailed: this.findWhichDescription(res.message, res, 'Leadtime').importFailed
    })
  }

  handleReservationResponse(res){
    let params

    if (this.state.importedFile.name){
      const dateFromFile = moment(this.state.importedFile.name.split('.')[0])
      const now = new moment().startOf('day')
      const dateDiff = dateFromFile.diff(now,'days')
      const fileDateArray = dateFromFile['_i'].split("-");
      params = {
        dateFromFile: dateFromFile,
        now: now,
        dateDiff: dateDiff,
        fileDateArray: fileDateArray
      }
    }

    this.setState({
      showUploadNotification: true,
      uploadNotification: this.findWhichDescription(res.message, res, 'Reservation', params).notification,
      uploadReminder: this.findWhichDescription(res.message, res, 'Reservation', params).reminder,
      importFailed: this.findWhichDescription(res.message, res, 'Reservation', params).importFailed,
    })
  }

  _isLeadtimeFile(filename){
    const regex = /即時動態管制表/
    return filename.match(regex)
  }

  _isCSVDateFile(filename){
    function isValidDate(dateString) {
      var regEx = /^\d{4}-\d{2}-\d{2}$/;
      if(!dateString.match(regEx)) return false;
      var d = new Date(dateString);
      var dNum = d.getTime();
      if(!dNum && dNum !== 0) return false;
      return d.toISOString().slice(0,10) === dateString;
    }

    return isValidDate(filename)
  }

  closeUploadNotification () {
    this.setState({showUploadNotification: false})
  }

  async handleDownloadCSV(){
    if(this.state.queryResultCount !== '999+' && this.state.queryResultCount <= 0){
      return;
    } else {
      try{
        let downloadQuery={...this.state.query, download:true}
        this.setState({
          query:{downloadQuery},
        })
        await API.getTicketDataInSync(this.state.user, downloadQuery)
      } catch(e){
        console.log('download csv error', e)
        if (e.response.status === 401) {
          window.localStorage.clear();
          this.setState({user: null})
        }
      }
    }
  }

  async onClickDownload(){
    this.setState({csvDownloading: true});
    await this.handleDownloadCSV();
    this.setState({csvDownloading: false})
  }

  setActiveTab (page) {
    return () => {
      this.setState({ activeTab: page })
    }
  }

  onNotificationToggle (show) {
    this.setState({ notificationShown: show })
  }

  render () {
    if (!this.state.user) {
      return (<Redirect to={{ pathname: '/' }} />)
    }

    let screenPadding = '49px'

    return (
      <div>
        <ImportModal
          open = {this.state.showImportModal}
          handleOk = {this.uploadFile.bind(this)}
          handleCancel = {this.handleImportModalCancel.bind(this)}
          importFailed = {this.state.importFailed}
          description = {this.state.modalDescription}

        />
        <UploadNotification
          open = {this.state.showUploadNotification}
          handleOk = {this.closeUploadNotification.bind(this)}
          notification = {this.state.uploadNotification}
          reminder = {this.state.uploadReminder}
          importFailed = {this.state.importFailed}
          importedFilename = {this.state.importedFile && this.state.importedFile.name}
        />
        <Toolbar active='me' onToggle={this.onNotificationToggle.bind(this)} />
        <div className='' style={{ marginTop: screenPadding }}>
          <div className='borderbox flex flex-row w-full block sm:hidden h-16 text-xl' >
            <div className={`w-1/2 flex items-center justify-center h-full ${this.state.activeTab === 0 ? '' : 'inactive-tab-left bg-cruisys-grey'}`} onClick={this.setActiveTab(0).bind(this)}>
              <span>服務單</span>
            </div>
            <div className={`w-1/2 flex items-center justify-center h-full ${this.state.activeTab === 1 ? '' : 'inactive-tab-right bg-cruisys-grey'}`} onClick={this.setActiveTab(1).bind(this)}>
              <span>車輛動態</span>
            </div>
          </div>
          <div className={`min-h-screen pt-8 sm:px-6 mb-16 sm:w-3/4 ${this.state.activeTab === 0 ? '' : 'hidden'}`}>
            <div className='sm:hidden subtoolbar px-2'>
              <div>
                <Link to='/tickets/new'>
                  <SubToolbarButtonWithText icon='plus' name='新建' />
                </Link>
              </div>
              <div>
                <input type='file' name='file' id='file' accept='.csv' className='inputfile pcy' onChange={this.handleSelectFile.bind(this)} />
                <label htmlFor='file'>
                  <Icon name='download' />
                  <span className='ml-2'>匯入</span>
                </label>
              </div>
              <SubToolbarButtonWithText onClick={this.handleClickShowAdvancedSearch.bind(this)} name='進階搜尋' />
              {/* <div>
                <SubToolbarButtonWithText icon='list ul' onClick={this.toggleView.bind(this)} name='條列' />
              </div> */}
              <NameSearch
                className='w-full'
                key={this.state.searchRevision}
                style={{ gridColumn: '1 / 4', fontSize: '1.25rem' }}
                onQuery={this.handleQuery.bind(this)} />
            </div>

            <div className='hidden sm:flex justify-between' style={subtoolbarStyle}>
              <div className='pl-1'>
                <Link to='/tickets/new'><SubToolbarButton icon='plus' style={subtoolbarStyle} /></Link>
              </div>
              <div>
                <input type='file' name='file' id='file' accept='.csv' className='inputfile' onChange={this.handleSelectFile.bind(this)} />
                <label htmlFor='file'><Icon name='download' /></label>
              </div>
              <div>
                <SubToolbarButton icon='list ul' style={subtoolbarStyle} onClick={this.toggleView.bind(this)} />
              </div>
              <NameSearch
                className='w-4/5'
                key={this.state.searchRevision}
                onQuery={this.handleQuery.bind(this)} />
            </div>
            <div className='text-right underline mt-1 cursor-pointer text-lg text-cruisys-blue-dark hidden sm:block' style={{ height: '19px' }}>
              <span onClick={this.handleClickShowAdvancedSearch.bind(this)}>{this.state.showAdvancedSearch ? '' : '進階搜尋'}</span>
            </div>

            {this.state.showAdvancedSearch
              ? <AdvancedSearch
                handleCancel={this.handleClickCancelAdvancedSearch.bind(this)}
                onQuery={this.handleQuery.bind(this)}
              />
              : ''}

            <div className='w-full flex flex-row justify-between sm:justify-start items-center mt-4 mb-2 flex-wrap'>
              <FilterButton
                active={this.state.queryType === 'today'}
                onClick={this.handleSetQueryType('today').bind(this)}
                name='今日蒞臨' 
                resultCount={this.state.queryResultCount} />
              <FilterButton
                active={this.state.queryType === 'staying'}
                onClick={this.handleSetQueryType('staying').bind(this)}
                name='留廠車輛'
                resultCount={this.state.queryResultCount} />
              <FilterButton
                active={this.state.queryType === 'personal'}
                onClick={this.handleSetQueryType('personal').bind(this)}
                name='個人任務'
                resultCount={this.state.queryResultCount} />
              {this.state.queryType === 'custom'
                ? <FilterButton
                  style={{ width: 'auto' }}
                  active
                  onClick={this.removeCustomSearch.bind(this)}
                  icon='close'
                  name={`共 ${this.state.queryResultCount} 筆`} />
                : ''}
              <div className={`flex-grow text-right self-auto text-lg block ${this.state.queryResultCount === '999+' || this.state.queryResultCount > 0 ? 'underline cursor-pointer text-cruisys-blue-dark search': 'text-grey-darker'}`}>
                    <button
                      disabled={this.state.csvDownloading || this.state.queryResultCount !== '999+' && this.state.queryResultCount <= 0}
                      onClick={this.onClickDownload.bind(this)}
                      className={`ui icon button export-result ${this.state.queryType === 'custom' ? 'search': ''}`}
                      style={{
                        backgroundColor: '#fff', 
                        color: '#000', 
                        border: '1px solid rgb(222, 225, 229)', 
                        fontSize: '1.00rem', 
                        padding: '14px 16px',}}
                    >
                      {!this.state.csvDownloading
                        ? <span className="icon-ic_import mr-2"></span>
                        : <span>
                            <div className="lds-ellipsis">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </span>
                      }
                      <span className="ml-1">匯出結果</span>
                    </button>
                </div>
            </div>

            {this.state.loading
              ? <div className='h-64 w-full flex justify-center items-center'><Loading type='bubbles' color='#3E47F9' /></div> : ''}
            { !this.state.loading && this.state.view === 'card' ? <TicketCards data={this.state.tickets} /> : ''}
            { !this.state.loading && this.state.view === 'list' ? <TicketTable data={this.state.tickets} /> : ''}
            <div className='w-full flex flex-row justify-center items-center mt-4'>
              {!this.state.loading && this.state.pagination.totalPage
                ? <Pagination
                  activePage={this.state.pagination.currentPage}
                  totalPages={this.state.pagination.totalPage}
                  onPageChange={this.handlePaginationChange.bind(this)}
                  siblingRange={0}
                  ellipsisItem={undefined}
                  boundaryRange={1} /> : ''}
            </div>
          </div>
          <div className={`min-h-screen sidebar w-1/4 pl-4 pr-4 overflow-x-hidden sm:bg-cruisys-grey inner-left-shadow ${this.state.activeTab === 1 ? '' : 'hidden sm:block'}`}>
            <h3 className='my-4' style={{ marginTop: '14px' }}>進出廠車輛</h3>
            {this.state.newComing.length === 0 && !this.state.loadingNewComing
              ? <div className='w-full h-64 flex items-center justify-center flex-col'>
                <img alt='' src='/recent_empty.svg' className='mb-4' />
                <span className='text-grey-dark text-lg'>無進出廠車輛</span>
              </div> : ''}
            {this.state.newComing.map((event, i) => <MiniTicketCard key={i} ticket={event.service_ticket} comingEvent={event} {...this.props}/>)}
          </div>
        </div>
        <div />
      </div>
    )
  }
}

export default MePage

function queryToday (user) {
  let q = {}

  q.start_from = moment().startOf('day').format()
  q.start_to = moment().add(1, 'day').startOf('day').format()
  return q
}

function queryStaying (user) {
  let q = {}

  q.start_to = moment().startOf('day').format()
  q.status = 'started'

  return q
}

function queryPersonal (user) {
  let q = {}

  q.own_task = true

  return q
}

function defaultQuery (user) {
  return queryToday(user)
}

function defaultQueryType (user) {
  return 'today'
}
