import moment from 'moment'
import 'moment/locale/zh-tw'

moment.locale('zh-tw')

const location2type = {
  '1F-REPAIR': 'repair',
  '1F-REPAIR-W01': 'repair',
  '1F-REPAIR-W02': 'repair',
  '1F-REPAIR-W03': 'repair',
  '1F-REPAIR-W04': 'repair',
  '1F-REPAIR-A01': 'repair',
  '1F-REPAIR-B01': 'repair',

  '1F-REPAIR-Q01': 'repair',

  '1F-REPAIR-L01': 'repair',
  '1F-REPAIR-L02': 'repair',
  '1F-REPAIR-L03': 'repair',
  '1F-REPAIR-L04': 'repair',
  '1F-REPAIR-L05': 'repair',

  '1F-DISPATCH'  : 'dispatch',
  '1F-DISPATCH-D01'  : 'dispatch',
  '1F-DISPATCH-D02'  : 'dispatch',

  '1F-RECEP': 'reception',
  '1F-RECEP-R01': 'reception',
  '1F-RECEP-R02': 'reception',
  '1F-RECEP-R03': 'reception',

  '1F-SCHWA': 'reception',
  '1F-SCHWA-P01': 'reception',
  '1F-SCHWA-P02': 'reception',

  '1F-RESERVE': 'reserve',
  '1F-RESERVE-W01': 'reserve',
  '1F-RESERVE-W02': 'reserve',
  '1F-RESERVE-L01': 'reserve',
  '1F-RESERVE-L02': 'reserve',

  '1F-DELIVER': 'checkout',
  '1F-DELIVER-D01': 'checkout',
  '1F-DELIVER-D02': 'checkout',

  '1F-DETAIL': 'detailing',
  '1F-DETAIL-D01': 'detailing',
  '1F-DETAIL-D02': 'detailing',
  '1F-DETAIL-P01': 'detailing',
  '1F-DETAIL-P02': 'detailing',

  'B1F-STANDBY': 'standby'
}

const location2name = {
  '1F-REPAIR': '一樓 快速維修區',
  '1F-REPAIR-W01': '一樓 快速維修區 工位01',
  '1F-REPAIR-W02': '一樓 快速維修區 工位02',
  '1F-REPAIR-W03': '一樓 快速維修區 工位03',
  '1F-REPAIR-W04': '一樓 快速維修區 工位04',
  '1F-REPAIR-A01': '一樓 快速維修區 四輪定位01',
  '1F-REPAIR-B01': '一樓 快速維修區 煞車01',

  '1F-REPAIR-Q01': '一樓 快速維修區 終檢01',

  '1F-REPAIR-L01': '一樓 快速維修區',
  '1F-REPAIR-L02': '一樓 快速維修區',
  '1F-REPAIR-L03': '一樓 快速維修區',
  '1F-REPAIR-L04': '一樓 快速維修區',
  '1F-REPAIR-L05': '一樓 快速維修區',

  '1F-DISPATCH'         : '一樓 中央通道',
  '1F-DISPATCH-D01'     : '一樓 中央通道 01',
  '1F-DISPATCH-D02'     : '一樓 中央通道 02',

  '1F-RECEP': '一樓 接待區',
  '1F-RECEP-R01': '一樓 接待區 01',
  '1F-RECEP-R02': '一樓 接待區 02',
  '1F-RECEP-R03': '一樓 接待區 03',

  '1F-SCHWA': '一樓 黑森林',
  '1F-SCHWA-P01': '一樓 黑森林 01',
  '1F-SCHWA-P02': '一樓 黑森林 02',

  '1F-RESERVE': '一樓 調度區',
  '1F-RESERVE-W01': '一樓 調度區 工位01',
  '1F-RESERVE-W02': '一樓 調度區 工位02',
  '1F-RESERVE-L01': '一樓 調度區',
  '1F-RESERVE-L02': '一樓 調度區',

  '1F-DELIVER': '一樓 交車區',
  '1F-DELIVER-D01': '一樓 交車區 01',
  '1F-DELIVER-D02': '一樓 交車區 02',

  '1F-DETAIL': '一樓 戶外洗車區',
  '1F-DETAIL-D01': '一樓 戶外洗車區 美容01',
  '1F-DETAIL-D02': '一樓 戶外洗車區 美容02',
  '1F-DETAIL-P01': '一樓 戶外洗車區 停車01',
  '1F-DETAIL-P02': '一樓 戶外洗車區 停車02',

  'B1F-STANDBY': '地下一樓 待交區',

}


const location2shortname = {
  '1F-REPAIR': '快速維修區',
  '1F-REPAIR-W01': '工位01',
  '1F-REPAIR-W02': '工位02',
  '1F-REPAIR-W03': '工位03',
  '1F-REPAIR-W04': '工位04',
  '1F-REPAIR-A01': '定位01',
  '1F-REPAIR-B01': '煞車01',

  '1F-REPAIR-Q01': '終檢01',

  '1F-REPAIR-L01': '快速維修區',
  '1F-REPAIR-L02': '快速維修區',
  '1F-REPAIR-L03': '快速維修區',
  '1F-REPAIR-L04': '快速維修區',
  '1F-REPAIR-L05': '快速維修區',

  '1F-DISPATCH'  : '中央通道',
  '1F-DISPATCH-D01'     : '中央通道01',
  '1F-DISPATCH-D02'     : '中央通道02',

  '1F-RECEP': '接待區',
  '1F-RECEP-R01': '接待01',
  '1F-RECEP-R02': '接待02',
  '1F-RECEP-R03': '接待03',

  '1F-SCHWA': '黑森林',
  '1F-SCHWA-P01': '黑森林01',
  '1F-SCHWA-P02': '黑森林02',

  '1F-RESERVE': '調度區',
  '1F-RESERVE-W01': '調度區01',
  '1F-RESERVE-W02': '調度區02',
  '1F-RESERVE-L01': '調度區',
  '1F-RESERVE-L02': '調度區',

  '1F-DELIVER': '交車區',
  '1F-DELIVER-D01': '交車01',
  '1F-DELIVER-D02': '交車02',

  '1F-DETAIL': '戶外洗車區',
  '1F-DETAIL-D01': '美容01',
  '1F-DETAIL-D02': '美容02',
  '1F-DETAIL-D03': '戶外洗車區',
  '1F-DETAIL-D04': '戶外洗車區',

  'B1F-STANDBY': '待交區'

}

function ticketEventsToTimelineData (events, lastServiceDate) {
  let data = events.map(e => {
    let d = {
      time: moment(e.time).toDate(),
      action: e.description,
      location: location2name[e.location],
      type: location2type[e.location] || ( e.progress && e.progress != 'none' && e.line )? 'progress' : 'event', 
      progress:e.progress,
      line: e.line || false
    }

    // 這兩種 event 特別處理，希望可以照進度呈現
    if (e.description.includes('完成進度') || e.description.includes('無訊號')){
      d['type'] = 'progress'
    }

    if (d['type'] === 'event') {
      d['label'] = d['action']
    }

    return d
  }).reverse()

  if (lastServiceDate) {
    data.push({
      time: moment(lastServiceDate).startOf('day').toDate(),
      label: `上次維修 ${lastServiceDate}`,
      location: null,
      type: 'event'
    })
  }

  return data
}

function ticketLastLocation (ticket) {
  for (let i = ticket.events.length - 1; i >= 0; i--) {
    if (ticket.events[i].location) return ticket.events[i].location
  }

  return null
}

function ticketLastType (ticket) {
  return location2type[ticketLastLocation(ticket)]
}

function showStatus (ticket) {
  let color
  let label
  if (ticket.status === 'started') {
    color = '#00D080'
    label = '已開始'
  } else if (ticket.status === 'created' || ticket.status === 'init') {
    color = '#7A7D83'
    label = '尚未開始'
  } else {
    color = '#7A7D83'
    label = '已結束'
  }

  return { color, label }
}

function showProgress (progress) {
  let color
  let label
  let iconName

  if (progress === 'none') {
    color = '#7A7D83'
    label = '尚未開始'
    iconName = 'icon-ic_working'
  } else if (progress === 'recep') {
    color = '#2A4B8D'
    label = '進廠接待'
    iconName = 'icon-ic_reception'
  } else if (progress === 'dispatch') {
    color = '#CA16F5'
    label = '工作調度'
    iconName = 'icon-ic_ticket'
  } else if (progress === 'repair') {
    color = '#F58C16'
    label = '服務進行'
    iconName = 'icon-ic_working'
  } else if (progress === 'detail') {
    color = '#32B16C'
    label = '車輛整備'
    iconName = 'icon-ic_detailing'
  } else if (progress === 'standby'){
    color = '#FFFFFF'
    label = '待交'
    iconName = 'icon-ic_working'
  } else if (progress === 'deliver'){
    color = '#7E7E7E'
    label = '交車說明'
    iconName = 'icon-ic_offline_pin'
  }
  else {
    color = '#7E7E7E'
    label = '未知' + progress
    iconName = 'icon-ic_working'
  }

  return { color, label, iconName }
}

function showShortLocationName(loc) {
  return location2shortname[loc]
}


function carImageHead (plateNo) {
  return `${window.cruisys_config.API_HOST}/static/cars/${plateNo}_head.png`
}

function carImageRear (plateNo) {
  return `${window.cruisys_config.API_HOST}/static/cars/${plateNo}_rear.png`
}

function carImageDefault () {
  return '/car_placeholder.png'
}

function userImageDefault () {
  return '/user_placeholder.png'
}

function lineImageDefault () {
  return '/line_default_avatar.png'
}

function locationImage (ticket) {
  return `${window.cruisys_config.API_HOST}/static/locations/${ticketLastLocation(ticket)}.png`
}

function showStayIn (ticket) {
  let color = '#ff8373'
  let label
  if (ticket.stay_in === true) {
    label = '內候'
  } else if (ticket.stay_in === false) {
    label = '留車'
  } else {
    label = '　' // full-width space
  }

  return { color, label }
}

function showCustomerStatus (ticket) {
  let color = '#ff8373'
  let label;
  label = ticket.customer_status;

  return { color, label }
}

export default {
  ticketLastLocation,
  ticketEventsToTimelineData,
  location2name,
  location2shortname,
  location2type,
  ticketLastType,
  showStatus,
  showProgress,
  showStayIn,
  showCustomerStatus,
  showShortLocationName,

  carImageHead,
  carImageRear,
  carImageDefault,

  userImageDefault,
  lineImageDefault,

  locationImage
}
