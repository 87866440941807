import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import Toolbar from '../components/Toolbar'

import Form from '../components/new/Form'

export default class C extends Component {
  constructor (props) {
    super(props)
    let u = window.localStorage.getItem('loggedIn')
    if (u) u = JSON.parse(u)
    this.state = {
      user: u,
      returnList: false
    }
  }
  handleOnCreated () {
    this.setState({ returnList: true })
  }

  handleOnCancel (){
    this.setState({ returnList: true })
  }

  handleSessionExpired (){
    this.setState({ user: null })
  }

  render () {
    if (!this.state.user) {
      return (<Redirect to={{ pathname: '/' }} />)
    }

    if (this.state.returnList) {
      return (<Redirect to={{ pathname: '/me' }} />)
    }

    return (
      <div>
        <Toolbar />
        <Form 
          onCreated={this.handleOnCreated.bind(this)} 
          onCancel={this.handleOnCancel.bind(this)}
          handleSessionHexpired={this.handleSessionExpired.bind(this)}
          {...this.props}
        />
      </div>
    )
  }
}
