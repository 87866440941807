import React, { Component } from 'react'
import ticketHelper from '../../ticketHelper'
import {Link} from "react-router-dom"

class ProgressItem extends Component {
    render () {
      return (
        <Link to={'/me?qtype=' + this.props.qtype} className={(!this.props.currentValue || this.props.currentValue == 0 ? 'disabled-link' : '')}>
            <div className={'flex flex-row bg-white my-5 py-3 mr-6 rounded ' + (this.props.currentValue > 0 ? 'shadow border border-grey' : '')}>
            <div className={'self-left px-3 block text-xl w-10 ' + this.props.iconName} style={{ color: this.props.color }}/>
            <div className="" style={{ color: this.props.color }}>{this.props.label}</div>
            <div className={'text-4xl px-3 text-right w-16 ' + (this.props.currentValue > 0 ? 'text-cruisys-grey-dark' : 'text-cruisys-grey-light')}>{this.props.currentValue}</div>
            <div className="" style={{ color: this.props.color }}>輛</div>
          </div>
        </Link>
      )
    }
  }

export default class C extends Component {  
    render () {
        let progress = {
            recep : ticketHelper.showProgress('recep'),
            dispatch: ticketHelper.showProgress('dispatch'),
            repair: ticketHelper.showProgress('repair'),
            detail: ticketHelper.showProgress('detail'),
            deliver: ticketHelper.showProgress('deliver')
          };

      return (        
        <div className="flex-col">
          <ProgressItem currentValue={this.props.recep} color={progress.recep.color} label={progress.recep.label} iconName={progress.recep.iconName} qtype='recep' />
          <ProgressItem currentValue={this.props.dispatch} color={progress.dispatch.color} label={progress.dispatch.label} iconName={progress.dispatch.iconName} qtype='dispatch'/>
          <ProgressItem currentValue={this.props.repair} color={progress.repair.color} label={progress.repair.label} iconName={progress.repair.iconName} qtype='repair'/>
          <ProgressItem currentValue={this.props.detail} color={progress.detail.color} label={progress.detail.label} iconName={progress.detail.iconName} qtype='detail'/>
          <ProgressItem currentValue={this.props.deliver} color={progress.deliver.color} label={progress.deliver.label} iconName={progress.deliver.iconName} qtype='deliver'/>
        </div>
      )
    }
  }