import React , {useState} from 'react';
import translate_TW from '../../assets/language/zh-tw';

const Area = (props) => {
    return(
        <div className= {`flex items-center justify-center area m-2
            ${ + props.status ? 'open' : 'close'}`}
            onClick={props.switchArea}
            >
            {translate_TW[props.name]}
        </div>
    )
}

export default Area;