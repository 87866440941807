import React, { Component } from 'react'
import { Header } from 'semantic-ui-react'
import { Formik, Field, Form } from 'formik'

import API from '../api'

import moment from 'moment'
import 'moment/locale/zh-tw'
moment.locale('zh-tw');

export default class AdvancedSearch extends Component {
  constructor (props) {
    super(props)

    let u = window.localStorage.getItem('loggedIn')
    if (u) u = JSON.parse(u)
    this.state = {
      user: u,
      users: []
    }
  }

  async loadUsers () {
    let users = await API.getUsers(this.state.user)

    this.setState({ users: users })
    console.log(users)
  }

  validateWIP(value){
    let error;
    var wip_nolimit = /^[0-9]{5}$/;
    if(value && wip_nolimit.test(value) === false){
     error = "5碼限數字";
    }
    return error;
  }

  validateTag(value){
    let error;
    var tag_nolimit = /^[0-9]*$/;
    if(value && tag_nolimit.test(value) === false){
     error = "請輸入數字";
    }
    return error;
  }

  validatePlateNo(value){
    let error;
    var plate_nolimit = /^[A-Za-z0-9]*$/;
    if(value && plate_nolimit.test(value) === false){
      error = "請輸入英數字";
    }
    return error;

  }

  componentDidMount () {
    this.loadUsers()
  }

  onQueryReady (q) {
    this.props.onQuery(q)
  }

  render () {
    return (
      <div className='rounded bg-grey-light p-4'>
        <Formik
          initialValues={{ start_from: '', start_to: '', end_from: '', end_to: '', WIP_no: '', tag_no: '', plate_no: '', assignee: 'null', customer_status: ''}}
          onSubmit={(values, actions) => {
            console.log(values, actions)

            let query = {}

            // delete values['plate_no']
            Object.keys(values).forEach(k => {
              if (k === 'plate_no') {
                query['car.plate_no'] = values[k].toUpperCase()
                return
              }
              // stay_in 廢棄
              // if (k === 'stay_in') {
              //   if (values[k] === 'all') { return } else {
              //     query[k] = values[k]
              //     return
              //   }
              // }
              if (!values[k] || values[k] === 'null') return

              if (k === 'start_from') {
                query['start_from'] = moment(values[k]).startOf('day').format()
                return
              }
              if (k === 'start_to') {
                query['start_to'] = moment(values[k]).endOf('day').format()
                return
              }
              if (k === 'end_from') {
                query['end_from'] = moment(values[k]).startOf('day').format()
                return
              }
              if (k === 'end_to') {
                query['end_to'] = moment(values[k]).endOf('day').format()
                return
              }

              query[k] = values[k]
            })

            this.onQueryReady(query)
            actions.setSubmitting(false)
          }}
          render={({ errors, status, touched, isSubmitting, isValid, dirty }) => {
            return (
              <Form>
                <Header as='h4'>進階搜尋</Header>
                <div id='advanced-search-fields'>
                  <div className='flex items-center sm:justify-end'>
                    <label htmlFor='start_from'>進廠(從)</label>
                  </div>
                  <Field
                    name='start_from'
                    className='appearance-none border rounded py-2 pl-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline'
                    id='start_from'
                    type='date'
                    style={{ height: '33px' }}
                  />
                  <div className='flex items-center sm:justify-end'>
                    <label htmlFor='start_to'>進廠(至)</label>
                  </div>
                  <Field
                    name='start_to'
                    className='h-8 appearance-none border rounded py-2 pl-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline'
                    id='start_to'
                    type='date'
                    style={{ height: '33px' }}
                  />
                  <div className='flex items-center sm:justify-end'>
                    <label htmlFor='end_from'>交車(從)</label>
                  </div>
                  <Field
                    name='end_from'
                    className='appearance-none border rounded py-2 pl-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline'
                    id='end_from'
                    type='date'
                    style={{ height: '33px' }}
                  />
                  <div className='flex items-center sm:justify-end'>
                    <label htmlFor='end_to'>交車(至)</label>
                  </div>
                  <Field
                    name='end_to'
                    className='appearance-none border rounded py-2 pl-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline'
                    id='end_to'
                    type='date'
                    style={{ height: '33px' }}
                  />
                  <div className='flex items-center sm:justify-end'>
                    <label htmlFor='plate_no'>車牌</label>
                  </div>
                  <div className='plate_no'>
                    <Field 
                      name='plate_no' 
                      className='appearance-none border rounded py-2 pl-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline uppercase w-full'
                      id='plate_no' 
                      type='text' 
                      placeholder='輸入部分或完整車牌' 
                      maxLength={7} 
                      validate={this.validatePlateNo}
                      />
                      {errors.plate_no
                        ? <span className='is-invalid-text ' style={{marginLeft: -6.5 + 'rem'}}>{errors.plate_no}</span>
                        : ''                    
                      }
                  </div>
                  <div className='flex items-center sm:justify-end'>
                    <label htmlFor='tag'>TAG</label>
                  </div>
                  <div className='tag'>
                    <Field name='tag_no' className='appearance-none border rounded py-2 pl-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline w-full' 
                      id='tag' 
                      type='tel' 
                      placeholder='輸入完整TAG' 
                      maxLength={5} 
                      validate={this.validateTag}/>
                      {errors.tag_no
                        ? <span 
                            className='is-invalid-text' 
                            style={{marginLeft: -5.5 + 'rem' }}>
                            {errors.tag_no}
                          </span>
                        : ''
                      }
                  </div>
                  <div className='flex items-center sm:justify-end'>
                    <label htmlFor='customer_status'>來賓狀態</label>
                  </div>
                  <Field component='select' name='customer_status' id='customer_status' className='select-css' style={{ height: '33px' }}>
                    <option value=''>全部</option>
                    <option value='內候'>內候</option>
                    <option value='留車'>留車</option>
                    <option value='取 / 送'>取 / 送</option>
                    <option value='取車回廠'>取車回廠</option>
                    <option value='接駁'>接駁</option>
                    <option value='當天決定'>當天決定</option>
                  </Field>
                  <div className='flex items-center sm:justify-end'>
                    <label htmlFor='progress'>進度</label>
                  </div>
                  <Field component='select' name='progress' id='progress' className='select-css' style={{ height: '33px' }}>
                    <option value='null'>全部</option>
                    <option value='none'>尚未開始</option>
                    <option value='recep'>進廠接待</option>
                    <option value='dispatch'>工作調度</option>
                    <option value='repair'>服務進行</option>
                    <option value='detail'>車輛整備</option>
                    <option value='deliver'>交車說明</option>
                  </Field>
                  <div className='flex items-center sm:justify-end'>
                    <label htmlFor='wip'>WIP</label>
                  </div>
                  <div className='wip'>
                    <Field name='WIP_no' 
                      className='appearance-none border rounded py-2 pl-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline w-full' 
                      id='wip' 
                      type='tel' 
                      placeholder='輸入完整WIP' 
                      maxLength={5} 
                      validate={this.validateWIP}/>
                      {errors && errors.WIP_no                     
                        ? <span className='is-invalid-text absolute -ml-24' style={{marginTop: 0.4 + 'rem'}}>{errors.WIP_no}</span>
                        : ''
                      }
                  </div>
                  <div className='flex items-center sm:justify-end'>
                    <label htmlFor='assignee'>負責人</label>
                  </div>
                  <Field component='select' name='assignee' id='assignee' className='select-css' style={{ height: '33px' }}>
                    <option value='null'>全部</option>
                    {this.state.users.map(u => <option value={u.data._id} key={u.data._id}>{u.data.name}</option>)}
                  </Field>
                </div>
                <div className='w-full flex justify-end mt-4'>
                  <button className='bg-grey px-8 py-3 text-lg rounded mr-4' onClick={this.props.handleCancel}>取消</button>
                  <button type='submit' 
                    className='bg-cruisys-blue text-white px-8 py-3 rounded mr-4 text-lg' 
                    disabled={isSubmitting || dirty && !isValid}>
                      {(isSubmitting
                        ? '搜尋中...'
                        : dirty && !isValid
                          ? '搜尋欄位資料有誤'
                          : '搜尋')}
                  </button>
                </div>
              </Form>
            )
          }}
        />
      </div>
    )
  }
}
