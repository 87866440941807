import React, { Component } from 'react'
import { Button, Modal } from 'semantic-ui-react'

import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import avatarUrl from '../avatarUrl';
import API from '../api'
import '../assets/scss/components/settingmodal.scss';

class LogoutModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  handleCancel () {
    this.props.handleCancel()
  }

  render () {
    return (<Modal open={this.props.open} closeOnDimmerClick size='mini'>
      <Modal.Header>登出確認</Modal.Header>
      <Modal.Content>
        <Modal.Description>
        是否確定要登出系統?
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='grey' onClick={this.handleCancel.bind(this)}>取消</Button>
        <Button color='blue' onClick={this.props.handleOk.bind(this)}>確認</Button>
      </Modal.Actions>
    </Modal>
    )
  }
}

export default class C extends Component {
  constructor (props) {
    super(props)

    let u = window.localStorage.getItem('loggedIn')
    if (u) u = JSON.parse(u)
    this.state = {
      loggedOut: false,
      logoutConfirm: false,
      build_info: {
        api_version: '',
        api_build: '',
        frontend_version: '',
        frontend_build: '' },
      user: u
    }
  }

  async __logout () {
    await API.logout(this.state.user);
    localStorage.clear();
    this.setState({ loggedOut: true })
  }

  handleLogout () {
    this.setState({logoutConfirm: true})
  }

  closePopUp (){
    this.setState({logoutConfirm: false})
  }

  async loadBuildInfo () {
    let cruisys_build = await API.getBuildInfo (this.state.user)
    //console.log(cruisys_build)
    this.setState({build_info: cruisys_build})

  }

  componentDidMount () {
    console.log('this.user is =>', this.state.user);
    this.loadBuildInfo()
  }

  render () {
    if (this.state.loggedOut) {
      return (
        <Redirect to={{ pathname: '/' }} />
      )
    }
    let avatar = avatarUrl(this.state.user.data.avatar)
    return (
      <div className={`settingmodel ${this.props.open ? 'open' : ''}`}>
        <LogoutModal
          open = {this.state.logoutConfirm}
          handleOk={this.__logout.bind(this)}
          handleCancel={this.closePopUp.bind(this)}/>
        <div className="personInfo">
          <img className="icon" src={avatar}/>
          <p className="name">{this.state.user.data.name}</p>
          <p className="setting-title">{this.state.user.data.title} {this.state.user.data.username}</p>
        </div>
        <div className="settings">
          <a className="manage" style={{opacity:'.5', cursor: 'default'}}>
            管理後台
          </a>
          <a className="logout" onClick={this.handleLogout.bind(this)}>登出</a>
          <p className="version">前端版本 : {this.state.build_info.frontend_version},{this.state.build_info.frontend_build}</p>
          <p className="version">API版本 : {this.state.build_info.api_version},{this.state.build_info.api_build}</p>
        </div>
      </div>
    )
  }
}
