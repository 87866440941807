import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OfficeArea from '../components/controlParking/officearea';
import Records from '../components/controlParking/records';
import areaControlApi from '../api/control-api';
import '../assets/scss/page/control.scss';

export default class ControlWelcomeAboard extends Component {
  constructor(props) {
    super(props);
    let user = window.localStorage.getItem('loggedIn')
    if (user) user = JSON.parse(user)
    this.state = {
      user: user,
      areaStatus: null,
      records: null,
    }
    this.setRecords = this.setRecords.bind(this);
    this.switchArea = this.switchArea.bind(this);
    this.updateAreaStatus = this.updateAreaStatus.bind(this);
  }
  // Get the initial state of each area
  async componentDidMount() {
    const area_initres = await areaControlApi.getAreaStatus(this.state.user);
    try {
      if (area_initres.data.data) {
        const arrangedArea = {
          koln: area_initres.data.data['koln'],
          munich: area_initres.data.data['munich'],
          hamburg: area_initres.data.data['hamburg'],
          berlin: area_initres.data.data['berlin']
        };
        this.setState({ areaStatus: arrangedArea });
        this.setRecords(area_initres.data.message_history);
      }
    } catch (error) {
      console.log('getAreaStatus API error is =>', error);
    }
  }
  // patch request to modify status of each area
  async updateAreaStatus() {
    const area_updatedres = await areaControlApi.patchAreaStatus(this.state.user, this.state.areaStatus);
    try {
      if (area_updatedres.data) {
        const arrangedArea = {
          koln: area_updatedres.data.data['koln'],
          munich: area_updatedres.data.data['munich'],
          hamburg: area_updatedres.data.data['hamburg'],
          berlin: area_updatedres.data.data['berlin']
        };
        this.setState({ areaStatus: arrangedArea });
        this.setRecords(area_updatedres.data.message_history);
      }
    } catch (error) {
      console.log('patchAreaStatus API error is =>', error);
    }
  }
  // turn Off or turn On area by hand
  switchArea(areaname, status) {
    let newareaStatus = this.state.areaStatus;
    newareaStatus[areaname] = status;
    this.setState({ areaStatus: newareaStatus }, () => this.updateAreaStatus());
  }
  // set records after "init" and "updateAreaStatus"
  setRecords(messages) {
    this.setState({ records: messages });
  }
  render() {
    return (
      <React.Fragment>
        <div className='cruisys_title_area'>
          <Link className='cruisys_link' to='/stats'>BMW Service</Link>
          <p className='cruisys_title'><span className='hidden md:inline-block mr-3'>Cruisys Welcome Aboard</span> 接待區車位控制</p>
        </div>
        <main className='container mx-auto px-2'>
          <OfficeArea
            areaStatus={this.state.areaStatus}
            switchArea={this.switchArea} />
          <Records records={this.state.records} />
        </main>
      </React.Fragment>
    )
  }
}