import React, { Component } from 'react'

import * as Scroll from 'react-scroll'

export default class C extends Component {
  render () {
    let color = this.props.color ? this.props.color : 'teal'
    let padding = ['pl-1']
    if (this.props.pt) {
      padding.push('pt-3')
    }
    if (this.props.pb) {
      padding.push('pb-3')
    }

    return (
      <div className={padding.join(' ')}>
        <div className='border-l-8 bg-white p-3 shadow-md' style={{ borderColor: color }}>
          <div className='flex flex-row'>
            <span className={`${this.props.iconName} text-xl block mt-1`} style={{ color: color }} />
            <div className='ml-2'>
              { this.props.scrollName
                ? <Scroll.Element name={this.props.scrollName}><div className='text-lg pb-1 font-bold' style={{ color: color }}>{this.props.progressName}</div></Scroll.Element>
                : <div className='text-lg pb-1 font-bold' style={{ color: color }}>{this.props.progressName}</div>}
              <div className={'flex ' + (this.props.line ? 'pt-1' : '')} style={{color: this.props.textColor}}>
                { this.props.line
                  ? <img className='mr-1' style={{width:'20px', height:'20px'}} src='/line_app_icon.png'></img>
                  :  ''}
                {this.props.text}
              </div>
              <div style={{color: this.props.textColor}}>{this.props.subText}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
