import React, { Component } from 'react'

import { Button } from 'semantic-ui-react'

const s = {
  backgroundColor: 'white',
  color: 'black',
  border: '1px solid #dee1e5'
}

export default class C extends Component {
  constructor (props) {
    super(props)
    this.state = { touched: false }
  }

  onTouchStart () {
    this.setState({ touched: true })
  }

  onTouchEnd () {
    this.setState({ touched: false })
  }

  render () {
    var style
    if (this.state.touched) {
      style = { ...s, ...this.props.style, backgroundColor: 'grey' }
    } else {
      style = { ...s, ...this.props.style }
    }
    return (
      <Button onTouchStart={this.onTouchStart.bind(this)} onTouchEnd={this.onTouchEnd.bind(this)} icon={this.props.icon} style={style} onClick={this.props.onClick} />
    )
  }
}
