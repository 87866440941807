import React, { Component } from 'react'
import { Button, Modal, Form } from 'semantic-ui-react'

export default class CompleteTicketModal extends Component {
  constructor (props) {
    super(props)
    this.state = {      
      paid: (this.props.paid) ? true : false,
      checked: 0,
      checkItemsCount: (this.props.paid) ? 2 : 3
    }

    this.emptyCommentString = '確認客戶交辦事項 (無)';
  }

  handleCheck (e, v) {
    console.log(v.checked)

    if (v.checked) {
      this.setState({ checked: this.state.checked + 1 })
    } else {
      this.setState({ checked: this.state.checked - 1 })
    }
  }

  handleCancel () {
    this.setState({ checked: 0 })
    this.props.handleCancel()
  }

  render () {
    return (<Modal open={this.props.open} closeOnDimmerClick size='mini'>
      <Modal.Header>確認關閉服務單？</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form.Checkbox label='回收 TAG' onChange={this.handleCheck.bind(this)} />
          <Form.Checkbox label='完成結帳' style={{ marginTop: '1rem' }} onChange={this.handleCheck.bind(this)} disabled={this.state.paid} defaultChecked={this.state.paid} />
          <Form.Checkbox label={this.props.comment === '' || this.props.comment === null ? this.emptyCommentString : this.props.comment} style={{ marginTop: '1rem' }} onChange={this.handleCheck.bind(this)} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='grey' onClick={this.handleCancel.bind(this)}>取消</Button>
        <Button disabled={this.state.checked !== this.state.checkItemsCount} color='blue' onClick={this.props.handleOk.bind(this)}>確認</Button>
      </Modal.Actions>
    </Modal>
    )
  }
}
