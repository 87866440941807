import React, { Component } from 'react'

import { Icon, Input } from 'semantic-ui-react'

export default class C extends Component {
  constructor (props) {
    super(props)
    this.state = {
      plate_no_query: '',
      error: ''
    }
  }

  onChange (e) {
    let error = '';
    error = this._validate(e.target.value)
    this.setState({ plate_no_query: e.target.value, fieldWarning: error })
  }

  handleClick () {
    if (this.state.plate_no_query !== '') {
      this.props.onQuery({ 'car.plate_no': this.state.plate_no_query })
    }
  }

  _validate (value){
    let error = '';
    var plate_nolimit = /^[A-Za-z0-9]*$/;
    if(value && plate_nolimit.test(value) === false){
      error = "請輸入英數字";
    }
    return error
  }

  render () {
    return (
      <Input
        className={this.props.className}
        value={this.state.plate_no_query}
        icon
        placeholder='輸入部分或完整車牌'
        onChange={this.onChange.bind(this)}
        style={{ ...this.props.style }} >
        <input
          maxLength={7}
        />
        {this.state.fieldWarning
          ? <span style={{color: 'red', lineHeight: '2.5', marginLeft: -11 + 'rem'}}>請輸入英數字</span>
          : ''
        }
        <Icon
          name='search'
          circular={true}
          link={true}
          onClick={!Boolean(this.state.fieldWarning)
            ? this.handleClick.bind(this)
            : null }
          // customized style instead of using disabled
          style={this.state.fieldWarning
            ? {opacity: '0.2'}
            : {}}
        />
      </Input>
    )
  }
}
