import React, { Component } from 'react'
import { Button, Modal } from 'semantic-ui-react'

export default class LoginErrorModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (<Modal open={this.props.open} closeOnDimmerClick size='mini'>
      <Modal.Header>權限不足</Modal.Header>
      <Modal.Content>
        <Modal.Description>
        您沒有權限進入，請聯繫您的管理員協助。
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='blue' onClick={this.props.handleOk.bind(this)}>確認</Button>
      </Modal.Actions>
    </Modal>
    )
  }
}
