import React, { Component } from 'react'

import { Icon } from 'semantic-ui-react'

const s = {
  backgroundColor: 'white',
  color: 'black',
  border: '1px solid #dee1e5'
}

export default class SubToolbarButtonWithText extends Component {
  constructor (props) {
    super(props)
    this.state = { touched: false }
  }

  onTouchStart () {
    this.setState({ touched: true })
  }

  onTouchEnd () {
    this.setState({ touched: false })
  }

  render () {
    var style
    if (this.state.touched) {
      style = { ...s, ...this.props.style, backgroundColor: 'grey' }
    } else {
      style = { ...s, ...this.props.style }
    }
    return (
      <div
        className='w-full h-full rounded flex justify-center items-center text-xl'
        onTouchStart={this.onTouchStart.bind(this)}
        onTouchEnd={this.onTouchEnd.bind(this)}
        style={style}
        onClick={this.props.onClick} >
        {this.props.icon ? <Icon name={this.props.icon} /> : ''}
        <span className='ml-2'>{this.props.name}</span>
      </div>
    )
  }
}
