import React, { Component } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import API from '../../api'
import moment from 'moment'
import 'moment/locale/zh-tw'
import formHelper from '../../formHelper'
import avatarUrl from '../../avatarUrl'
import Loading from '../Loading'
import { get } from 'lodash'
import { Prompt } from 'react-router'
import ticketHelper from '../../ticketHelper'

import ImageWithDefault from '../ImageWithDefault'
import ServiceTypeSelect from '../TicketField/ServiceTypeSelect'
import NumberFormat from 'react-number-format';

moment.locale('zh-tw')

export default class NewTicketForm extends Component {
  constructor(props) {
    super(props)

    let u = window.localStorage.getItem('loggedIn')
    if (u) u = JSON.parse(u)
    this.state = {
      user: u,
      users: [],
      loading: true,
      carID: undefined,
      plate_no : null,
      nonbooked_id : null,
      created_time: null,
      called : false,
    }
    this.changed = false
    // 使用者透過輸入車牌載入的車輛資訊，null 代表沒有對應車輛
    // 只要車牌沒有修改，這個資訊就不會變
    this.baseCar = null

    this.lastCheckedPlateNO = ''
  }

  async loadUsers() {
    try{
      let users = await API.getUsers(this.state.user)
      this.setState({ users: users, loading: false })
      // console.log(users)
    } catch(e) {
      if (e.response.status === 401) {
        window.localStorage.clear();
        this.props.handleSessionHexpired()
      }

    }
  }

  componentDidMount() {
    // If this page is come from 總覽頁的 "進出場車輛" icon, connect plate_no and nonbooked_id
    if(this.props.location.state){
      this.setState({
        plate_no : this.props.location.state.plate_no,
        nonbooked_id : this.props.location.state.nonbooked_id,
        created_time : this.props.location.state.created_time
      });
    } 
    this.loadUsers();   
  }

  render() {    
    if (this.state.loading) {
      return (
        <div className='bg-white min-h-screen'>
          <Loading type='bubbles' color='#3E47F9' />
        </div>
      )
    }
    return (
      <Formik
        initialValues={{
          ticket: formHelper.newFormValue(this.state.user.data._id)
        }}
        onSubmit={async (values, actions, onSubmit, isValid) => { 
          this.changed = false
          try {
            // if baseCarID === null
            // create car, get car ID
            let carID = this.state.newCarID
            if (!this.baseCar) {
              // 這是一台全新的車輛
              let newCar = await API.createCar(this.state.user, values.ticket.car)
              carID = newCar._id
            }

            //處理字串前後空白
            values.ticket = formHelper.trimFormField(values.ticket)

            // create ticket
            console.log('creating ticket', values.ticket)
            let newTicket = formHelper.formValue2ticket(values.ticket)
            // 先把 contact 資料備份起來
            let newTicketCar = JSON.parse(JSON.stringify(newTicket.car))
            // 把車輛資料換成 car ID
            newTicket.car = carID;
            if(this.state.nonbooked_id){
              newTicket.nonbooked = this.state.nonbooked_id;
            }
            console.log('newTicket is =>', newTicket);
            let ret = await API.createTicket(this.state.user, this.state.newCarID, newTicket)
            console.log('ret is =>', ret);

            // create snapshot for given ticket ID with car data
            let r = await API.createCarSnapshot(this.state.user, ret._id, carID, newTicketCar)
            console.log(r)

            this.props.onCreated()
          } catch (e) {
            console.error(e)
            if(e.response){
              // axios unexpected response, refactor in the future
              if(e.response.data){
                console.error('Error Message: ' + JSON.stringify(e.response.data.message))
              }
            }
            actions.setErrors({ form: '建立失敗，請檢查資料是否正確' })
            actions.setSubmitting(false)
            window.scrollTo(0, 0)
          }
        }}

        validate={formHelper.validateForm.bind(this)}
        render={({ errors, status, touched, dirty, isSubmitting, values, setFieldValue, isValid, setStatus, validateForm}) => {
          // console.log('values is =>', values);
          let avatar
          if (values.ticket.assignee) {
            if (values.ticket.assignee === 'null') {
              avatar = '/empty_user.png'
            } else {
              if (values.ticket.assignee.avatar) { // assignee is an object
                avatar = avatarUrl(get(values.ticket, 'assignee.avatar', ''))
              } else { // assignee is an ID
                // console.log(this.state.users, values.ticket.assignee)
                let u = this.state.users.filter(u => u.data._id === values.ticket.assignee)[0]
                // console.log(this.state.users)
                avatar = avatarUrl(u.data.avatar)
              }
            }
          }
          let carImage = ''
          if (values.ticket.car.plate_no.length >= 4) {
            carImage = ticketHelper.carImageHead(values.ticket.car.plate_no.toUpperCase())
          }
          if(this.state.plate_no && this.state.nonbooked_id && this.state.created_time){
            values.ticket.car.plate_no = this.state.plate_no;
            values.ticket.booked = 'false'; // represent come self
            values.ticket.timeFormValue.estimated_arrival_time = `${this.state.created_time.slice(0, 10)}T${this.state.created_time.slice(11, 16)}`;
            isSubmitting = false;
            isValid = true;
            if(!this.state.called){
              validateForm();
              this.setState({called : true});
            }
          }
          return (
            <div className='' style={{ marginTop: '49px' }} >
              <Form>
                <Prompt
                  when={this.changed}
                  message={location => '表單有未儲存的修改，是否要捨棄變更？'}
                />
                <ErrorMessage name='form' component='div' className='p-4 mt-8 mx-auto w-full text-white bg-cruisys-red rounded text-2xl font-bold text-center' />
                <div className='bg-white border-b border-grey shadow p-8 new-form-section'>
                  <div>
                    <h2>新建服務單</h2>
                    <h3 className='sm:hidden text-xl text-grey-darker'>車牌</h3>
                    <div style={{ display: 'inline-block', width: '100%' }}>
                    <div className='flex-row'>
                      {this.state.plate_no && this.state.nonbooked_id 
                        ? <h1 className="font-black text-3xl mb-4 text-black font-sans">{values.ticket.car.plate_no}</h1>
                        : <React.Fragment>
                            <Field name='ticket.car.plate_no' className={'py-2 pl-2 font-sans text-3xl font-black w-full sm:w-64 ' + ( (errors.ticket && errors.ticket.car && errors.ticket.car.plate_no) ? 'is-invalid-field' : 'border-grey border') } placeholder='AS1234' style={{textTransform: 'uppercase' }} validate={formHelper.validatePlateNo.bind(this)} maxLength={7} />
                            { errors.ticket && errors.ticket.car && errors.ticket.car.plate_no
                              ? <div className='is-invalid-text text-xl'>{errors.ticket.car.plate_no}</div>
                              : ''
                            }
                          </React.Fragment>
                      }
                      
                    </div>
                    </div>
                    <div className='mt-6 new-form-sub-section'>
                      <div className='sm:flex justify-center items-center hidden sm:inline-block'>
                        {values.ticket.assignee
                          ? <img alt='' src={avatar} style={{ width: '40px', height: '40px' }} className='rounded-full' />
                          : <img alt='' src='/empty_user.png' style={{ width: '40px', height: '40px' }} />}
                      </div>
                      <div className='block sm:hidden flex items-center' id='new-ticket-assignee-input-label'>
                        <h3 className='text-xl text-grey-darker'>負責人</h3>
                      </div>
                      <Field component='select' className='inline-block select-css' name='ticket.assignee' >
                        <option value='null' >尚未指定</option>
                        {this.state.users.map(u => <option value={u.data._id} key={u.data._id}>{u.data.name}</option>)}
                      </Field>

                      <div className='flex justify-start sm:justify-center items-center'>
                        <span className='text-xl text-grey-darker font-bold sm:font-normal'>WIP</span>
                      </div>
                      <div className='flex-row'>
                        <Field name='ticket.WIP_no' type='tel' className={'py-2 px-4 text-xl w-full ' + (errors.ticket && errors.ticket.WIP_no ? 'is-invalid-field' : 'border border-grey')} placeholder='12345' maxLength={5} validate={formHelper.validateWIP} />
                        {errors.ticket && errors.ticket.WIP_no
                          ? <div className='is-invalid-text text-xl'>{errors.ticket.WIP_no}</div>
                          : ''
                        }
                      </div>

                      <div className='sm:flex justify-center items-center hidden' style={{ width: '40px', height: '40px' }}>
                        <span className='icon-ic_reservation text-3xl block' />
                      </div>
                      <div className='block sm:hidden flex items-center' id='new-ticket-booked-input-label'>
                        <h3 className='text-xl text-grey-darker'>預約狀況</h3>
                      </div>
                      {this.state.plate_no && this.state.nonbooked_id 
                        ? <div className='flex selfcome items-center' id='new-ticket-booked-input'>自來</div>
                        : <Field component='select' className='select-css' value={values.ticket.booked} name='ticket.booked' id='new-ticket-booked-input'>
                            <option value='true'>預約</option>
                            <option value='false'>自來</option>
                          </Field>
                      }
                      <div className='flex justify-start sm:justify-center items-center'>
                        <span className='text-xl text-grey-darker font-bold sm:font-normal'>TAG</span>
                      </div>
                      <div className='flex-row'>
                        <Field
                          name='ticket.tag_no'
                          type='tel'
                          className={'py-2 px-4 text-xl w-full ' + (errors.ticket && errors.ticket.tag_no ? 'is-invalid-field' : 'border-grey border') }
                          placeholder='12345'
                          maxLength={5}
                          validate={ async (value) => {
                            return await formHelper.validateTag.call(this, value, setStatus)
                          }}/>
                        {errors.ticket && errors.ticket.tag_no
                          ? <div className='is-invalid-text text-xl'>{errors.ticket.tag_no}</div>
                          : status && status.tag
                            ?<div className='is-warning-text'>{status.tag}</div>
                            :''
                        }
                      </div>

                      <div className='sm:flex justify-center items-center hidden' style={{ width: '40px', height: '40px' }}>
                        <span className='icon-ic_star-full text-3xl block' />
                      </div>
                      <div className='block sm:hidden flex items-center' id='new-ticket-service-input-label'>
                        <h3 className='text-xl text-grey-darker'>服務類型</h3>
                      </div>
                      <ServiceTypeSelect className='select-css' name='ticket.service_type' id='new-ticket-service-input' />

                      <div className='flex justify-start sm:justify-center items-center'>
                        <span className='text-xl text-grey-darker font-bold sm:font-normal'>系列</span>
                      </div>
                      <Field type='text' placeholder='F-30' className='py-2 px-4 text-xl w-full border border-grey' name='ticket.car.series' />

                      <div className='sm:flex justify-center items-center hidden' style={{ width: '40px', height: '40px' }}>
                        <span className='icon-ic_customer_here text-3xl block' />
                      </div>
                      <div className='block sm:hidden flex items-center' id='new-ticket-stayin-input-label'>
                        <h3 className='text-xl text-grey-darker'>來賓狀態</h3>
                      </div>
                      <Field component='select' className='select-css' name='ticket.customer_status' id='new-ticket-stayin-input'>
                        <option value='內候'>內候</option>
                        <option value='留車'>留車</option>
                        <option value='取 / 送'>取 / 送</option>
                        <option value='取車回廠'>取車回廠</option>
                        <option value='接駁'>接駁</option>
                        <option value='當天決定'>當天決定</option>
                      </Field>

                      <div className='flex justify-start sm:justify-center items-center'>
                        <span className='text-xl text-grey-darker font-bold sm:font-normal'>里程</span>
                      </div>
                      <NumberFormat
                        className='py-2 px-4 text-xl border w-full border-grey'
                        type='tel'
                        thousandSeparator={true}
                        value={values.ticket.car.mileage}
                        placeholder='999,999'
                        maxLength={7}
                        allowNegative={false}
                        decimalScale={0}
                        onValueChange={val => setFieldValue('ticket.car.mileage', val.floatValue)}
                        isAllowed={(values) => {
                          const { formattedValue, floatValue } = values;
                          return  formattedValue === "" || floatValue <= 999999;
                        }}/>

                      <div className="flex my-4 sm:my-0 justify-start sm:justify-center items-center">
                        <span className="text-xl text-grey-darker font-bold sm:font-normal">VIN</span>
                      </div>
                      <div className='flex-row new-form-vin-input'>
                        <Field name='ticket.car.vin' className={'py-2 px-4 text-xl w-full ' + (errors.ticket && errors.ticket.car && errors.ticket.car.vin ? 'is-invalid-field' : 'border-grey border')} maxLength={17} placeholder='V12345678901234567' validate={formHelper.validateVin} />
                        {errors.ticket && errors.ticket.car && errors.ticket.car.vin
                          ? <div className='is-invalid-text text-xl'>{errors.ticket.car.vin}</div>
                          : ''
                        }
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-center items-center'>
                    <ImageWithDefault 
                      alt='car form'
                      src={carImage} 
                      default={ticketHelper.carImageDefault()} 
                      style={{ width:'100%', height:'auto', maxWidth:'300px' }}
                    />
                  </div>
                </div>

                <div className='p-8 border-b border-grey new-form-section'>
                  <div>
                    <h4 className='text-xl font-black mb-6 tracking-wide'>工作計劃</h4>
                    <div className='flex flex-row items-center'>
                      <span className='text-4xl block font-black text-cruisys-blue w-8 mb-10' >
                        <span className='icon-ic_reception text-xl block mt-1' style={{ color: '#1F4287' }} />
                      </span>
                      <div className='ml-2 text-xl' style={{ width: '310px' }}>
                        <div>
                          <span className='pb-1 inline-block' style={{ width: '8rem' }}>預計進廠</span>
                          <Field name='ticket.timeFormValue.estimated_arrival_time' type='datetime-local' className='border border-grey mt-1' />
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-row items-center mt-4'>
                      <span className='text-4xl block font-black text-cruisys-blue w-8' >
                        <span className='icon-ic_working text-xl block mt-1' style={{ color: '#FF8373' }} />
                      </span>
                      <div className='ml-2 text-xl' style={{ width: '310px' }}>
                        <div>
                          <span className='pb-1 inline-block' style={{ width: '6rem' }}>維修時間</span>
                          <span>
                            <Field type='tel' name='ticket.timeFormValue.estimated_repair_time.hr' className='mr-4 w-16 border border-grey py-2 px-4' placeholder={0} maxLength={2} />
                            時
                            <Field type='tel' name='ticket.timeFormValue.estimated_repair_time.m' className='mx-4 w-16 border border-grey py-2 px-4' placeholder={0} maxLength={2} />
                            分
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-row mt-4 items-center'>
                      <span className='text-4xl block font-black text-cruisys-blue w-8' >
                        <span className='icon-ic_detailing text-xl block mt-1' style={{ color: '#00D4FF' }} />
                      </span>
                      <div className='ml-2 text-xl' style={{ width: '310px' }}>
                        <div>
                          <span className='pb-1 inline-block' style={{ width: '6rem' }}>美容時間</span>
                          <span>
                            <Field type='tel' name='ticket.timeFormValue.estimated_detailing_time.hr' className='mr-4 w-16 border border-grey py-2 px-4' placeholder={0} maxLength={2} />
                            時
                            <Field type='tel' name='ticket.timeFormValue.estimated_detailing_time.m' className='mx-4 w-16 border border-grey py-2 px-4' placeholder={0} maxLength={2} />
                            分
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-row items-center mt-4'>
                      <span className='text-4xl block font-black text-cruisys-blue w-8 mb-10' >
                        <span className='icon-ic_offline_pin text-xl block mt-1' style={{ color: '#6369F8' }} />
                      </span>
                      <div className='ml-2 text-xl' style={{ width: '310px' }}>
                        <div>
                          <span className='pb-1 inline-block' style={{ width: '8rem' }}>預計交車</span>
                          <Field name='ticket.timeFormValue.estimated_delivery_time' type='datetime-local' className='border border-grey mt-1' />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='mt-6 sm:mt-0'>
                    <h4 className='text-xl font-black mb-6 tracking-wide'>確認事項</h4>
                    <Field component='textarea' className='w-full sm:w-5/6 h-32 border border-grey-dark p-2 text-xl' name='ticket.checklists[0].comment' placeholder='需要注意事項備註' />
                  </div>
                </div>

                <div className='p-8 border-b border-grey new-form-section'>
                  <div>
                    <h4 className='text-xl font-black mb-6 tracking-wide'>聯絡資訊</h4>
                    <div className='flex flex-row items-center w-full mb-4'>
                      <span className='text-xl w-2/5'>客戶姓名</span>
                      <Field name='ticket.contact.name' className='py-2 px-4 text-xl w-3/5 sm:w-48 border border-grey' placeholder='王小美' />
                    </div>
                    <div className='flex flex-row items-center w-full mb-4'>
                      <span className='text-xl w-2/5'>客戶稱謂</span>
                      <Field name='ticket.contact.title' className='py-2 px-4 text-xl w-3/5 sm:w-48 border border-grey' component='select'>
                        <option value='先生'>先生</option>
                        <option value='小姐'>小姐</option>
                        <option value='女士'>女士</option>
                        <option value='未指定'>未指定</option>
                      </Field>
                    </div>
                    <div className='flex flex-row items-center w-full mb-4'>
                      <span className='text-xl w-2/5'>客戶電話</span>
                      <Field name='ticket.contact.phone' type='tel' className='py-2 px-4 text-xl w-3/5 sm:w-48 border border-grey' placeholder='0900000000' />
                    </div>
                  </div>
                </div>
                <div className='pl-4 pt-4 flex justify-center items-center mb-20'>
                  <button className='mb-16 mt-4 mr-4 bg-grey-light text-black p-4 text-2xl rounded-lg w-2/5' onClick={this.props.onCancel}>取消</button>
                  <button type='submit' disabled={isSubmitting || !isValid} className={'mb-16 mt-4 ml-4 bg-cruisys-blue p-4 text-2xl rounded-lg w-2/5 text-white'}>
                    {(touched && dirty && !isValid ? '資料有誤' : '建立')}
                  </button>
                </div>
              </Form>
            </div>
          )
        }}
      />
    )
  }
}
