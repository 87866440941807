const axios = require('axios')

// only store data, designed to be serialized and saved to localStorage
class User {
  constructor (token, userData) {
    this.token = token
    this.data = userData
  }
}

async function login (username, password) {
  let resp = await axios.post(`${window.cruisys_config.API_HOST}/users/login`, {
    username: username,
    password: password
  })

  return new User(resp.data.data.token, resp.data.data.user)
}

async function loginWithLine (accessToken) {
  let resp = await axios.post(`${window.cruisys_config.API_HOST}/users/login`, {
    access_token: accessToken
  })

  return new User(resp.data.data.token, resp.data.data.user)
}

async function getUser (authUser, id) {
  let resp = await axios.get(`${window.cruisys_config.API_HOST}/users/${id}`, {
    headers: { 'X-api-token': authUser.token }
  })

  return new User(undefined, resp.data.data)
}

async function getUsers (authUser) {
  let resp = await axios.get(`${window.cruisys_config.API_HOST}/users`, {
    headers: { 'X-api-token': authUser.token }
  })

  return resp.data.data.map(ud => new User(undefined, ud))
}

async function getCars (authUser) {
  let resp = await axios.request({
    url: `${window.cruisys_config.API_HOST}/cars`,
    method: 'GET',
    params: { plate_no: '' },
    headers: { 'X-api-token': authUser.token }
  })

  return resp.data.data
}

async function getCar (authUser, plateNO) {
  let resp = await axios.request({
    url: `${window.cruisys_config.API_HOST}/cars`,
    method: 'GET',
    params: { plate_no: plateNO },
    headers: { 'X-api-token': authUser.token }
  })

  return resp.data.data
}

async function createCar (authUser, data) {
  let resp = await axios.request({
    url: `${window.cruisys_config.API_HOST}/cars`,
    method: 'POST',
    headers: { 'X-api-token': authUser.token },
    data: {
      ...data
    }
  })

  return resp.data.data
}

async function createCarSnapshot (authUser, ticketID, carID, data) {
  let resp = await axios.request({
    url: `${window.cruisys_config.API_HOST}/cars/${carID}/snapshots`,
    method: 'POST',
    headers: { 'X-api-token': authUser.token },
    data: {
      ...data,
      service_ticket_id: ticketID
    }
  })

  return resp.data.data
}

async function createTicket (authUser, car, data) {
  let resp = await axios.request({
    url: `${window.cruisys_config.API_HOST}/service_tickets`,
    method: 'POST',
    headers: { 'X-api-token': authUser.token },
    data: {
      car: car._id,
      ...data
    }
  })
  return resp.data.data
}

async function getTicket (authUser, ticketID) {
  let resp = await axios.get(`${window.cruisys_config.API_HOST}/service_tickets/${ticketID}`, {
    headers: { 'X-api-token': authUser.token }
  })
  return resp.data.data
}

async function getTickets (authUser) {
  let resp = await axios.request({
    url: `${window.cruisys_config.API_HOST}/service_tickets`,
    method: 'GET',
    headers: { 'X-api-token': authUser.token }
  })
  return resp.data.data
}

async function searchTicket (authUser, queryParams) {
  let resp = await axios.get(`${window.cruisys_config.API_HOST}/service_tickets`, {
    headers: { 'X-api-token': authUser.token },
    params: queryParams
  })
  console.log(resp.data)

  return resp.data
}

async function getTicketDataInSync (authUser, queryParams) {
  let response = await axios.get(`${window.cruisys_config.API_HOST}/service_tickets`, {
    headers: { 'X-api-token': authUser.token },
    params: queryParams,
    responseType: 'blob',
  })
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'report.csv');
  document.body.appendChild(link);
  link.click();
}

async function updateTicket (authUser, ticketID, updates) {
  let resp = await axios.request({
    url: `${window.cruisys_config.API_HOST}/service_tickets/${ticketID}`,
    method: 'PATCH',
    headers: { 'X-api-token': authUser.token },
    data: updates
  })

  return resp.data.data
}

async function __deleteTickets () {
  await axios.delete(`${window.cruisys_config.API_HOST}/backdoor_drop_service_tickets`)
}

async function getTag (authUser, tagID) {
  let resp = await axios.get(`${window.cruisys_config.API_HOST}/tags/${tagID}`, {
    headers: { 'X-api-token': authUser.token }
  })

  return resp.data.data
}

async function logout (authUser) {
  let resp = await axios.request({
    url: `${window.cruisys_config.API_HOST}/users/logout`,
    method: 'POST',
    headers: { 'X-api-token': authUser.token }
  })

  return resp.data.message
}

async function __deleteNotifications (authUser) {
  let r = await axios.delete(`${window.cruisys_config.API_HOST}/backdoor_drop_notifications/users/${authUser.data._id}`)

  return r.data.data
}

async function getNotifications (authUser) {
  let resp = await axios.request({
    url: `${window.cruisys_config.API_HOST}/notifications?user_id=${authUser.data._id}`,
    method: 'GET',
    headers: { 'X-api-token': authUser.token }
  })

  return resp.data.data
}

async function getNotificationsNoLimit (authUser) {
  let resp = await axios.request({
    url: `${window.cruisys_config.API_HOST}/notifications?user_id=${authUser.data._id}&limit=0`,
    method: 'GET',
    headers: { 'X-api-token': authUser.token }
  })

  return resp.data.data
}

async function __insertNotification (authUser, data) {
  let resp = await axios.request({
    url: `${window.cruisys_config.API_HOST}/backdoor/insert_notification`,
    method: 'POST',
    data: data
  })

  return resp.data
}

async function markAllNotificationsRead (authUser) {
  let r = await axios.request({
    url: `${window.cruisys_config.API_HOST}/notifications/user/${authUser.data._id}`,
    method: 'PATCH',
    headers: { 'X-api-token': authUser.token }
  })

  return r.data.data
}

async function markNotificationRead (authUser, notificationID) {
  let r = await axios.request({
    url: `${window.cruisys_config.API_HOST}/notifications/${notificationID}`,
    method: 'PATCH',
    headers: { 'X-api-token': authUser.token }
  })

  return r.data.data
}

async function getRecent (authUser) {
  let r = await axios.request({
    url: `${window.cruisys_config.API_HOST}/movements`,
    method: 'GET',
    headers: { 'X-api-token': authUser.token }
  })

  return r.data.data
}

async function getNewComing (authUser) {
  let r = await axios.request({
    url: `${window.cruisys_config.API_HOST}/car.plate_no/recent`,
    method: 'GET',
    headers: { 'X-api-token': authUser.token }
  })

  return r.data.data
}

async function getNewComingUpdate (authUser, event_id) {
  let r = await axios.request({
    url: `${window.cruisys_config.API_HOST}/car.plate_no/recent/${event_id}`,
    method: 'GET',
    headers: { 'X-api-token': authUser.token }
  })

  return r.data.data
}

async function uploadCSV (authUser, file, formKey) {
  const data = new window.FormData()
  data.append(formKey, file, file.name)

  let res = await axios.request({
    url: `${window.cruisys_config.API_HOST}/service_tickets/import/file`,
    data: data,
    method: 'POST',
    headers: {
      'X-api-token': authUser.token,
      'Content-Type': 'multipart/form-data'
    }
  })

  return res.data
}

async function getDashboard (authUser, dateString) {
  let r = await axios.request({
    url: `${window.cruisys_config.API_HOST}/reports/summary/daily/${dateString}`,
    method: 'GET',
    headers: { 'X-api-token': authUser.token }
  })

  return r.data.data
}

async function getBuildInfo (authUser) {
  let r = await axios.request({
    url: `${window.cruisys_config.API_HOST}/hello`,
    method: 'GET',
    headers: { 'X-api-token': authUser.token }
  })

  return r.data
}


async function __insertRecent (authUser, plateNO) {
  let r = await axios.request({
    url: `${window.cruisys_config.API_HOST}/backdoor_car_incoming`,
    method: 'POST',
    headers: { 'X-api-token': authUser.token },
    data: { plate_no: plateNO }
  })

  return r.data.data
}

// module.exports = { User, login, getUser, getUsers }
export default {
  User,
  login,
  loginWithLine,
  getUser,
  getUsers,

  getCars,
  getCar,
  createCar,
  createCarSnapshot,

  createTicket,
  getTicket,
  searchTicket,
  __deleteTickets,
  updateTicket,
  getTickets,
  getTag,
  logout,
  getTicketDataInSync,

  __deleteNotifications,
  __insertNotification,
  markAllNotificationsRead,
  markNotificationRead,
  getNotifications,
  getNotificationsNoLimit,

  getDashboard,
  getBuildInfo,

  uploadCSV,

  getRecent,
  getNewComing,
  getNewComingUpdate,
  __insertRecent,
}
