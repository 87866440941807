import React, { Component } from 'react'
import { Menu, Label } from 'semantic-ui-react'
import { Link, Redirect } from 'react-router-dom'

import ImageWithDefault from './ImageWithDefault'
import NotificationPopupBox from './notificationPopupBox'
import SettingModal from './SettingModal'

import ticketHelper from '../ticketHelper'

import API from '../api'
import avatarUrl from '../avatarUrl'

var floatStyle = {
  left: '95%',
  top: '10%',
  padding: '4px',
  backgroundColor: '#FF8373',
  color: 'white'
}

var toolbarStyle = {
  fontSize: '1.25rem',
  height: '3rem',
  backgroundColor: '#212a52',
  width: '100vw'
}

const menuItemStyle = {
  color: 'white',
  paddingLeft: '15px',
  paddingRight: '15px'
}

class Toolbar extends Component {
  constructor (props) {
    super(props)

    let u = window.localStorage.getItem('loggedIn')
    if (u) u = JSON.parse(u)
    this.state = {
      showNotifications: false,
      showSettingModal: false,
      user: u,
      notifications: [],
      notificationCount: 0,
      redirectToLogin: false
    }
  }

  isDemo () {
    return this.state.user && this.state.user.data.roles[0] === 'demo'
  }

  async getNotifications () {
    try {
      let ret = await API.getNotificationsNoLimit(this.state.user)

      this.setState({ notifications: ret, notificationCount: ret.reduce((sum, n) => n.read_time ? sum : sum + 1, 0) })
    } catch (e) {
      // 如果出錯，應該就是登入資訊過期了，這邊直接導回 login 頁
      // TODO: 更嚴謹的檢查
      window.localStorage.removeItem('loggedIn')
      this.setState({ redirectToLogin: true })
    }
  }

  componentDidMount () {
    this.getNotifications()
    this.timer = setInterval(() => {
      this.getNotifications()
    }, 30000)
  }

  componentWillUnmount () {
    clearInterval(this.timer)
  }

  handleNotificationClick () {
    if (this.props.disableNotification) {
      return
    }
    if (this.state.showNotifications) {
      this.setState({ showNotifications: false })

      if (this.props.onToggle) this.props.onToggle(false)
    } else {
      this.setState({ showNotifications: true })

      if (this.props.onToggle) this.props.onToggle(true)
    }
  }

  handlesettingClick () {
    this.setState({ showSettingModal: !this.state.showSettingModal });
  }

  async markAllAsRead () {
    await API.markAllNotificationsRead(this.state.user)

    this.getNotifications()
  }

  handleMarkAllAsRead () {
    this.markAllAsRead()
  }

  refreshPage () {
    if (this.props.onAttemptToRefresh) {
      this.props.onAttemptToRefresh()
      this.setState({ showNotifications: false })
    }
  }

  renderNotifications () {
    let alreadyInAllNotificationsPage = false
    if (window.location.href.endsWith('/notifications')) alreadyInAllNotificationsPage = true

    return (
      <div className='sm:fixed border border-grey bg-white shadow sm:shadow-lg w-100 sm:w-notification-popup z-50' style={{ right: '80px', top: '3.5rem' }}>
        <div className='bg-grey-light p-2 text-center'>
          {alreadyInAllNotificationsPage
            ? <a href='#/notifications' onClick={this.refreshPage.bind(this)}><h4 className='m-0 underline'>顯示全部</h4></a>
            : <Link to='/notifications'><h4 className='m-0 underline'>顯示全部</h4></Link>}
        </div>
        {/* <div className='flex flex-row justify-between bg-grey-light p-2' onClick={this.handleMarkAllAsRead.bind(this)}>
          <h4 className='m-0 font-normal ml-2'>全部設為已讀</h4>
        </div> */}
        {this.state.notifications.map(n => <NotificationPopupBox data={n} key={n._id} />)}
      </div>
    )
  }

  render () {
    let statsClassName = 'pb-2 pt-2'
    let statsStyle = {}
    let meClassName = 'pb-2 pt-2'
    let meStyle = {}
    let avatar = avatarUrl(this.state.user.data.avatar)

    if (this.props.active === 'stats') {
      statsClassName += ' border-b-2 border-blue-light'
      statsStyle = { marginBottom: '-2px' }
    } else if (this.props.active === 'me') {
      meClassName += ' border-b-2 border-blue-light'
      meStyle = { marginBottom: '-2px' }
    }
    if (this.state.redirectToLogin) {
      return (<Redirect to={{ pathname: '/',state:{refreshLogin:true} }} />)
    }
    return (
      <div className='mt-14 sm:mt-0'>
        <Menu className='fixed' style={toolbarStyle}>
          <SettingModal open={this.state.showSettingModal}/>
          <Link to='/me'>
            <Menu.Item style={{ fontWeight: 700, paddingTop: '0.5rem', paddingBottom: 0, ...menuItemStyle }}
              name='BMW Service' >
              <div className='sm:pl-5 pb-2 pt-2 text-2xl'>
                <img src='/BMW_type.svg' style={{ width: '133px', height: '23px' }} alt='BMW Service' />
              </div>
            </Menu.Item>
          </Link>
          <Menu.Menu position='right'>
            {this.isDemo() ? '' : <Menu.Item
              name='stats'
              style={menuItemStyle}>
              <div className={statsClassName} style={statsStyle}>
                <Link to='/stats' style={{ color: 'white' }}>
                  <div className='flex flex-row justify-center items-center'>
                    <span className='icon-ic_dashboard' />
                    <span className='ml-2 hidden sm:block'>總覽</span>
                  </div>
                </Link>
              </div>
            </Menu.Item>}
            {this.isDemo() ? '' : <Menu.Item
              name='me'
              style={menuItemStyle}>
              <div className={meClassName} style={meStyle}>
                <Link to='/me' style={{ color: 'white' }}>
                  <div className='flex flex-row justify-center items-center'>
                    <span className='icon-ic_ticket' />
                    <span className='ml-2 hidden sm:block'>服務單</span>
                  </div>
                </Link>
              </div>
            </Menu.Item>}
            {this.isDemo() ? '' : <Menu.Item
              name='messages'
              className='relative' style={menuItemStyle}>
              <div className='pb-2 pt-2 sm:hidden'>
                <Link to='/notifications'>
                  <div className='flex flex-row justify-center items-center'>
                    <span className='icon-ic_error_outline text-white' />
                    {this.state.notificationCount > 0 && !this.props.disableNotification
                      ? <Label floating style={floatStyle}>{this.state.notificationCount}</Label>
                      : ''}
                    <span className='ml-2 hidden sm:block'>通知</span>
                  </div>
                </Link>
              </div>
              <div className='pb-2 pt-2 hidden sm:block' onClick={this.handleNotificationClick.bind(this)}>
                <div className='flex flex-row justify-center items-center'>
                  <span className='icon-ic_error_outline' />
                  {this.state.notificationCount > 0 && !this.props.disableNotification
                    ? <Label floating style={floatStyle}>{this.state.notificationCount}</Label>
                    : ''}
                  <span className='ml-2 hidden sm:block'>通知</span>
                </div>
              </div>
            </Menu.Item>}
            <Menu.Item
              name='setting'
              style={menuItemStyle}
              onClick={this.handlesettingClick.bind(this)}>
              <div className='pb-2 pt-2'>
                <div className='flex flex-row justify-center items-center'>
                  <span>
                    <ImageWithDefault
                      alt=''
                      src={avatar}
                      default={ticketHelper.userImageDefault()}
                      className='rounded-full'
                      style={{ width: '36px', height: '36px' }} />
                  </span>
                  <span className='ml-2 hidden sm:block'>{this.state.user.data.name}</span>
                </div>
              </div>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        {this.state.showNotifications ? this.renderNotifications() : ''}
      </div>
    )
  }
}

Toolbar.defaultProps = {
  renderSubtoolbar: false
}

export default Toolbar
