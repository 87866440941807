import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import TicketCard from './TicketCard'

class TicketCards extends Component {
  render () {
    if (this.props.data.length === 0) {
      return (
        <div className='flex flex-col justify-center items-center h-64 text-xl'>
          <img alt='' src='/tickets_empty.svg' />
          <div className='text-grey-darker mt-4'>{this.props.search ? '查無服務單' : '目前無服務單' }</div>
        </div>
      )
    }
    return (
      <div className='ticketCards'>
        {this.props.data.map(ticket => {
          return (
            <Link to={`/tickets/${ticket._id}`} key={ticket._id}>
              <div className='panel'><TicketCard data={ticket} /></div>
            </Link>
          )
        })}
      </div>
    )
  }
}

export default TicketCards
