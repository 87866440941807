import React, { Component } from 'react'
import ticketHelper from '../ticketHelper'
import { get } from 'lodash'
import { Link } from 'react-router-dom'
import API from '../api'

import avatarUrl from '../avatarUrl'

export default class MiniTicketCard extends Component {
  constructor(props){
    super(props)

    let u = window.localStorage.getItem('loggedIn')
    if (u) u = JSON.parse(u)

    this.state = {
      user: u,
    }

    this.goToTicket = this.goToTicket.bind(this);
  }

  async goToTicket(isTicketCreated, comingEvent, plate_no){
    if(isTicketCreated){
      this.props.history.push(`tickets/${comingEvent.service_ticket._id}`);
    } else {
      const eventUpdated = await API.getNewComingUpdate(this.state.user, comingEvent._id)
      this.props.history.push({
        pathname: 'tickets/new', 
        // 有新的 comming Event, 會被更新到同一筆 comingEvent._id
        state:{plate_no: plate_no , nonbooked_id: comingEvent._id, created_time: eventUpdated.time}
      });
    }
  }

  render () {
    let comingEvent = this.props.comingEvent;
    let isTicketCreated = comingEvent.service_ticket !== undefined;
    let plate_no = isTicketCreated
      ? comingEvent.service_ticket.car.plate_no
      : comingEvent.plate_no;
    let recep = comingEvent.recep_space
    let isIn = comingEvent.direction === "in"
    let iscomingSelf = comingEvent.plate_no !== undefined;
    return (
      <div onClick={this.goToTicket.bind(this, isTicketCreated, comingEvent, plate_no)} 
        className={`rounded-lg border border-grey shadow overflow-hidden py-2 mb-2
        ${!isIn
          ? "bg-grey-lighter"
          : isTicketCreated
              ? "bg-grey-lighter"
              : "bg-white"}`}>
        <div className={`flex flex-row justify-center items-center mt-2`}>
          <div className={`flex flex-row justify-center items-center border-b-2 border-gray w-full mx-4 pb-2`}>
            <div className='flex flex-row items-center'>
              <span className={`text-xl font-black ${ !isIn ? "text-grey-darkest icon-exit" : "text-cruisys-blue-dark icon-enter"}`} />
              <div className={`pl-2 text-xl font-black ${ !isIn ? "text-grey-darkest" : "text-cruisys-blue-dark"}`}>{plate_no}</div>
            </div>
          </div>
        </div>
        <div className={`flex justify-center my-4`}>
          <div className={`flex text-base text-lg font-bold ${ !isIn ? "text-grey-darkest" : "text-cruisys-blue-dark"}`}>
            {!isIn
              ? "車輛偵測離廠"
              : iscomingSelf
                ? `自來車指定${recep}`
                : comingEvent.service_ticket.booked 
                  ? `預約車指定${recep}`
                  : `自來車指定${recep}`}
          </div>
        </div>
        <div className='flex justify-center mt-2 mb-1'>
          <button className={`text-base font-bold rounded-lg text-center w-full mx-4 py-3 self-center
                              ${!isIn
                                ? "bg-white text-grey-darkest"
                                : isTicketCreated
                                  ? "bg-cruisys-blue-dark text-white"
                                  : "bg-grey-lighter text-cruisys-blue-dark"}`}>
            {isTicketCreated ? '查看' : '建立'}
          </button>
        </div>
      </div>
    )
  }
}
