import React, { Component } from 'react'

import moment from 'moment'
import 'moment/locale/zh-tw'

import SectionTime from './SectionTime'
import Event from './Event'
import SectionLabel from './SectionLabel'

import ticketHelper from '../../ticketHelper'

const s = {
  display: 'grid',
  gridTemplateColumns: '1fr 4fr'
}

const ABS_TIME_THRESHOLD = 1 // hours

moment.locale('zh-tw')

export default class C extends Component {
  // Count date difference
  differenceInDays (currentDay, inputDay) {
    // console.log('currentDay is =>', currentDay);
    // console.log('inputDay is =>', inputDay);
    if(currentDay.getDate() != inputDay.getDate()){
      return true;
    } else {
      return false;
    }
  }
  renderSections (data) {
    let sections = []
    const now = new Date()
    let currentDay = new Date()

    for (let i = 0; i < data.length; i++) {
      let sep = false
      if (this.differenceInDays(currentDay, data[i].time)) {
        if(i == 0){
          sep = 'first';
        } else {
          sep = true
        }
        currentDay = data[i].time
      }

      // differenceInHours is 0 if it's less than 1 hour
      //if (differenceInHours(now, data[i].time) >= ABS_TIME_THRESHOLD) {
      //  sections.push(<SectionTime key={`${data[i].time}-time-${i}`} text={moment(data[i].time).format('MM月DD日')} subText={moment(data[i].time).format('HH:mm')} sep={sep} />)
      //} else {
      //  sections.push(<SectionTime key={`${data[i].time}-time-${i}`} text={moment(data[i].time).fromNow()} sep={sep} />)
      //}

      // show datetime time during trail operation. The final type needs discussion.
      sections.push(<SectionTime key={`${data[i].time}-time-${i}`} text={moment(data[i].time).format('MM月DD日')} subText={moment(data[i].time).format('HH:mm:ss')} sep={sep} />)

      let pt = false
      let pb = false
      let mt = false
      if (i > 0 && data[i - 1].progress !== data[i].progress) pt = true
      if (i < data.length - 1 && data[i + 1].progress !== data[i].progress) pb = true

      // hack: 上次維修一定只會出現在最後一個
      if (data[i].label && data[i].label.startsWith('上次維修')) mt = true

      // event padding
      if (i > 0 && data[i].type === 'event' && data[i - 1].type === 'event') mt = true

      if (data[i].type === 'event') {
        sections.push(<Event 
          text={data[i].label}
          key={`${data[i].time}-event-${i}`}
          pt={pt}
          pb={pb}
          mt={mt}
          line={data[i].line} />)
      } else {

        let progress = ticketHelper.showProgress(data[i].progress);
        sections.push(<SectionLabel
          key={`${data[i].time}-label-${i}`}
          progressName={progress.label}          
          text={data[i].action}
          subText={data[i].location}
          color={progress.color}
          textColor={ data[i].action.includes('無訊號') ? '#606f7b' : '#000000'}
          pt={pt}
          pb={pb}
          scrollName={(pt || i === 0) ? data[i].type : null}
          iconName={progress.iconName}
          line={data[i].line} />)
      }
    }

    return sections
  }

  render () {
    return (
      <div style={s} className='mb-20' >
        {this.renderSections(this.props.data)}
      </div>
    )
  }
}



