import React from 'react';
import Area from './area';


export default class OfficeArea extends React.Component {
    constructor(props) {
        super(props)
        this.modifyCarArea = this.modifyCarArea.bind(this);
    }
    modifyCarArea(){
        let car_areas;
        if(this.props.areaStatus){
            let areastatus_array = [];
            Object.keys(this.props.areaStatus).forEach(key => {
                let eachstatus = {};
                eachstatus.name = key;
                eachstatus.status = this.props.areaStatus[key];
                areastatus_array.push(eachstatus);
            });
            car_areas = areastatus_array.map(area =>
                <Area
                    key={area.name}
                    name={area.name}
                    status={area.status}
                    switchArea={() => {
                        this.props.switchArea(area.name, !area.status);
                        area.status = !area.status;
                    }}
                />)
            return car_areas;
        }
    }
    render() {
        return (
            <section className='border_area office_area my-5'>
                <p className='title office text-center'>接待位區域</p>
                <p className='explain office text-center'>點擊下方接待位區域可控制車位是否佔位，深色區塊為佔位，白色區塊為空位</p>
                <div className='each_area'>
                    <div className='flex items-center justify-center flex-wrap h-full'>
                      {this.modifyCarArea()}
                    </div>
                </div>
            </section>
        )
    }
}