import React, { Component } from 'react'

import Timeline from '../components/Timeline'
// import TimelinePlaceholder from '../components/Timeline/Placeholder'
import API from '../api'
import '../assets/scss/page/ticket.scss';

export default class TicketTimelineEmptyColumn extends Component {
  constructor (props) {
    super(props)
    let u = window.localStorage.getItem('loggedIn')
    if (u) u = JSON.parse(u)

    this.state = {
      updating: false,
      user: u,
      line_status : false,
      islineOpen : true,
      ticket_status : null
    }
  }
  componentDidMount(){
    // Define if ticket is int, created, or closed. 
    this.setState({
      ticket_status : this.props.ticket.status,
      islineOpen : this.props.ticket.line_notification
    });
    this.props.ticket.car_orig.contacts.forEach(person => {
      if(person.hasOwnProperty('line_id')){
        this.setState({line_status: true});
      }
    });
  }

  async updateStatus () {
    let ret = await API.updateTicket(this.state.user, this.props.ticketID, { status: 'started' })
    console.log(ret)

    this.setState({ updating: false })

    this.props.onReloadRequired()
  }

  handleSetStatusStarted () {
    this.setState({ updating: true })
    this.updateStatus()
  }

  switchNotify(){
    console.log('click')
    // If ticket status is "closed" or line is not bound, user can't set notification.
    if(this.state.ticket_status === 'closed' || !this.state.line_status){
      return;
    }
    this.setState({islineOpen : !this.state.islineOpen}, () => {
      this.props.updateLineNotify({line_notification: this.state.islineOpen});
    });
  }
  
  render () {
    return (
      <div>
        {this.props.showStatusButton
          ? <React.Fragment>
              <button
                className='w-full bg-cruisys-blue text-white rounded-lg py-4 flex flex-row items-center justify-center'
                onClick={this.handleSetStatusStarted.bind(this)}
                disabled={this.state.updating || !this.props.ticket.assignee || !this.props.ticket.tag_no}>
                <span className={'block mr-4 text-2xl ' + (!this.props.ticket.tag_no ? 'icon-ic_report_problem' : 'icon-ic_offline_pin')} style={{ lineHeight: '28px' }} />
                <span className='block h-8 text-2xl font-black' style={{ lineHeight: '28px' }}>
                {!this.props.ticket.tag_no ? '尚未輸入TAG': '開始追蹤'}</span>
              </button>
              <div className={`w-full flex bg-white items-center justify-center line-notify-setting
                ${this.state.ticket_status === 'closed' || !this.state.line_status ? 'close': ''}`}>
                <span className='icon-info'></span>
                <p className='text'>{
                  this.state.line_status 
                    ? '客戶已綁定，發送Service Go通知？'
                    : '客戶未綁定，Service Go通知已關閉'
                  }
                </p>
                <div className="onoffswitch"> 
                  <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" checked/> 
                  <label onClick={this.switchNotify.bind(this)} className={`onoffswitch-label ${this.state.ticket_status === 'closed' || !this.state.line_status ? 'close': ''}`} for="myonoffswitch"> 
                    <span className={`onoffswitch-inner 
                      ${this.state.line_status 
                        ? this.state.islineOpen
                          ? 'on' 
                          : 'off'
                        : 'off'}`}>
                    </span> 
                    <span className={`onoffswitch-switch 
                      ${this.state.line_status 
                        ? this.state.islineOpen
                          ? 'on'
                          : 'off'
                        : 'off'}`}>
                    </span> 
                  </label> 
                </div>
              </div>
            </React.Fragment>
          : ''}

        <h3 className='my-2 mt-8'>工作軌跡</h3>
        <Timeline data={this.props.data} />
      </div>
    )
  }
}
