import moment from'moment'
import React, { Component } from 'react'
import { Button, Modal } from 'semantic-ui-react'

class ImportModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
      <Modal open={this.props.open} closeOnDimmerClick size='mini'>
        <Modal.Header>
          {!this.props.importFailed
            ? <div>
                匯入確認
              </div>
            : <div className='text-red-error'>
                匯入失敗
              </div>
          }
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {this.props.description}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color= {this.props.importFailed ? 'blue' : 'grey'}
            onClick={this.props.handleCancel}>
              {this.props.importFailed
                ? '確認'
                : '取消'
              }
          </Button>

          {this.props.importFailed
            ? null
            : <Button color='blue' onClick={this.props.handleOk}>確認</Button>
          }
        </Modal.Actions>
      </Modal>
    )
  }
}

class UploadNotification extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
      <Modal open={this.props.open} closeOnDimmerClick size='mini'>
        <Modal.Header>
          {!this.props.importFailed
            ? <div>
                匯入成功
              </div>
            : <div className='text-red-error'>
                匯入失敗
              </div>
          }
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
              <div>
                <div>
                  {this.props.notification}
                </div>
                {this.props.reminder
                  ? <div className='mt-4 text-cruisys-grey-dark'>
                      {this.props.reminder}
                    </div>
                  : null
                }
              </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='blue' onClick={this.props.handleOk}>確認</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export {
  ImportModal,
  UploadNotification
}