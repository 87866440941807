import React, { Component } from 'react'

export default class C extends Component {
  render () {
    return (
      <div className={`bg-white p-4 shadow border border-grey ${this.props.className}`}>
        <h4 className='text-grey-darker text-xl font-normal'>今日蒞臨 ({this.props.total})</h4>
        <div className='ml-4'>
          <h5>預約車輛 ({this.props.reserved})</h5>
          {this.props.reserved > 0 && this.props.showChart
            ? <div>
                <div className='w-full rounded-full overflow-hidden flex flex-row'>
                  <div className='bg-cruisys-teal' style={{ width: `${this.props.arrived * 100 / this.props.reserved}%`, height: '14px' }} />
                  <div className='bg-cruisys-red' style={{ width: `${this.props.waiting * 100 / this.props.reserved}%`, height: '14px' }} />
                </div>
                <div className='mt-2 flex flex-row items-center justify-between'>
                  <span className='my-1 flex flex-row items-center text-grey-darker text-lg'>
                    <span className='text-4xl text-cruisys-teal mr-1'>●</span>
                    已到達
                    <span className='ml-1 text-black font-black'>{this.props.arrived}</span>
                  </span>
                  <span className='my-1 flex flex-row items-center text-grey-darker text-lg'>
                    <span className='text-4xl text-cruisys-red mr-1'>●</span>
                    尚未進廠
                    <span className='ml-1 text-black font-black'>{this.props.waiting}</span>
                  </span>
                </div>
              </div>
            : <div className='w-full flex justify-center items-center mt-8'>
                <span className='text-xl text-grey'>無預約資料</span>
              </div>}
          <div className='mt-2 border-solid border-b'></div>
          <h5 className='mt-6'>自來車輛 ({this.props.unreserved})</h5>
        </div>
      </div>
    )
  }
}
