import React, { Component } from 'react'
import { Table } from 'semantic-ui-react'
import _, { get } from 'lodash'
import moment from 'moment'
import 'moment/locale/zh-tw'
import { Link } from 'react-router-dom'

import avatarUrl from '../avatarUrl'
import ticketHelper from '../ticketHelper'
import ImageWithDefault from './ImageWithDefault'
import TicketCardImage from './TicketCardImage'

moment.locale('zh-tw')

class TicketTable extends Component {

  renderRow (ticket) {
    let line_status = false;
    ticket.car_orig.contacts.forEach(person => {
      if(person.hasOwnProperty('line_id')){
        line_status = true;
      }
    });
    let st = ticketHelper.showProgress(ticket.progress)
    let statusColor = st.color
    let statusLabel = st.label
    let statusIconName = st.iconName

    let row = []
    row.push(
      <Table.Row key={`${ticket._id}-top`}>
        <Table.Cell textAlign='center' rowSpan='2' style={{ borderBottom: '1px solid black', padding: 0, paddingLeft: '5px' }}>
          <Link to={{pathname: `/tickets/${ticket._id}`}}>
            <div className='flex flex-row items-center justify-between text-black'>
              <div className='flex flex-col items-center'>
                <span className='font-sans text-lg uppercase font-bold'>{get(ticket, 'car.plate_no', '')}</span>
                <span className='font-sans text-lg'>系列 {get(ticket, 'car.series', '') ? get(ticket, 'car.series', '') : '-'}</span>
              </div>
              <TicketCardImage
                src={ticketHelper.carImageHead(_.get(ticket, 'car.plate_no', ''))}
                default={ticketHelper.carImageDefault()}
                style={{ width: '62px', height: '91px' }}
              />
            </div>
          </Link>
        </Table.Cell>
        <Table.Cell rowSpan='2' style={{ padding: '5px', borderBottom: '1px solid black' }}>
          <div className='flex flex-col justify-center items-center w-full h-full'>
            {ticket.assignee
              ? <ImageWithDefault
                alt='user_avatar'
                src={avatarUrl(get(ticket, 'assignee.avatar', ''))}
                default={ticketHelper.userImageDefault()}
                className='rounded-full'
                style={{ width: '35px', height: '35px' }} />
              : <img alt='' src='/empty_user.png' style={{ width: '35px', height: '35px' }} />}
            <span className='text-lg mt-2'>{get(ticket, 'assignee.name', '尚未指定')}</span>
          </div>
        </Table.Cell>
        <Table.Cell textAlign='right'>
          <div className='flex flex-row items-center text-lg'>
            <span className={`icon-ic_reservation ${ticket.booked ? 'text-cruisys-blue' : ''} mr-2`} />
            <span>{ticket.booked ? '預約' : '自來'}</span>
          </div>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'center' }}>
          <span className='text-lg'>{ticket.tag_no ? ticket.tag_no : '-'}</span>
        </Table.Cell>
        <Table.Cell textAlign='right' colSpan='2'>
          <span className='text-base text-grey-darker'>預計進廠</span>
          <br />
          {moment(ticket.estimated_arrival_time).format('MM/DD HH:mm')}
        </Table.Cell>
      </Table.Row>
    )
    row.push(
      <Table.Row key={`${ticket._id}-bottom`}>
        <Table.Cell textAlign='right' style={{ borderBottom: '1px solid black', borderLeft:'1px solid #dae1e7' }}>
          <div className='flex flex-row items-center text-lg'>
            <span className={`mr-2 ` + statusIconName} style={{ color: statusColor }} />
            <span>{statusLabel}</span>
          </div>
        </Table.Cell>
        <Table.Cell textAlign='right' style={{ borderBottom: '1px solid black' }}>
          {line_status === null
            ? ''
            : <div className='flex flex-row items-center text-lg'>
              <span className={`icon-line block mr-3 ${line_status ? 'text-green': 'text-grey'}`} />
              <span>{line_status ? '已綁定' : '未綁定'}</span>
            </div>}
        </Table.Cell>
        <Table.Cell textAlign='right' colSpan='2' style={{ borderBottom: '1px solid black' }}>
          <span className='text-base text-grey-darker'>預計交車</span>
          <br />
          {moment(ticket.estimated_delivery_time).format('MM/DD HH:mm')}
        </Table.Cell>
      </Table.Row>
    )
    return row;
  }
  renderRows (data) {
    let rows = [];
    for (let i = 0; i < data.length; i++) {
      rows = rows.concat(this.renderRow(data[i]));
    }
    return rows;
  }
  render () {
    return (
      <Table celled >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>車牌/系列</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: '5px' }}>負責人</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: '5px' }}>客訪/工作進度</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: '5px' }}>TAG/LINE</Table.HeaderCell>
            <Table.HeaderCell style={{ padding: '5px' }}>進廠/交車時間</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.renderRows(this.props.data)}
        </Table.Body>
      </Table>
    )
  }
}

export default TicketTable
