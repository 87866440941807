import React, { Component } from 'react'
import { Field } from 'formik'

export default class ServiceTypeSelect extends Component {
    constructor (props) {
        super(props);
    }

    render () {
        return (
            <Field component='select' className={this.props.className} name={this.props.name} id={this.props.id}>
                          <option value='null'>未指定服務類型</option>
                          <option value='FLS CBS 保養'>FLS CBS 保養</option>
                          <option value='FLS 安全檢查'>FLS 安全檢查</option>
                          <option value='FLS 保固召回'>FLS 保固召回</option>
                          <option value='FLS 保固終檢'>FLS 保固終檢</option>
                          <option value='FLS 故障查修'>FLS 故障查修</option>
                          <option value='FLS 新車檢查'>FLS 新車檢查</option>
                          <option value='FLS 零件到貨安裝'>FLS 零件到貨安裝</option>
                          <option value='一般保固召回'>一般保固召回</option>
                          <option value='一般保養'>一般保養</option>
                          <option value='一般零件到貨安裝'>一般零件到貨安裝</option>
                          <option value='一般維修'>一般維修</option>
                          <option value='出險'>出險</option>
                          <option value='車身維修'>車身維修</option>
            </Field>
        );
    }
}