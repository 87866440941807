import React, { Component } from 'react'

import * as Scroll from 'react-scroll'

export default class C extends Component {
  render () {
    let scrollName = this.props.scrollName ? this.props.scrollName : this.props.text
    return (
      <div className={`p-3 pl-4 flex bg-grey-light text-grey-darker font-black ${this.props.mt ? 'mt-4' : ''}`}>
        { this.props.line
          ? <img className='mr-1' style={{width:'20px', height:'20px'}} src='/line_app_icon.png'></img>
          :  ''}
        <Scroll.Element name={scrollName}>{this.props.text}</Scroll.Element>
      </div>
    )
  }
}
